import { Button, Dropdown, Modal, Radio, Row, Typography } from "antd";
import styled from "styled-components";

export const marketWatchItem = [
  { key: 1, label: "Rename", value: "rename" },
  { key: 2, label: "Delete", value: "delete" },
];
export const StyledMarketButton = styled(Button)`
  width: 100%;
  color: ${(props) => (props.$isSelected ? "white" : "")};
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 1.5rem;
  justify-content: center;
  /* border: 1px solid red; */
  background-color: ${(props) =>
    props.$isSelected ? props.theme.token.colorPrimary : ""};
`;
export const StyledMarketButtonAll = styled(Dropdown.Button)`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${(props) => (props.$isSelected ? "white" : "")};
  }

  & .ant-btn-compact-first-item {
    width: 100%;
  }
  & .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 1.5rem;
    background-color: ${(props) =>
      props.$isSelected ? props.theme.token.colorPrimary : ""};
    color: ${(props) =>
      props.$isSelected ? props.theme.token.colorBgBase : ""};
    font-size: 1.1rem;
  }
`;
export const MarketWatchButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG + "px"};
  font-size: 1.1rem;
  height: 3rem;
  background-color: ${(props) => props.theme.token.colorBgActionButton};
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  width: 98%;
  color: white;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
    height: 2.5rem;
  }
`;
export const StyledRow = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: row;
  align-items: center;
  background-color: ${(props) => props.theme.token.colorBgBase};
  padding: 25px 15px;
`;
export const StyledDropDownItem = styled.div`
  width: 13rem;
  font-size: 1rem;
  padding-inline: 1rem;
  padding-block: 0.3rem;
  border-radius: ${(props) => props.theme.token.borderRadiusSM + "px"};
  cursor: pointer;
  background-color: ${(props) =>
    props?.$selected
      ? props.theme.token.controlItemBgHover
      : props.theme.token.controlItemBg};
  &:hover {
    background-color: ${(props) => props.theme.token.controlItemBgHover};
  }
`;
export const StyledTableWrapper = styled(Row)`
  width: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  flex-flow: column;
`;
export const TextTitle = styled(Typography.Title)`
  &.ant-typography {
    color: ${(props) => props.theme.token.colorTextLabel};
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 1.125rem;
    text-align: left;
    & .styled-drop-down-star {
      color: ${(props) => props.theme.token.red4};
      font-size: 1rem;
    }
  }
`;
export const style = {
  headerScriptBtnStyle: {
    marginTop: "1.5rem",
  },
};
export const options = [
  { label: "Market", value: "market" },
  { label: "Limit/SL", value: "limit" },
  { label: "Intraday", value: "intraday" },
  { label: "Intraday-Limit", value: "intradaylimit" },
];
export const offlineOptions = [
  { label: "Market", value: "market" },
  { label: "Intraday", value: "intraday" },
];
export const StyledTheme = {
  Root: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Cell: styled.div`
    height: 6rem;
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: ${(props) => props.theme.token.borderRadiusLG}px;
    background-color: ${(props) => props.theme.token.colorBgBase};
    color: ${(props) => props.theme.token.colorTextBase};

    & .cell-title {
      font-size: 1rem;
      font-weight: 600;
      color: ${(props) => props.theme.token.colorTextLabel};
    }
    .cell-number {
      font-weight: 500;
      font-size: 1rem;
      color: ${(props) => props.theme.token.colorTextLabel};
    }
  `,
  Modal: styled(Modal)`
    transform: none !important;
    width: 90vw !important;
    & .ant-modal-content {
      border-radius: ${(props) => props.theme.token.borderRadiusLG}px;
      padding: 2rem;
      background-color: ${(props) =>
        props.$type === 1
          ? props.theme.token.tradeModalBuyColor
          : props.theme.token.marketWatchDown};
    }
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-close {
      display: none;
    }
  `,
  BuySellWrapper: styled(Row)`
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  `,
  MarketTypeRadio: styled(Radio.Group)`
    width: 100%;
    & .ant-radio-button-wrapper {
      font-size: 0.9rem;
    }
    .ant-radio-button-wrapper {
      width: 25%;
      padding-inline: 5px;
      text-align: center;

      @media (max-width: 900px) {
        width: 50%;
      }
    }
  `,
  RadioDiv: styled.div`
    width: 30%;
    @media (max-width: 1150px) {
      width: 50%;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  `,
};
