import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Skeleton,
  Space,
  Table as AntDTable,
  Tag,
  Tooltip,
  Typography,
  Modal,
} from "antd";
import ThemeDropDown from "../../../components/ThemeDropDown";
import { allOption, labelObj } from "../../../Helper/constant";
import ThemeInput from "../../../components/ThemeInput";
import { SearchOutlined } from "@ant-design/icons";
import EndPoints from "../../../APICall/EndPoints";
import APICall from "../../../APICall";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import {
  AccountTypeRadioButton,
  StyTable,
  StyledModal,
  accountOption,
  option,
} from "./style";
import UPlineComponant from "./Components/UPlineComponant";
import ExpirySettingModal from "./Components/ExpirySettingModal";
import ResetButton from "./Components/ResetButton";
import StatusButton from "./Components/StatusButton";
import { valanBillDate } from "../../../components/CommanFuction/CommanFunction";
import { TextLeftDiv, UserListButtonexport } from "../../../styles/commonStyle";

const initalStatus = { flage: false, item: "" };
const AccountPage = () => {
  // state
  const [searchValue, setSearchValue] = useState("");
  const [isExpiryModal, setIsExpiryModal] = useState({
    userId: null,
    flag: false,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [filters, setFilters] = useState({
    accountType: "default",
    role: "all",
    search: "",
  });
  const [showStatus, setShowStatus] = useState(initalStatus);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let optionValueRef = useRef(null);
  const paramType = useParams();

  // life cycle
  useEffect(() => {
    queryClient.refetchQueries([
      "usersDetails",
      pagination,
      filters,
      paramType,
    ]);
  }, []);

  //redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const isRent = userDetail?.partnershipWith === "rent";
  const showAccounts =
    (isRent && userDetail?.accountType !== "dealer") ||
    userDetail?.accountType === "owner";

  // api call
  const getUsers = async ({ queryKey }) => {
    const [_, { current, pageSize }, { accountType, search, role }] = queryKey;
    const type = paramType?.type === "rent" ? paramType?.type : "default";
    let url = `${EndPoints.getAllUser}?type=${type}&page=${current}&limit=${pageSize}`;
    if (search !== "" && role === "all") {
      url = `${EndPoints.getAllUser}?type=${type}&page=${current}&limit=${pageSize}&search=${search}`;
    }
    if (role !== "all" && search !== "") {
      url = `${EndPoints.getAllUser}?type=${type}&page=${current}&limit=${pageSize}&role=${role}&search=${search}`;
    }
    if (search === "" && role !== "all") {
      url = `${EndPoints.getAllUser}?type=${type}&page=${current}&limit=${pageSize}&role=${role}`;
    }

    let res = await APICall("get", url);
    return res?.data?.data;
  };

  const { isLoading, data, error, isFetching, refetch } = useQuery({
    queryKey: ["usersDetails", pagination, filters, paramType],
    queryFn: getUsers,
    // keepPreviousData: true,
  });

  // function
  const EditUserDetails = (id) => {
    if (paramType?.type !== "rent") {
      navigate(`/users/edit-account/${id}`);
      // window.open(`/users/edit-account/${id}`, "_blank");
    } else {
      navigate(`/users/edit-rent-account/${id}`);
      // window.open(`/users/edit-rent-account/${id}`, "_blank");
    }
  };

  const handleChange = (value) => {
    if (value) {
      optionValueRef.current = value;
      setFilters((pre) => ({ ...pre, role: value[0] }));
    }
  };

  const userLedger = (item) => {
    navigate(`/user-ledger/${item?.userId}`);
  };

  const handleExpiryModal = (userId) => {
    setIsExpiryModal({
      userId: userId,
      flag: true,
    });
  };

  const handleCancelExpiry = () => {
    setIsExpiryModal({
      userId: null,
      flag: false,
    });
  };

  // useDebounce(
  //   () => {
  //     const searchedData = data?.filter(
  //       (el) =>
  //         el.accountName.toLowerCase().includes?.(searchValue.toLowerCase()) ||
  //         el.userId.toString().includes?.(searchValue.toString())
  //     );
  //     if (searchValue === "") {
  //       if (
  //         optionValueRef.current === null ||
  //         optionValueRef.current[0] === "all"
  //       ) {
  //         setDisplayData(data);
  //       } else {
  //         let filteredData = data.filter(
  //           (el) => el.accountType === optionValueRef.current[0]
  //           // el.userId === optionValueRef.current[0]
  //         );
  //         setDisplayData(filteredData);
  //       }
  //     } else {
  //       setDisplayData(searchedData);
  //     }
  //   },
  //   200,
  //   [searchValue]
  // );

  useDebounce(
    () => {
      setFilters((pre) => ({ ...pre, search: searchValue }));
    },
    500,
    [searchValue]
  );

  const handleScriptSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const onChangeRadio = (e) => {
    const { value } = e.target;

    setFilters((pre) => ({ ...pre, accountType: value }));
    setPagination((pre) => ({ ...pre, current: 1 }));
    if (value === "rent") {
      navigate(`/users/accounts/${value}`);
    } else {
      navigate(`/users/accounts`);
    }
  };

  const handlePageChage = (value) => {
    setPagination((pre) => ({ ...pre, current: value }));
  };

  const ChangePageSize = (value) => {
    if (value) {
      setPagination({ current: 1, pageSize: value?.[0] });
    }
  };

  const handleShow = (item) => {
    setShowStatus({ flage: true, item: item });
  };

  // column
  const columns = [
    {
      title: "SR.NO.",
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "3rem",
      render: (text, record, index) => {
        return record?.index;
      },
      // width: "16rem",
    },
    isLoading || isFetching
      ? {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          align: "left",
          width: "16rem",
        }
      : {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          align: "center",
          width: "16rem",
          render: (imageUrl, item) => {
            return (
              !isLoading &&
              !isFetching && (
                <TextLeftDiv>
                  <Typography.Text>{item.accountName}</Typography.Text>
                  <Space size={[0, "small"]} wrap>
                    <Tag
                      color="magenta"
                      bordered={false}
                      style={{
                        fontSize: "1rem",
                        marginLeft: "0.5rem",
                      }}
                    >
                      {labelObj[item.accountType]}
                    </Tag>
                  </Space>
                </TextLeftDiv>
              )
            );
          },
        },
    {
      title: "USER ID",
      dataIndex: "userId",
      key: "userId",
      align: "center",
      width: "5rem",
    },
    {
      title: "UPLINE",
      dataIndex: "upline",
      key: "upline",
      align: "center",
      width: "15rem",
      render: (text, record, index) => {
        return (
          !isLoading &&
          !isFetching && (
            <TextLeftDiv>
              <UPlineComponant index={index} record={record} />
            </TextLeftDiv>
          )
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "35rem",
      render: (imageUrl, item) => {
        let userStatus = {
          status: item?.status ? item?.status[0] : null,
          loginStatus: item?.loginStatus ? item?.loginStatus[0] : null,
          userId: item?.userId || "null",
        };

        return (
          !isLoading &&
          !isFetching && (
            <>
              {userDetail.accountType !== "broker" ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                  }}
                >
                  <Tooltip placement="bottomLeft" title="Edit">
                    <UserListButtonexport
                      className="regularUserTableBtn"
                      onClick={() => EditUserDetails(item.userId)}
                    >
                      E
                    </UserListButtonexport>
                  </Tooltip>

                  <Tooltip placement="bottomLeft" title="Ledger">
                    <Button
                      className="redsUserTableBtn"
                      onClick={() => userLedger(item)}
                    >
                      L
                    </Button>
                  </Tooltip>

                  <ResetButton item={item} />

                  <Tooltip placement="bottomLeft" title={"Status"}>
                    <UserListButtonexport
                      className="regularUserTableBtn"
                      onClick={() => handleShow(item)}
                      // onClick={setShowStatus({ flage: true, item: item })}
                    >
                      S
                    </UserListButtonexport>
                  </Tooltip>
                  {/* <StatusButton userStatus={userStatus} item={item} /> */}

                  {/* <Tooltip placement="bottomLeft" title="Clear Login Attemps">
                  <UserListButtonexport
                    // style={{ backgroundColor: "#465f7b" }}
                    className="regularUserTableBtn"
                  >
                    CL
                  </UserListButtonexport>
                </Tooltip> */}
                  {item?.accountType === "masterAdmin" && (
                    <Tooltip placement="bottomLeft" title="Expairy Settings">
                      <UserListButtonexport
                        className="regularUserTableBtn"
                        onClick={() => handleExpiryModal(item?.userId)}
                      >
                        Ex
                      </UserListButtonexport>
                      {/* <ExpirySettingModal userId={item.userId} /> */}
                    </Tooltip>
                  )}
                </div>
              ) : (
                <div>
                  <Tooltip placement="bottomLeft" title="Ledger">
                    <Button
                      className="redsUserTableBtn"
                      onClick={() => userLedger(item)}
                    >
                      L
                    </Button>
                  </Tooltip>
                </div>
              )}
            </>
          )
        );
      },
    },
    {
      title: "LOGIN TIME",
      dataIndex: "loginTime",
      key: "loginTime",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        const time = valanBillDate(record?.loginTime, true);
        return !isLoading && !isFetching && <div>{time}</div>;
      },
    },
    {
      title: "LAST ACTIVITY",
      dataIndex: "lastActivity",
      key: "lastActivity",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        const time = valanBillDate(record?.lastActivity, true);
        return !isLoading && !isFetching && time;
      },
    },
    {
      title: "LOGIN IP",
      dataIndex: "ipAddress",
      key: "ipAddress",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        return (
          !isLoading &&
          !isFetching && <TextLeftDiv>{record?.ipAddress}</TextLeftDiv>
        );
      },
    },
  ];

  const SkeletonLoading = () => {
    return (
      <div className="account-table-skeleton">
        {new Array(4).fill({}).map((el, index) => {
          return (
            <Skeleton.Input
              key={index}
              active={true}
              style={{ width: `${(index + 1) * 15}rem`, height: "1.6rem" }}
            ></Skeleton.Input>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <Row justify={"space-between"} style={{ marginTop: "1rem" }}>
        <Col xs={12} sm={8} md={6} lg={4} xl={3}>
          <ThemeDropDown
            name={"accountType"}
            title={"Account Type"}
            placeholder="Select"
            $value={optionValueRef.current ? optionValueRef.current : "all"}
            onChange={(value) => handleChange(value)}
            showSearch={true}
            options={allOption?.filter(
              (item) => item.authorityLevel > userDetail.authorityLevel
            )}
            width={"16rem"}
            required
          />
        </Col>
        {/* <Checkbox
            // checked={is_sample_account}
            // onChange={onChangeCheckbox}
            style={{
              fontSize: "1rem",
              fontWeight: 400,
            }}
          >
            Sample Account
          </Checkbox> */}
        {showAccounts && (
          <Col
            // xs={24}
            // sm={12}
            // md={8}
            // lg={6}
            // xl={4}
            style={{ marginTop: "1.6rem" }}
          >
            {/* <TypeText>Show Accounts</TypeText> */}
            <AccountTypeRadioButton
              options={accountOption}
              onChange={onChangeRadio}
              value={paramType?.type === "rent" ? paramType?.type : "default"}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
        )}
      </Row>
      <div
        style={{
          marginTop: "2rem",
          marginBottom: "3rem",
          borderRadius: "10px",
          background: "#fff",
          padding: "2rem 0rem",
        }}
      >
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0rem 2rem",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                color: "#727880",
                marginRight: "1rem",
              }}
            >
              Show Entries
            </div>
            <ThemeDropDown
              placeholder="All"
              options={option}
              value={pagination.pageSize || 10}
              onChange={ChangePageSize}
              width={"8rem"}
              required
            />
          </div>

          <div className="userSearchInput" style={{ width: "100%" }}>
            <ThemeInput
              width={"100%"}
              required
              inputProps={{
                placeholder: "Search anything here",
                prefix: <SearchOutlined />,
                onChange: handleScriptSearch,
              }}
              formProps={{
                name: "search_text",
              }}
            />
          </div>
        </Space>
        <div style={{ marginTop: "2rem" }}>
          <StyTable
            className="userTable"
            columns={columns}
            dataSource={isLoading || isFetching ? [] : data?.userData}
            loading={{
              spinning: isLoading || isFetching,
              indicator: <SkeletonLoading />,
            }}
            pagination={{
              position: ["bottomRight"],
              current: data?.pagination?.currentPage || 1,
              defaultCurrent: 1,
              total: data?.pagination?.totalItems,
              showSizeChanger: false,
              pageSize: data?.pagination?.itemsPerPage || 10,
              onChange: handlePageChage,
            }}
            rowKey="_id"
            scroll={{
              x: 1300,
            }}
          />
        </div>
      </div>
      <StyledModal
        open={isExpiryModal.flag}
        // onOk={handleOk}
        onCancel={handleCancelExpiry}
      >
        <ExpirySettingModal userId={isExpiryModal?.userId} />
      </StyledModal>
      {showStatus?.flage && (
        <StatusButton
          item={showStatus?.item}
          setShowStatus={setShowStatus}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default AccountPage;
