import { StyleSheet } from "@react-pdf/renderer";
import { Button, Col, Row, Table } from "antd";
import styled from "styled-components";

export const StyledSummarySearchInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .ant-form-item {
    margin-bottom: unset !important;
  }
`;
export const StyledDiv = styled.div`
  /* width: 50%; */
  font-size: 1.2rem;
  font-weight: 600;
  color: #727880;
  margin-right: 1rem;
  @media (max-width: 1079px) {
    font-size: 0.9rem;
  }
  @media (max-width: 450px) {
    margin-right: 0.3rem;
    font-size: 0.8rem;
  }
`;
export const FileTypeButton = styled(Button)`
  border-radius: ${(props) => props.theme.token.borderRadiusLG};
  font-size: 0.9rem;
  width: 100%;
  box-shadow: ${(props) => props.theme.token.boxShadowNone};
  height: 3rem;
  @media (max-width: 1079px) {
    font-size: 0.8rem;
    height: 2.5rem;
  }
  @media (max-width: 767px) {
    font-size: 0.7rem;
    height: 2.5rem;
  }
`;
export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    padding: 20,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: "#000",
  },
  tableCell: {
    flex: 1,
    paddingVertical: 5,
    paddingHorizontal: 3,
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderRightStyle: "solid",
    fontSize: 12,
    textAlign: "center",
  },
  scriptHeader: {
    backgroundColor: "#0072bc",
    color: "white",
    fontSize: 12,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    textAlign: "center",
    flexDirection: "row",
  },
  userInfoCell: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tag: {
    backgroundColor: "#800080",
    color: "#fff",
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
    fontSize: 10,
  },
  pdfLink: {
    color: "#0000FF",
    textDecoration: "underline",
  },
  text: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
});
export const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 1rem;
  display: flex;

  @media (max-width: 1200px) {
    justify-content: left;
  }
`;
export const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;
export const FooterRow = styled.tr`
  background-color: #edecfa;
  /* height: 5px; */
  width: 100%;
  line-height: 0.2rem;
  font-weight: 500;
  /* font-size: small; */
  text-align: center;
  /* border-bottom-left-radius: 10px; */
`;
export const TableTitleCol = styled(Col)`
  display: flex;
  align-items: center;
`;
export const TableTitleSearch = styled(Col)`
  margin-left: ${(props) =>
    props.filterType === "trade" || props.filterType === "summary"
      ? "0"
      : "auto"};
`;
export const itemOption = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 30, value: 30 },
];
export const Summaycolumns = [
  {
    title: "SR NO.",
    dataIndex: "srNo",
    key: "srNo",
    align: "center",
    width: "4rem",
    render: (text, record, index) => index + 1,
  },
  {
    title: "USER INFO",
    dataIndex: "userId",
    key: "userId",
    align: "center",
    render: (text, record) => {
      if (record?.userId === "Total") {
        return record?.userId;
      } else {
        return `${record?.userId} (${record?.AccountName})`;
      }
    },
  },
  {
    title: "VALAN",
    dataIndex: "valan",
    key: "valan",
    align: "center",
    render: (text, record) => record?.valan?.valanName,
  },
  {
    title: "TOTAL VALAN BILL",
    dataIndex: "totalNetAmount",
    key: "totalNetAmount",
    align: "center",
    render: (text, record) => record?.totalNetAmount?.toFixed(2),
  },
  {
    title: "WITHOUT BROKERAGE",
    dataIndex: "withoutBrokerage",
    key: "withoutBrokerage",
    align: "center",
    render: (text, record) => record?.withoutBrokerage?.toFixed(2),
  },
  {
    title: "BROKERAGE",
    dataIndex: "brokerage",
    key: "brokerage",
    align: "center",
    render: (text, record) => record?.brokerage?.toFixed(2),
  },
  {
    title: "DOWNLINE BILL",
    dataIndex: "downlinem2m",
    key: "downlinem2m",
    align: "center",
    render: (text, record) => record?.downlinem2m?.toFixed(2),
  },
  {
    title: "UPLINE BILL",
    dataIndex: "uplinem2m",
    key: "uplinem2m",
    align: "center",
    render: (text, record) => record?.uplinem2m?.toFixed(2),
  },
  {
    title: "SELF BILL",
    dataIndex: "selfm2m",
    key: "selfm2m",
    align: "center",
    render: (text, record) => record?.selfm2m?.toFixed(2),
  },
];
