import React, { useEffect, useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Col, Row, Skeleton, Spin, notification, theme } from "antd";
import { useParams } from "react-router-dom";
import ThemeCard from "../../components/ThemeCard";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import {
  formatForValanBill,
  formatNumber,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { Container, SpinnerOverlay } from "../MarketSetting/MarketSetting";
import {
  StyledTheme,
  FooterRow,
  PDFButton,
  valanBillColumn,
  ScriptNameRow,
  ColorSpan,
  MarketFooterRow,
  MarketNameCol,
  HeadingRow,
  PdfRow,
} from "./style";
import { sequence } from "../../Helper/constant";
import PDFDocument from "./ValanBillPDF";
import {
  formatDate,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";

const ReportCart = () => {
  // state
  const [valanBill, setValanBill] = useState({});
  const [valanData, setValanData] = useState({});
  const [loading, setLoading] = useState(true);
  const { combinedParams } = useParams();

  const parseParams = (params) => {
    const [idPart, valanIdPart] = params.split("&valanId=");
    const userId = idPart;
    const valanId = valanIdPart;
    return { userId };
  };
  const { userId, valanId } = parseParams(combinedParams);

  // redux
  const loginDetails = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const isCustomer = loginDetails === "customer";
  const summaryData = useSelector(
    (state) => state.globleSlice?.summaryDataFilter
  );
  const withoutBrokerage = summaryData?.withoutBrokerage;

  // life cycle
  useEffect(() => {
    userValanBill();
  }, []);

  // api call
  const userValanBill = async () => {
    let URL = `${EndPoints.userValanBill}${userId}`;
    try {
      setLoading(true);
      let response = await APICall("post", URL, summaryData);
      if (response.status === 200) {
        const valanList = response?.data?.data;
        setValanData(valanList);
        const sortedValan = {};
        sequence.forEach((key) => {
          if (valanList.customerTrades?.[key]) {
            sortedValan[key] = valanList.customerTrades[key];
          }
        });
        setValanBill(sortedValan);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  // column
  const reportColumn = [
    {
      title: "Sr No.",
      dataIndex: "number",
      align: "center",
      width: "2rem",
      render: (_text, record, index) => {
        if (record?.customerId) {
          return <span>{index + 1}</span>;
        }
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      align: "center",
      width: "5rem",
      render: (_text, record) => {
        if (record?.customerId) {
          return (
            <span>{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</span>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "tradeTime",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        if (record?.tradeTime) {
          let time = valanBillDate(record?.tradeTime, true);
          return <span>{time}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "title",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        return (
          <span
            style={{
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            }}
          >
            {record?.title}
          </span>
        );
      },
    },
    {
      title: "Lot",
      dataIndex: "lot",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        const lot = formatNumber(record?.lot);
        return <div>{record?.title !== "live" ? lot : ""}</div>;
      },
    },
    {
      title: "Buy Qty",
      dataIndex: "buyQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let buy = "-";
        if (record?.tradePattern === "buy") {
          buy = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <>
            <div
              style={{
                color: buy !== "-" ? "green" : "",
                fontWeight: "600",
              }}
            >
              {buy}
            </div>
          </>
        );
      },
    },
    {
      title: "Sell Qty",
      dataIndex: "SellQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let sell = "-";
        if (record?.tradePattern === "sell") {
          sell = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <span style={{ color: sell !== "-" ? "red" : "", fontWeight: "500" }}>
            {sell}
          </span>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "tradePrice",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.tradePrice || record?.tradePrice == 0) {
          return formatForValanBill(record?.tradePrice);
        }
      },
    },
    !withoutBrokerage
      ? {
          title: "Net Rate",
          dataIndex: "netRate",
          align: "right",
          width: "4rem",
          render: (_text, record) => {
            if (record?.netRate || record?.netRate == 0) {
              const netRate = formatForValanBill(record?.netRate);
              return netRate;
            }
          },
        }
      : { width: "0rem" },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.netAmount || record?.netAmount == 0) {
          const netAmount = formatForValanBill(record?.netAmount);
          return netAmount;
        }
      },
    },
  ];

  const reportColNseEqu = [
    {
      title: "Sr No.",
      dataIndex: "number",
      align: "center",
      width: "2rem",
      render: (_text, record, index) => {
        if (record?.customerId) {
          return <span>{index + 1}</span>;
        }
      },
    },
    {
      title: "Client",
      dataIndex: "client",
      align: "center",
      width: "5rem",
      render: (_text, record) => {
        if (record?.customerId) {
          return (
            <span>{`${record?.customerId?.userId}-${record?.customerId?.accountName}`}</span>
          );
        }
      },
    },
    {
      title: "Date",
      dataIndex: "tradeTime",
      align: "center",
      width: "8rem",
      render: (text, record) => {
        if (record?.tradeTime) {
          let time = valanBillDate(record?.tradeTime, true);
          return <span>{time}</span>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "title",
      align: "center",
      width: "3rem",
      render: (text, record) => {
        return (
          <span
            style={{
              color: record?.title === "live" ? "green" : "black",
              fontWeight: "500",
            }}
          >
            {record?.title}
          </span>
        );
      },
    },
    // {
    //   title: "Lot",
    //   width: "0px",
    //   align: "center",
    // },

    {
      title: "Buy Qty",
      dataIndex: "buyQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let buy = "-";
        if (record?.tradePattern === "buy") {
          buy = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <>
            <div
              style={{
                color: buy !== "-" ? "green" : "",
                fontWeight: "600",
              }}
            >
              {buy}
            </div>
          </>
        );
      },
    },
    {
      title: "Sell Qty",
      dataIndex: "SellQty",
      align: "center",
      width: "3rem",
      render: (_text, record) => {
        let sell = "-";
        if (record?.tradePattern === "sell") {
          sell = formatToINR(Number(record?.quantity || 0));
        }
        return (
          <span style={{ color: sell !== "-" ? "red" : "", fontWeight: "500" }}>
            {sell}
          </span>
        );
      },
    },
    {
      title: "Rate",
      dataIndex: "tradePrice",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.tradePrice || record?.tradePrice == 0) {
          return formatForValanBill(record?.tradePrice);
        }
      },
    },
    !withoutBrokerage
      ? {
          title: "Net Rate",
          dataIndex: "netRate",
          align: "right",
          width: "4rem",
          render: (_text, record) => {
            if (record?.netRate || record?.netRate == 0) {
              const netRate = formatForValanBill(record?.netRate);
              return netRate;
            }
          },
        }
      : { width: "0rem" },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      align: "right",
      width: "4rem",
      render: (_text, record) => {
        if (record?.netAmount || record?.netAmount == 0) {
          const netAmount = formatForValanBill(record?.netAmount);
          return netAmount;
        }
      },
    },
  ];

  // function
  const TotalRow = ({ data, script, scriptequ }) => (
    <FooterRow>
      <td colSpan="2">ScriptWise Total :</td>
      <td></td>
      <td></td>
      {!scriptequ && <td></td>}
      <td style={{ textAlign: "center" }}>
        {formatToINR(data?.totalBuyQuantity || 0)}
      </td>
      <td style={{ textAlign: "center" }}>
        {formatToINR(data?.totalSellQuantity || 0)}
      </td>
      <td style={{ textAlign: "end" }}>{formatForValanBill(data?.rate)}</td>
      {!withoutBrokerage && !isCustomer ? (
        <td style={{ textAlign: "end" }}>
          {`Br. : ${formatForValanBill(data?.netRate)}`}
        </td>
      ) : (
        <td style={{ width: "0rem" }}></td>
      )}
      <td style={{ textAlign: "end" }}>
        {formatForValanBill(data?.totalNetAmount)}
      </td>
    </FooterRow>
  );

  return (
    <Container isloading={loading.toString()}>
      {loading && (
        <SpinnerOverlay>
          <Spin size="large" />
        </SpinnerOverlay>
      )}

      <PdfRow align={"bottom"} justify={"space-between"}>
        <StyledTheme.ThemeColumn>
          {`Live Bill: ${
            valanData?.user?.accountName ? valanData?.user?.accountName : ""
          }(${valanData?.user?.userId ? valanData?.user?.userId : ""}) ` || 0}
        </StyledTheme.ThemeColumn>
        <StyledTheme.ThemeColumn>
          {`Valan: ${
            valanData?.valan?.valanName ? valanData?.valan?.valanName : ""
          } ` || 0}
        </StyledTheme.ThemeColumn>
        <StyledTheme.ThemeColumn>
          {`From: ${
            valanData?.valan?.startDate
              ? formatDate(valanData?.valan?.startDate)
              : ""
          } To ${
            valanData?.valan?.endDate
              ? formatDate(valanData?.valan?.endDate)
              : ""
          } ` || 0}
        </StyledTheme.ThemeColumn>
        {valanData?.user?.accountName && (
          <StyledTheme.ThemeColumn>
            <PDFDownloadLink
              document={
                <PDFDocument
                  valanBill={valanBill}
                  grossRef={valanData}
                  withoutBrokerage={withoutBrokerage}
                  column={valanBillColumn}
                  isCustomer={isCustomer}
                />
              }
              fileName={`${valanData?.user?.accountName}(${valanData?.user?.userId})valanBill.pdf`}
            >
              {({ blob, url, loading, error }) => (
                <PDFButton loading={loading} style={{ color: "white" }}>
                  Download PDF
                </PDFButton>
              )}
            </PDFDownloadLink>
          </StyledTheme.ThemeColumn>
        )}
      </PdfRow>

      <Row justify={"space-between"} style={{ fontWeight: "500" }}>
        <StyledTheme.ThemeBuyColumn>
          {`Buy Turnover: ${
            formatToINR(valanData?.totalBuyTurnover || 0) || 0
          }`}
        </StyledTheme.ThemeBuyColumn>
        <StyledTheme.ThemeSellColumn>
          {`Sell Turnover: ${
            formatToINR(valanData?.totalSellTurnover || 0) || 0
          }`}
        </StyledTheme.ThemeSellColumn>
      </Row>

      <HeadingRow
        justify={isCustomer ? "center" : "space-between"}
        loading={loading}
      >
        {!isCustomer && (
          <StyledTheme.ThemeColumn>{`Without Brokerage Bill: ${formatToINR(
            valanData?.totalGroce || 0
          )}`}</StyledTheme.ThemeColumn>
        )}
        {!withoutBrokerage && !isCustomer && (
          <StyledTheme.ThemeColumn>{`Total Brokerage: ${formatToINR(
            valanData?.totalBrokerage || 0
          )}`}</StyledTheme.ThemeColumn>
        )}
        {!withoutBrokerage && valanData?.user?.accountType !== "customer" && (
          <StyledTheme.ThemeColumn>
            {`Self Brokerage: ${formatToINR(valanData?.selfBrokerage || 0)}`}
          </StyledTheme.ThemeColumn>
        )}
        {valanData?.user?.accountType !== "customer" && (
          <StyledTheme.ThemeColumn>
            {`Self Amount: `}
            <ColorSpan
              color={
                valanData?.selfAmount
                  ? valanData?.selfAmount > 0
                    ? "green"
                    : "red"
                  : "black"
              }
            >
              {formatToINR(valanData?.selfAmount || 0)}
            </ColorSpan>
          </StyledTheme.ThemeColumn>
        )}

        <StyledTheme.ThemeColumn>
          {`Final Bill With Brokerage: `}
          <ColorSpan
            color={
              valanData?.totalNetAmount
                ? valanData?.totalNetAmount > 0
                  ? "green"
                  : "red"
                : "black"
            }
          >
            {formatToINR(valanData?.totalNetAmount || 0)}
          </ColorSpan>
        </StyledTheme.ThemeColumn>
      </HeadingRow>

      {Object?.keys?.(valanBill)?.length === 0 && (
        <Row justify={"center"} style={{ fontSize: "4rem", marginTop: "4rem" }}>
          <StyledTheme.Table
            columns={valanBillColumn}
            className="w-100"
            dataSource={[]}
            pagination={false}
            locale={{
              emptyText: loading ? <Skeleton active /> : null,
            }}
            bordered={false}
          />
        </Row>
      )}
      {Object?.keys?.(valanBill)?.length > 0 &&
        Object?.keys?.(valanBill)?.map((script, index) => {
          let scriptequ = script === "NSE EQU" ? true : false;
          return (
            <ThemeCard style={{ marginBottom: "2rem" }} key={index}>
              <StyledTheme.TitleRow justify={"space-between"} align="bottom">
                <MarketNameCol xs={24} sm={12}>
                  {script}
                </MarketNameCol>

                <Col xs={24} sm={12} className="segement-bill-title">
                  {`Segment Bill :`}
                  <ColorSpan
                    color={
                      valanBill?.[script]?.totalMarketNetAmount
                        ? valanBill?.[script]?.totalMarketNetAmount > 0
                          ? "green"
                          : "red"
                        : "black"
                    }
                  >
                    {formatToINR(
                      valanBill?.[script]?.totalMarketNetAmount || 0
                    )}
                  </ColorSpan>
                </Col>
              </StyledTheme.TitleRow>
              {valanBill?.[script]?.trades?.map((item, index) => {
                const date = item?.trades?.[0]?.expiryDate;
                const finalDate = valanBillDate(date, false);
                const opt = item?._id?.marketName?.endsWith("OPT");
                const name = opt
                  ? `${item?._id?.symbolName} ${
                      item?._id?.strikePrice ? item?._id?.strikePrice : ""
                    } ${item?._id?.series ? item?._id?.series : ""} `
                  : item?._id?.symbolName;
                return (
                  <div key={index}>
                    <ScriptNameRow justify={"center"}>
                      {name} {finalDate ? finalDate : null}
                    </ScriptNameRow>

                    <StyledTheme.Root style={{ marginBottom: "1rem" }}>
                      <StyledTheme.Table
                        columns={scriptequ ? reportColNseEqu : reportColumn}
                        dataSource={item?.trades}
                        pagination={false}
                        bordered={true}
                        isLoading={loading ? <Skeleton /> : false}
                        rowKey="_id"
                        scroll={{
                          x: 1250,
                        }}
                        style={{
                          position: "sticky",
                        }}
                        summary={() => (
                          <TotalRow
                            data={item}
                            script={script}
                            scriptequ={scriptequ}
                          />
                        )}
                      />
                    </StyledTheme.Root>
                  </div>
                );
              })}
              {!withoutBrokerage && (
                <MarketFooterRow justify={"space-evenly"}>
                  {!isCustomer && (
                    <StyledTheme.ThemeColumn>
                      {`Total Brokerage: ${formatToINR(
                        valanBill?.[script]?.totalBrokerage || 0
                      )}`}
                    </StyledTheme.ThemeColumn>
                  )}
                  {valanData?.user?.accountType !== "customer" && (
                    <StyledTheme.ThemeColumn>
                      {`Self Brokerage: ${formatToINR(
                        valanBill?.[script]?.selfBrokerage || 0
                      )}`}
                    </StyledTheme.ThemeColumn>
                  )}
                </MarketFooterRow>
              )}
            </ThemeCard>
          );
        })}
    </Container>
  );
};

export default ReportCart;
