import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Switch, Tabs, Typography, theme } from "antd";
import ThemeCard from "../../../components/ThemeCard";
import { setNewAccountInfo } from "../../../store/slice/userAccountSlice";
import {
  setBrokerList,
  setBrokerInitialIds,
  setBrokerInitislData,
} from "../../../store/slice/brokaerForUsersFormSlice";
import FooterView from "./FooterView";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import ClientBrokerageView from "./MarketAccess/ClientBrokerageView";
import MarginView from "./MarketAccess/MarginView";
import ScriptWiseSettingsView from "./MarketAccess/ScriptWiseSettingsView";
import { handleMarketValidation } from "../../../Helper/functions";
import AdditionalSharingView from "./components/AdditionalSharingView";
import {
  StyledTheme,
  StyledExclamationOutlined,
  FlexContainer,
  TabContainer,
  TabLabel,
} from "./style";

const MarketAccessView = React.memo(({ is_edits, parentForm }) => {
  const dispatch = useDispatch();
  const token = theme.useToken().token;
  const [show, setShow] = useState(false);

  //state
  const [showMarket, setShowMarket] = useState([]);
  const [tab, setTab] = useState("");

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );

  const market_access = useSelector(
    (state) => state.userAccountSlice.market_access
  );
  const parentDetails = useSelector(
    (state) => state.authSlice.MarketAccessDetails
  );
  const parentMarket = parentDetails?.parentMarketList;
  const childMarketAccess = useSelector(
    (state) => state?.userAccountSlice?.childMarketAccess
  );

  const marketValidationErrors = useSelector(
    (state) => state.globleSlice.marketValidationErrors
  );

  const marketList = useSelector((state) => state.globleSlice.marketListData);
  const List = useMemo(
    () => marketList?.map((el) => el?.scriptName),
    [marketList]
  );

  const formMarket = useMemo(() => {
    return showMarket?.map((el, index) => {
      let checked = market_access?.includes(el.value);
      let isValid = marketValidationErrors[el?.value];
      return {
        label: (
          <FlexContainer>
            <TabContainer
              onClick={() => handleTabChange(el?.value)}
              isActive={tab === el?.value}
              isChecked={checked}
              isValid={isValid}
              // token={token}
            >
              <StyledTheme.Checkbox
                checked={checked}
                onChange={(e) => handleTabCheck(e, el?.value)}
              />
              <TabLabel
                isActive={tab === el?.value}
                isChecked={checked}
                // token={token}
              >
                {el?.label}
              </TabLabel>
            </TabContainer>
            <div>{isValid === true && <StyledExclamationOutlined />}</div>
          </FlexContainer>
        ),
        key: `${el?.scriptName}`,
        children: (
          <MarketAccessDetailView
            tab={el?.scriptName}
            show={show}
            setShow={setShow}
            is_edits={is_edits}
            parentForm={parentForm}
          />
        ),
        forceRender: true,
      };
      // return {
      //   label: (
      //     <div style={{ display: "flex" }}>
      //       <div
      //         onClick={() => handleTabChange(el?.value)}
      //         style={{
      //           width: "100%",
      //           display: "flex",
      //           alignItems: "center",
      //           backgroundColor:
      //             tab === el?.value
      //               ? token.colorPrimary
      //               : checked
      //               ? token.colorPrimaryBgHover
      //               : "white",
      //           borderRadius: token.borderRadiusLG,
      //           padding: "1.5rem",
      //           border: isValid
      //             ? "2px solid #b93d3d"
      //             : `1px solid ${token.colorBorder}`,
      //           position: "relative",
      //           cursor: "pointer",
      //         }}
      //       >
      //         <StyledTheme.Checkbox
      //           checked={checked}
      //           onChange={(e) => handleTabCheck(e, el?.value)}
      //         />
      //         <span
      //           style={{
      //             marginInline: "0.5rem",
      //             color:
      //               tab === el?.value
      //                 ? token.colorTextLightSolid
      //                 : checked
      //                 ? "white"
      //                 : token.colorText,
      //             fontSize: "1rem",
      //             fontWeight: 600,
      //           }}
      //         >
      //           {el?.label}
      //         </span>
      //       </div>
      //       <div>
      //         {isValid === true && (
      //           <ExclamationOutlined
      //             style={{
      //               color: "red",
      //               // marginLeft: "2rem",
      //               fontSize: "1.5rem",
      //             }}
      //           />
      //         )}
      //       </div>
      //     </div>
      //   ),
      //   key: `${el?.scriptName}`,
      //   children: (
      //     <MarketAccessDetailView
      //       key={el?.scriptName}
      //       tab={el?.scriptName}
      //       show={show}
      //       setShow={setShow}
      //       is_edits={is_edits}
      //       parentForm={parentForm}
      //     />
      //   ),
      //   forceRender: true,
      // };
    });
  }, [
    showMarket,
    market_access,
    marketValidationErrors,
    tab,
    token,
    show,
    is_edits,
    parentForm,
  ]);

  useEffect(() => {
    setTab(List[0]);
    setShow(false);
  }, [accountType]);
  // life cycle
  useEffect(() => {
    if (is_edits) {
      setTab(market_access[0]);
    } else {
      setTab(List[0]);
    }

    getBroker();
    let dummy = [];
    if (!is_edits) {
      let includes = market_access?.includes(List[0]);
      if (!includes) {
        dummy = [...market_access, List[0]];
        dispatch(setNewAccountInfo({ market_access: dummy }));
      }
    }
  }, []);

  useEffect(() => {
    if (is_edits && accountType !== "masterAdmin") {
      const market = parentMarket?.map((el) => {
        return { label: el, value: el, scriptName: el };
      });
      setShowMarket(market);
    } else {
      setShowMarket(marketList);
    }
  }, [is_edits, parentMarket]);

  const getBroker = async () => {
    try {
      let response = await APICall("get", `${EndPoints.getUserByFilter}broker`);
      if (response.status === 200) {
        // console.log("response", response.data);
        const broker = response?.data?.data?.finalResult;
        const brokerAll = broker.map((el) => ({
          label: `${el?.userId}(${el?.accountName})`,
          value: el?.userId,
        }));
        dispatch(setBrokerInitislData(brokerAll));
        if (is_edits && accountType === "customer") {
          dispatch(setBrokerInitialIds(brokerAll));
          let idFromForm = parentForm
            ?.getFieldValue("additionalSharing")
            ?.map((item) => item.brokerId);
          let idFromAlllScrip = brokerAll.map((item) => item.value);
          let exIds = idFromAlllScrip.filter(
            (item) => !idFromForm.includes(item)
          );
          dispatch(
            setBrokerList(
              brokerAll.filter((item) => exIds.includes(item.value))
            )
          );
        } else {
          dispatch(setBrokerList(brokerAll));
          dispatch(setBrokerInitialIds(brokerAll));
        }
        // }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //functions
  const handleTabChange = (activeKey) => {
    if (tab === activeKey) {
      setTab("");
    } else {
      setTab(activeKey);
      let dummy = [];
      let includes = market_access?.includes(activeKey);
      // console.log("activeKey", activeKey, includes, market_access);
      if (!includes) {
        dummy = [...market_access, activeKey];
        dispatch(setNewAccountInfo({ market_access: dummy }));
      }
    }
  };

  const handleTabCheck = (e, activeKey) => {
    e.stopPropagation();
    const checked = e.target.checked;
    if (!checked) {
      handleMarketValidation(parentForm, dispatch);
    }
    if (!checked && is_edits) {
      const include = childMarketAccess?.includes(activeKey);
      if (include) {
        return;
      }
    }
    let dummy = [];
    if (market_access?.includes(activeKey)) {
      dummy = market_access.filter((o1) => o1 !== activeKey);
    } else {
      dummy = [...market_access, activeKey];
    }
    if (e.target.checked) {
      setTab(activeKey);
    }
    dispatch(setNewAccountInfo({ market_access: dummy }));
  };

  return (
    <ThemeCard
      title="Market Access"
      bordered={false}
      style={{ marginBlock: "3rem", flexDirection: "column", width: "100%" }}
    >
      <StyledTheme.Wrapper>
        {formMarket.length > 0 && (
          <Tabs
            items={formMarket}
            activeKey={tab}
            // tabPosition="mode"
            draggable={false}
            className="users-tabs-view"
            onChange={handleTabChange}
            style={{
              marginBottom: "2rem",
            }}
          />
        )}
      </StyledTheme.Wrapper>
    </ThemeCard>
  );
});

export default MarketAccessView;

const MarketAccessDetailView = React.memo(
  ({ tab, is_edits, parentForm, show, setShow, key }) => {
    const token = theme.useToken().token;

    //redux
    const partnerShip = useSelector(
      (state) => state.userAccountSlice?.accountDetails?.partnershipWith
    );
    const market_access = useSelector(
      (state) => state.userAccountSlice.market_access
    );
    const accountType = useSelector(
      (state) => state.userAccountSlice?.accountType
    );
    const parentScriptSetting = useSelector(
      (state) => state.authSlice?.MarketAccessDetails?.additional
    );
    const isRent = partnerShip === "rent";
    const scriptSetting = parentScriptSetting?.scriptWiseSettings;
    const additionalSetting = parentScriptSetting?.additionalSharing;
    let checked = market_access?.includes(tab) && tab;

    // function
    const onClickLink = () => {
      setShow(!show);
    };

    return (
      <div>
        {checked && (
          // <Row align={"center"} style={{ margin: "1rem 0rem" }}>
          <Form.Item
            label="Only position squerUp"
            name={`isOnlyPositionSquareup$${tab}`}
            defaultValue={false}
            labelCol={{ xs: 20 }}
            wrapperCol={{ xs: 4 }}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "1rem 0rem",
            }}
          >
            <Switch
              // style={{ width: "100%" }}
              name={`isOnlyPositionSquareup$${tab}`}
              // onChange={offlineTradeHandler}
            />
          </Form.Item>
          // </Row>
        )}
        {checked && !isRent ? (
          <ClientBrokerageView
            key={key}
            tab={tab}
            view={"brokerageDetails"}
            is_edits={is_edits}
            form={parentForm}
          />
        ) : null}
        {checked ? (
          <MarginView
            key={key}
            tab={tab}
            view={"margin"}
            is_edits={is_edits}
            form={parentForm}
          />
        ) : null}
        <>
          {accountType === "customer" && additionalSetting !== "block" ? (
            <div className={show && checked ? "d-block" : "d-none"}>
              <AdditionalSharingView
                key={key}
                tab={tab}
                parentForm={parentForm}
                view={"additionalSharing"}
                is_edits={is_edits}
              />
            </div>
          ) : null}
          <div className={show && checked ? "d-block" : "d-none"}>
            {(scriptSetting !== "block" || accountType === "masterAdmin") && (
              <ScriptWiseSettingsView
                key={key}
                tab={tab}
                view={"scriptWiseSettingInfo"}
                is_edits={is_edits}
              />
            )}
          </div>

          {show &&
          checked &&
          (scriptSetting !== "block" || accountType === "masterAdmin") ? (
            <div>
              <FooterView
                key={key}
                tab={tab}
                view={"allScript"}
                is_edits={is_edits}
                parentForm={parentForm}
              />
            </div>
          ) : null}
        </>

        <Typography.Text
          onClick={onClickLink}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "end",
            color: token.blue,
          }}
        >
          {(scriptSetting !== "block" || accountType === "masterAdmin") &&
          tab !== "" &&
          checked ? (
            <>
              {show
                ? "Hide Script Wise Setting (optional)"
                : "Show Script Wise Setting (optional)"}
            </>
          ) : null}
        </Typography.Text>
      </div>
    );
  }
);
