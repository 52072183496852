import { useQuery, useQueryClient } from "@tanstack/react-query";
import ThemeDropDown from "../ThemeDropDown";
import React, { useEffect, useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserClearFilter,
  setValanValue,
} from "../../store/slice/globleSlice";
import useGetQueryParams from "../../hooks/useGetQueryParams";

const ValanComponant = React.memo(({ valueRef }) => {
  // state
  const [valanOption, setValanOption] = useState([]);
  const [value, setValue] = useState(null);
  const { params } = useGetQueryParams();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // redux
  const clearValan = useSelector((state) => state.globleSlice?.userClearFilter);
  const setValan = useSelector((state) => state.globleSlice?.clearValan);

  // api
  const getValanList = async () => {
    let response = await APICall("get", EndPoints.getValanList);
    return response?.data?.data?.valanList;
  };
  const {
    isLoading: valanLoading,
    error: valanError,
    data: valanData,
  } = useQuery({ queryKey: ["getValan"], queryFn: getValanList });

  // life cycle

  useEffect(() => {
    queryClient.refetchQueries(["getValan"]);
    if (params?.valan) {
      setValue(params?.valan);
      valueRef.current = {
        ...valueRef.current,
        valan: params?.valan,
      };
    }
  }, []);

  useEffect(() => {
    if (clearValan) {
      setValue(null);
      dispatch(setUserClearFilter(false));
    }
  }, [clearValan]);

  useEffect(() => {
    if (valanData) {
      const finalValan = valanData.map((el) => {
        return { label: el?.valanName, value: el?._id };
      });
      setValanOption(finalValan);
      if (!params?.valan) {
        setValue(finalValan?.[0]?.value);
        valueRef.current = {
          ...valueRef.current,
          valan: finalValan?.[0]?.value,
        };
      }
    }
  }, [valanData]);

  useEffect(() => {
    if (!setValan) {
      setValue(null);
      valueRef.current = { ...valueRef.current, valan: null };
    }
  }, [setValan]);

  // function
  const onChangeDropDown = (name, value, options) => {
    setValue(value?.[0] ? value[0] : null);
    valueRef.current = {
      ...valueRef.current,
      valan: value?.[0] ? value[0] : null,
      startDate: null,
      endDate: null,
    };
    dispatch(setValanValue(true));
  };

  return (
    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
      <ThemeDropDown
        title={"Valan"}
        placeholder="Valan"
        name="valan"
        width={"98%"}
        options={valanOption}
        $onChange={onChangeDropDown}
        value={value}
        loadings={valanLoading ? "true" : "false"}
        // suffixIcon={<Spin size="small" spinning={valanLoading} />}
        // disabled={valanLoading}
      />
    </Col>
  );
});

export default ValanComponant;
