import { Button, Col, Form, Modal, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import ThemeInput from "../ThemeInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import useCheckPassward from "../../hooks/useCheckPassward";

const VerificationModal = React.memo(
  ({
    handleCancel,
    record,
    handleExecute,
    executeLoading,
    type,
    title,
    query,
  }) => {
    const [form] = Form.useForm();
    const queryClient = useQueryClient();
    const [isLoading, setIsLoading] = useState(false);
    const { flage, passwardLoding, passwardMutate, setFlage } =
      useCheckPassward();

    const {
      isLoading: deleteLoading,
      error,
      mutate,
    } = useMutation({
      mutationFn: async (payload) => {
        let response = await APICall(
          "delete",
          `${EndPoints.deleteTrade}${payload?.id}`,
          payload
        );

        return response;
      },
      onSuccess: (res) => {
        if (res?.status === 200) {
          notification.success({ message: res?.data?.message });
          queryClient.refetchQueries([query]);
          handleCancel();
        } else {
          notification.error({ message: res?.data?.message });
        }
      },
    });

    useEffect(() => {
      // Start loading when either password verification or execution starts
      if (passwardLoding || executeLoading || deleteLoading) {
        setIsLoading(true);
      }
      // Only stop loading if both operations are complete and flag is false
      if (!passwardLoding && !executeLoading && !deleteLoading && !flage) {
        setIsLoading(false);
      }
    }, [passwardLoding, executeLoading, deleteLoading, flage]);

    useEffect(() => {
      if (flage) {
        if (type === "execute") {
          handleExecute(record);
        } else {
          mutate({
            deviceType: "web",
            tradeIpAddress: record?.tradeIpAddress,
            id: record?._id,
          });
        }
        setFlage(false);
      }
    }, [flage]);

    const handleFinishFailed = (vlaue) => {
      console.log(vlaue, "failed");
    };

    const handleFinish = (vlaue) => {
      passwardMutate(vlaue.passward);
    };

    const handleSubmit = () => {
      form.submit();
    };

    return (
      <Modal
        title={title}
        open={true}
        onCancel={handleCancel}
        onOk={handleSubmit}
        confirmLoading={isLoading}
      >
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <Row
            align={"middle"}
            justify={"space-between"}
            gutter={[10, 10]}
            style={{ marginTop: "2rem" }}
          >
            <Col span={24}>
              <ThemeInput
                title="Passward"
                name="passward"
                formProps={{
                  name: "passward",
                  rules: [{ required: true }],
                }}
                wrapperProps={{ style: { width: "90%" } }}
              />
            </Col>
            {/* <Col
            xs={24}
            sm={6}
            md={8}
            lg={8}
            xl={8}
            style={{ textAlign: "center" }}
          >
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Col> */}
          </Row>
        </Form>
      </Modal>
    );
  }
);

export default VerificationModal;
