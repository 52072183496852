import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  theme,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  SearchOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { SEGMENTS, options } from "../../../Helper/constant";
import { useDispatch, useSelector } from "react-redux";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import {
  setBlockedScripts,
  setEditData,
} from "../../../store/slice/scriptSettingsRequestSlice";
import { useMutation } from "@tanstack/react-query";
import { useWatch } from "antd/es/form/Form";

const _ = require("lodash");

const FooterView = ({ tab, view, parentForm }) => {
  const wrapperRef = useRef(null);

  return (
    <div>
      <WrapperView
        ref={wrapperRef}
        tab={tab}
        view={view}
        parentForm={parentForm}
      />
    </div>
  );
};

export default FooterView;

const EditableCell = React.memo(
  ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    editable,
    children,
    validatorFunction,
    isValidation,
    brokerageType,
    watchBrokerageType,
    tab,
    ...restProps
  }) => {
    const inputNode =
      inputType === "maskedNumber" || inputType === "number" ? (
        <InputNumber
          addonAfter={
            inputType === "number"
              ? null
              : watchBrokerageType === "percentage"
              ? "%"
              : "Fix"
          }
          placeholder="0"
          min={0}
          readOnly={
            record.id === "All" &&
            (dataIndex === "delivery" || dataIndex === "intraday")
          }
          // disabled={
          //   record.id === "All" &&
          //   (dataIndex === "delivery" || dataIndex === "intraday")
          // }
        />
      ) : inputType === "checkbox" ? (
        <Checkbox />
      ) : inputType === "dropdown" ? (
        <Select
          defaultValue={tab === "NSE EQU" ? "quantity" : "lot"}
          style={{
            width: 120,
          }}
          options={[
            {
              value: "lot",
              label: "Lot",
            },
            {
              value: "quantity",
              label: "Quantity",
            },
            {
              value: "value",
              label: "Value",
            },
          ].filter((item) => (tab === "NSE EQU" ? item.value !== "lot" : item))}
        />
      ) : (
        <Input placeholder="0" />
      );
    if (editable) {
      return (
        <td {...restProps}>
          {editing ? (
            <Form.Item
              name={dataIndex}
              initialValue={
                dataIndex === "type" && (tab === "NSE EQU" ? "quantity" : "lot")
              }
              rules={
                isValidation
                  ? [
                      () => ({
                        validator(_, value) {
                          return validatorFunction(_, value);
                        },
                      }),
                    ]
                  : null
              }
              style={{
                margin: 0,
              }}
            >
              {inputNode}
            </Form.Item>
          ) : (
            children
          )}
        </td>
      );
    } else {
      return (
        <td>
          <Form.Item name={dataIndex}> {children}</Form.Item>
        </td>
      );
    }
  }
);

const WrapperView = (props, _ref) => {
  const { tab, parentForm } = props;
  const dataOffset = useRef(0);
  const valueRef = useRef([]);
  const dispatch = useDispatch();
  let canCallAPI = true;
  const [page, setPage] = useState(1);

  //state
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  //redux
  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const search = useSelector((state) => state?.scriptSettingSlice?.search);
  const scriptInfo = useSelector(
    (state) =>
      state?.userAccountSlice?.[tab]?.["scriptWiseSettingInfo"]?.selectedScript
  );
  const apidata = useSelector(
    (state) => state?.userAccountSlice?.[tab]?.brokerageDetails
  );

  const initalDelivery = apidata?.deliveryTotal;
  const initalIntraday = apidata?.intradayTotal;

  const scripSettings = useSelector((state) => state.scriptSettingSlice);
  const parentScriptS = useSelector(
    (state) => state.userAccountSlice.parentValidation
  );
  const brokarageDetails = useSelector(
    (state) => state.userAccountSlice?.parentValidation?.brokarageDetails?.[tab]
  );
  const watchBrokerageType = useWatch(`brokerageType$${tab}`, parentForm);
  const watchIntraday = useWatch(`intradayTotal$${tab}`, parentForm);
  const watchDelivery = useWatch(`deliveryTotal$${tab}`, parentForm);

  const userAccountType = useSelector(
    (state) => state.userAccountSlice.accountType
  );
  const isRent = parentScriptS?.accountDetails?.partnershipWith === "rent";

  const scriptSettingsRequest = useSelector(
    (state) => state.scriptSettingsRequestSlice?.[tab]
  );

  //lifecycle
  useEffect(() => {
    if (scriptInfo === true) {
      setData((pre) =>
        pre?.map((item) => {
          return { ...item, checked: true };
        })
      );
    } else {
      setData((pre) =>
        pre?.map((item) => {
          return { ...item, checked: false };
        })
      );
    }
  }, [scriptInfo]);

  useEffect(() => {
    setExtraRow((pre) => ({
      ...pre,
      delivery: watchDelivery,
      intraday: watchIntraday,
    }));
  }, [watchDelivery, watchIntraday]);

  useDebounce(
    () => {
      if (search === "") {
        setData([]);
        appendData(page);
      } else {
        const filteredData = valueRef.current?.filter((el) =>
          el?.scriptName.toLowerCase().includes?.(search.toLowerCase())
        );
        setData(filteredData);
      }
    },
    1,
    [search]
  );

  useEffect(() => {
    if (tab) {
      setLoading(true);
      if (data?.length > 0) setData([]);
      dataOffset.current = 0;
      appendData(page);
    }
  }, [tab]);
  const {
    data: marketArrayForAllCheck,
    isLoading: allCheckArrayLoading,
    isError,
    mutate: mutateAllArray,
  } = useMutation({
    mutationFn: () =>
      fetch(
        `${process.env.REACT_APP_API_URL}api/v1${EndPoints.getTableScript}${SEGMENTS[tab]}`,
        {
          method: "get",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSelectedRow([
            "All",
            ...data?.data?.data?.map((item) => item.symbolName),
          ]);
        }),
  });
  //function
  const appendData = async (page, search, sortColumn, sortType) => {
    if (!canCallAPI) {
      return null;
    }
    canCallAPI = false;
    let dummyData = [];
    try {
      setLoading(true);
      let response = await APICall(
        "get",
        `${EndPoints.getTableScript}${
          SEGMENTS[tab]
        }&page=${page}&limit=${10}&search=${search || ""}&sortColumn=${
          sortColumn || ""
        }&sortType=${sortType || ""}`
      );

      setData([]);
      setPage(Number(response?.data?.data?.currentPage));
      setTotalData(response?.data?.data?.totalPages);
      dummyData = response?.data?.data?.data;
      let count = 1;
      /////////////
      let draft = [];

      let temp = scriptSettingsRequest?.edited.find(
        (item) => item.scriptName === "All"
      );
      for (let index = 0; index < dummyData?.length; index++) {
        draft.push({
          id: count++,
          scriptName: dummyData[index].symbolName,
          script_name_value: dummyData[index].symbol,
          minValue:
            Number(scripSettings[tab][dummyData[index].symbolName]?.minValue) ||
            null,
          maxValue:
            Number(scripSettings[tab][dummyData[index].symbolName]?.maxValue) ||
            null,
          delivery:
            Number(scripSettings[tab][dummyData[index].symbolName]?.delivery) ||
            null,
          intraday:
            Number(scripSettings[tab][dummyData[index].symbolName]?.intraday) ||
            null,
          total:
            Number(scripSettings[tab][dummyData[index].symbolName]?.total) ||
            null,
          type: scripSettings[tab][dummyData[index].symbolName]?.type
            ? scripSettings[tab][dummyData[index].symbolName]?.type
            : tab === "NSE EQU"
            ? "quantity"
            : "lot",

          open: dummyData[index]?.open,
          lotSize: dummyData[index]?.lotSize,
        });
      }

      setExtraRow({ ...extraRow, ...scripSettings?.[tab]?.["All"], ...temp });
      setData([...draft]);

      //////////////

      setLoading(false);
      dataOffset.current = dataOffset.current + 5;
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    } finally {
      setTimeout(() => {
        canCallAPI = true;
      }, 1000);
    }

    // return console.log("dummyData", dummyData);
    // const newData = dummyData.slice(
    //   dataOffset.current,
    //   dataOffset.current + 5
    // );
  };
  //////////////////////////////////////////////////////////////////////////
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState("");
  const [editingKey, setEditingKey] = useState("");

  const [selectedRow, setSelectedRow] = useState([]);
  const [extraRow, setExtraRow] = useState({
    key: "0",
    id: "All",
    block: false,
    scriptName: "All",
    // isExtra: true,
    open: "",
    delivery: watchDelivery || initalDelivery,
    intraday: watchIntraday || initalIntraday,
    lqv: null,
    min: null,
    max: null,
    total: null,
    operation: "operation",
  });
  const isEditing = (record) => record.id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      ...record,
    });
    setEditingKey(record.id);
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    if (key === "All") {
      let isTouched = form.isFieldsTouched();
      let temp = form.getFieldsValue();
      if (temp?.maxValue === false) {
        temp.maxValue = null;
      }
      if (temp?.minValue === false) {
        temp.minValue = null;
      }
      if (isTouched) {
        dispatch(
          setEditData({
            tab: tab,
            edited: [...scriptSettingsRequest.edited, { ...temp }],
          })
        );
      }
      setExtraRow({ ...extraRow, ...temp });
      setEditingKey("");
    } else {
      try {
        const row = await form.validateFields();
        let filtered = scriptSettingsRequest.edited.filter(
          (item) => item.scriptName !== row.scriptName
        );
        dispatch(
          setEditData({
            tab: tab,
            edited: [...filtered, row],
          })
        );
        const newData = [...data];
        const index = newData.findIndex((item) => key === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setData(newData);

          setEditingKey("");
        } else {
          newData.push(row);
          setData(newData);
          setEditingKey("");
        }
      } catch (errInfo) {
        console.log("Validate Failed:", errInfo);
      }
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      editable: false,
      width: "4%",
    },
    {
      title: "Block",
      dataIndex: "block",
      width: "4%",
      editable: false,
      render: (_, record) => {
        const parentBlock = parentScriptS?.blockScript?.[tab]?.includes(
          record?.scriptName
        );
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              name={record.scriptName}
              onChange={async (e) => {
                let isChecked = e.target.checked;
                if (record.id === "All") {
                  if (isChecked) {
                    let temp = data.map((item) => item.scriptName);
                    await mutateAllArray();
                    setSelectedRow(["All", ...temp]);
                  } else {
                    let keys = [];
                    for (let key in scripSettings[tab]) {
                      let isParentBlocked =
                        parentScriptS?.blockScript?.[tab]?.includes(key);
                      isParentBlocked && keys.push(key);
                    }
                    setSelectedRow([...keys]);
                  }
                } else {
                  await setSelectedRow((prevSelectedRow) =>
                    isChecked
                      ? [...prevSelectedRow, record.scriptName]
                      : prevSelectedRow.filter(
                          (item) => item !== record.scriptName && item !== "All"
                        )
                  );
                }
              }}
              checked={selectedRow.includes(record.scriptName) || parentBlock}
              disabled={
                record.id === "All"
                  ? false
                  : parentScriptS?.blockScript?.[tab]?.includes(
                      `${record.scriptName}`
                    )
                  ? true
                  : false
              }
            />
          </div>
        );
      },
    },
    {
      title: "Script Name",
      dataIndex: "scriptName",
      width: "9%",
      sorter: true,
      editable: false,
      render: (_, record) => {
        return (
          <div>
            <div>{record.scriptName}</div>
            {record.scriptName !== "" && record.scriptName !== "All" && (
              <div>Lot size : {record.lotSize}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Close Price",
      dataIndex: "open",
      editable: false,
      width: "9%",
    },
    {
      title: "Brokerage",
      dataIndex: "brokerage",
      editable: true,
      children: [
        {
          title: "Delivery",
          dataIndex: "delivery",
          inputType: "maskedNumber",
          editable: true,
          width: "9%",
          isValidation: true,
          render: (_, record) => {
            let isBlock = parentScriptS?.blockScript?.[tab]?.includes(
              `${record.scriptName}`
            );
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            return (
              <Tooltip
                trigger={"hover"}
                title={`${
                  record?.scriptName !== "All" ? "Click To Start Editing" : ""
                }`}
              >
                <InputNumber
                  value={record?.delivery}
                  disabled={isBlock || newBlock}
                  onClick={() => {
                    record?.scriptName !== "All" && edit(record);
                  }}
                  readOnly={
                    record?.scriptName === "All" && !isBlock && !newBlock
                  }
                  addonAfter={watchBrokerageType === "percentage" ? "%" : "Fix"}
                />
              </Tooltip>
            );
          },
          validatorFunction: (a, b) => {
            if (b < (isRent ? 0 : brokarageDetails?.deliveryTotal)) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${
                    isRent ? "0" : brokarageDetails?.deliveryTotal
                  } ${
                    watchBrokerageType === "percentage" ? `and less than 1` : ""
                  } `
                )
              );
            } else if (watchBrokerageType === "percentage" && b >= 1) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${brokarageDetails?.deliveryTotal} and less than 1`
                )
              );
            } else {
              return Promise.resolve();
            }
          },
        },
        {
          title: "Intraday",
          dataIndex: "intraday",
          inputType: "maskedNumber",
          editable: true,
          width: "9%",
          isValidation: true,
          render: (_, record) => {
            let isBlock = parentScriptS?.blockScript?.[tab]?.includes(
              `${record.scriptName}`
            );
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            return (
              <Tooltip
                trigger={"hover"}
                title={`${
                  record?.scriptName !== "All" ? "Click To Start Editing" : ""
                }`}
              >
                <InputNumber
                  value={record?.intraday}
                  disabled={isBlock || newBlock}
                  onClick={() => {
                    record?.scriptName !== "All" && edit(record);
                  }}
                  readOnly={
                    record?.scriptName === "All" && !isBlock && !newBlock
                  }
                  addonAfter={watchBrokerageType === "percentage" ? "%" : "Fix"}
                />
              </Tooltip>
            );
          },
          validatorFunction: (a, b) => {
            if (b < (isRent ? 0 : brokarageDetails?.intradayTotal)) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${
                    isRent ? "0" : brokarageDetails?.intradayTotal
                  } ${
                    watchBrokerageType === "percentage" ? `and less than 1` : ""
                  } `
                )
              );
            } else if (watchBrokerageType === "percentage" && b >= 1) {
              return Promise.reject(
                new Error(
                  `Value must be greater than ${brokarageDetails?.intradayTotal} and less than 1`
                )
              );
            } else {
              return Promise.resolve();
            }
          },
        },
      ],
    },
    {
      title: "L / Q / V",
      dataIndex: "type",
      inputType: "dropdown",
      width: "9%",
      editable: true,
      render: (_, record) => {
        let isBlock = parentScriptS?.blockScript?.[tab]?.includes(
          `${record.scriptName}`
        );
        let newBlock = scriptSettingsRequest?.blocked?.includes(
          record?.scriptName
        );
        let defalutvalue = tab === "NSE EQU" ? "quantity" : "lot";

        return (
          <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
            <Select
              style={{ minWidth: "100px" }}
              value={record?.type || defalutvalue}
              open={false}
              onClick={() => !isBlock && !newBlock && edit(record)}
            />
          </Tooltip>
        );
      },
    },
    {
      title: "Single Trade",
      dataIndex: "singleTrade",
      width: "9%",
      editable: true,

      children: [
        {
          title: "Min",
          dataIndex: "minValue",
          width: "9%",
          isValidation: false,
          editable: true,
          inputType: "number",
          render: (_, record) => {
            let isBlock = parentScriptS?.blockScript?.[tab]?.includes(
              `${record.scriptName}`
            );
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            return (
              <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
                <InputNumber
                  onClick={() => edit(record)}
                  value={record?.minValue}
                  readOnly
                  disabled={isBlock || newBlock}
                />
              </Tooltip>
            );
          },
        },
        {
          title: "Max",
          dataIndex: "maxValue",
          width: "9%",
          isValidation: false,
          editable: true,
          inputType: "number",
          render: (_, record) => {
            let isBlock = parentScriptS?.blockScript?.[tab]?.includes(
              `${record.scriptName}`
            );
            let newBlock = scriptSettingsRequest?.blocked?.includes(
              record?.scriptName
            );
            return (
              <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
                <InputNumber
                  onClick={() => edit(record)}
                  value={record?.maxValue}
                  readOnly
                  disabled={isBlock || newBlock}
                />
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: "Total",
      dataIndex: "total",
      inputType: "number",
      width: "9%",
      isValidation: true,
      editable: true,
      render: (_, record) => {
        let isBlock = parentScriptS?.blockScript?.[tab]?.includes(
          `${record.scriptName}`
        );
        let newBlock = scriptSettingsRequest?.blocked?.includes(
          record?.scriptName
        );
        return (
          <Tooltip trigger={"hover"} title={`Click To Start Editing`}>
            <InputNumber
              onClick={() => edit(record)}
              value={record.total}
              readOnly
              disabled={isBlock || newBlock}
            />
          </Tooltip>
        );
      },

      validatorFunction: (a, b) => {
        if (
          isRent
            ? b < 0
            : b > parentScriptS?.marginDetails?.[tab].delivery || b < 0
        ) {
          return Promise.reject(
            new Error(
              ` You can not give total less then  ${
                isRent ? "0" : parentScriptS?.marginDetails?.[tab].delivery
              }`
            )
          );
        } else {
          return Promise.resolve();
        }
      },
    },
    {
      title: "operation",
      dataIndex: "operation",
      width: "9%",
      // fixed: "right",
      editable: false,
      render: (_, record) => {
        const editable = isEditing(record);

        return (
          <div style={{ width: "100px" }}>
            {editable ? (
              <>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => save(record.id)}
                  icon={<SaveOutlined />}
                  style={{
                    marginRight: 8,
                  }}
                ></Button>
                <Popconfirm
                  title="Sure to cancel?"
                  placement="topRight"
                  onConfirm={cancel}
                >
                  <Button size="small" danger icon={<StopOutlined />}></Button>
                </Popconfirm>
              </>
            ) : (
              <>
                <Button
                  type="primary"
                  size="small"
                  disabled={
                    accountType !== "customer" ||
                    selectedRow.includes(record.scriptName) ||
                    editingKey !== "" ||
                    parentScriptS?.blockScript?.[tab]?.includes(
                      `${record.scriptName}`
                    )
                      ? true
                      : false
                  }
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                  style={{ marginRight: 8 }}
                ></Button>
                <Button
                  title="Edit"
                  type="primary"
                  style={{ visibility: "hidden" }}
                  size="small"
                  disabled={editingKey !== ""}
                  onClick={() => edit(record)}
                  icon={<EditOutlined />}
                ></Button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const otherColumns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      editable: false,
      width: "4%",
    },
    {
      title: "Block",
      dataIndex: "block",
      width: "4%",
      editable: false,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              name={record.scriptName}
              onChange={(e) => {
                let isChecked = e.target.checked;
                if (record.id === "All") {
                  if (isChecked) {
                    let temp = data.map((item) => item.scriptName);
                    mutateAllArray();
                    setSelectedRow(["All", ...temp]);
                  } else {
                    let keys = [];
                    for (let key in scripSettings[tab]) {
                      let isParentBlocked =
                        parentScriptS?.blockScript?.[tab]?.includes(key);
                      isParentBlocked && keys.push(key);
                    }
                    setSelectedRow([...keys]);
                  }
                } else {
                  if (isChecked) {
                    setSelectedRow([...selectedRow, record.scriptName]);
                  } else {
                    setSelectedRow(
                      selectedRow.filter(
                        (item) => item !== record.scriptName && item !== "All"
                      )
                    );
                  }
                }
              }}
              checked={selectedRow.includes(record.scriptName)}
              disabled={
                record.id === "All"
                  ? false
                  : parentScriptS?.blockScript?.[tab]?.includes(
                      `${record.scriptName}`
                    )
                  ? true
                  : false
              }
            />
          </div>
        );
      },
    },
    {
      title: "Script Name",
      dataIndex: "scriptName",
      width: "9%",
      sorter: true,
      editable: false,
      render: (_, record) => {
        return (
          <div>
            <div>{record.scriptName}</div>
            {record.scriptName !== "All" && (
              <div>Lot size : {record.lotSize}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Open Price",
      dataIndex: "open",
      sorter: true,
      editable: false,
      width: "9%",
    },
  ];

  useEffect(() => {
    if (scripSettings) {
      let keys = [];
      let blocked = scriptSettingsRequest.blocked;
      for (let key in scripSettings[tab]) {
        scripSettings[tab][key]?.isBlocked && keys.push(key);
      }
      setSelectedRow([...selectedRow, ...keys, ...blocked]);
    }
  }, [scripSettings]);

  useEffect(() => {
    if (editingKey === "") {
      form.resetFields();
    }
  }, [editingKey]);

  function appendDataByKey(targetArray, sourceArray, key) {
    // Create a map from the source array for quick lookups
    const sourceMap = new Map(sourceArray.map((item) => [item[key], item]));

    // Append data from the source map to the target array
    return targetArray.map((item) => {
      const sourceItem = sourceMap.get(item[key]);
      if (sourceItem) {
        return { ...item, ...sourceItem };
      }
      return item;
    });
  }

  useEffect(() => {
    let main = document
      .querySelector(".table-body")
      ?.querySelector(".ant-table-content");
    let header = document
      .querySelector(".table-header")
      ?.querySelector(".ant-table-content");
    if (main && header) {
      main.addEventListener("scroll", () => {
        header.scrollLeft = main.scrollLeft;
      });
      header.addEventListener("scroll", () => {
        main.scrollLeft = header.scrollLeft;
      });
    }
  }, [editingKey]);

  useEffect(() => {
    if (scriptSettingsRequest?.edited?.length > 0 && data && !loading) {
      let tempData = appendDataByKey(
        data,
        scriptSettingsRequest?.edited,
        "scriptName"
      );
      setData([...tempData]);
    }
  }, [loading]);

  useEffect(() => {
    dispatch(setBlockedScripts({ tab: tab, blocked: selectedRow }));
  }, [selectedRow]);
  useEffect(() => {
    return () => {
      setSelectedRow([]);
    };
  }, [accountType]);

  const dataSource = [...data];
  const cols = userAccountType === "customer" ? columns : otherColumns;
  const mergedColumns = cols.map((col) => {
    if (col.dataIndex !== "operation") {
      if (!col.children) {
        return {
          ...col,
          onCell: (record) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            inputType: col.inputType || "text",
            validatorFunction: col.validatorFunction,
            isValidation: col.isValidation,
            brokerageType: brokarageDetails?.brokerageType,
            watchBrokerageType: watchBrokerageType,
            tab: tab,
          }),
        };
      } else {
        return {
          ...col,
          children: col.children.map((child) => ({
            ...child,
            onCell: (record) => ({
              record,
              editable: child.editable,
              dataIndex: child.dataIndex,
              title: child.title,
              editing: isEditing(record),
              inputType: child.inputType || "number",
              validatorFunction: child.validatorFunction,
              isValidation: child.isValidation,
              brokerageType: brokarageDetails?.brokerageType,
              watchBrokerageType: watchBrokerageType,
              tab: tab,
            }),
          })),
        };
      }
    } else {
      return { ...col };
    }
  });
  const handleSearch = (e) => {
    functionDebounce(e);
  };

  const functionDebounce = _.debounce((e) => {
    setSearchValue(e.target.value);
    appendData(page, e.target.value);
  }, 300);

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "end", marginBottom: "1rem" }}
      >
        <Input
          style={{ width: "300px" }}
          onChange={handleSearch}
          placeholder="Search anything here"
          prefix={<SearchOutlined />}
          // value={searchValue}
        />
      </div>
      <Form form={form} component={false}>
        <Table
          className="users-custom-table table-header"
          columns={mergedColumns}
          scroll={{ x: 1200 }}
          sortDirections={["ascend", "descend", "ascend"]}
          virtual={editingKey ? false : true}
          onChange={(pagination, filters, sorter, extra) => {
            appendData(
              page,
              searchValue,
              sorter.field,
              sorter.order === "ascend" ? "asc" : "desc"
            );
          }}
          dataSource={[{ ...extraRow }]}
          rowClassName="editable-row"
          pagination={false}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
        />

        <Table
          showHeader={false}
          scroll={{ x: 1200, scrollToFirstRowOnChange: false }}
          // sticky={{ offsetHeader: 64 }}
          className="users-custom-table table-body"
          virtual={editingKey ? false : true}
          loading={loading || allCheckArrayLoading}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={{
            total: 10 * totalData,
            current: page,
            onChange: (e) => {
              appendData(e, searchValue);
            },
            showSizeChanger: false,
          }}
          // summary={() => {
          //   return (
          //     <Table.Summary fixed={"top"}>
          //       <CustomerSummery />
          //     </Table.Summary>
          //   );
          // }}
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
        />
      </Form>
    </>
  );
};

//////////////////////////////////////////////////////////////////////////////
