import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import { Button, Col, Form, Modal, Row, Typography, notification } from "antd";
import { ModalText, StyledOverFlowDiv, onlyTime } from "./style";
import { formatToINR } from "../../components/FormateToINR/formatToINR";
import ThemeInput from "../../components/ThemeInput";
import { Link } from "react-router-dom";

const AutoSqureTable = React.memo(() => {
  //state
  const [showModal, setShowModal] = useState({ flag: false, data: null });
  const queryCilent = useQueryClient();

  // life cycle
  useEffect(() => {
    queryCilent.resetQueries(["autoSqure"]);
  }, []);

  // api call
  const getAutoSqureData = async ({ pageParam = 1 }) => {
    let response = await APICall(
      "post",
      `${EndPoints.getM2MAlert}&type=squareup&page=${pageParam}&limit=10&isLatest=true`
    );
    return response?.data?.data;
  };

  const autoSqureQuery = useInfiniteQuery({
    queryKey: ["autoSqure"],
    queryFn: getAutoSqureData,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  });

  // console.log("autoQuery", autoSqureQuery);
  const allData = autoSqureQuery?.data?.pages?.flatMap((el) => el?.alerts);

  // function
  const handleAction = (record) => {
    setShowModal({ flag: true, data: record });
  };

  // column
  const autoSquareColumns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "left",
      width: "10rem",
      render: (text, record) => {
        const time = onlyTime(record?.alertTime);
        return <div>{time}</div>;
      },
    },
    {
      title: "CLIENT",
      dataIndex: "client",
      key: "client",
      align: "left",
      width: "20rem",
      ellipsis: true,
      render: (text, record) => {
        return (
          <StyledOverFlowDiv
            title={`${record?.data?.userName}(${record?.clientId})`}
          >
            {`${record?.data?.userName}(${record?.clientId})`}
          </StyledOverFlowDiv>
        );
      },
    },
    {
      title: "M2M",
      dataIndex: "m2m",
      key: "m2m",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.amount)}</span>;
      },
    },
    {
      title: "LEDGER + M2M",
      dataIndex: "lot",
      key: "lot",
      align: "center",
      width: "15rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.balance)}</span>;
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      align: "center",
      width: "10rem",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => handleAction(record)}>
            Restore
          </Button>
        );
      },
    },
  ];

  return (
    <Col xl={12} lg={24}>
      <DashboardCommonTable
        columns={autoSquareColumns}
        tableTitle="AUTO SQUARE OFF LIST"
        dataSource={allData?.[0] ? allData : []}
        tradeQuery={autoSqureQuery}
        queryKey="autoSqure"
        isLoading={autoSqureQuery?.isLoading}
        viewMore={true}
        allowBtn={true}
      />
      {showModal?.flag && (
        <RestoreModal data={showModal?.data} setShowModal={setShowModal} />
      )}
    </Col>
  );
});

export default AutoSqureTable;

const RestoreModal = ({ data, setShowModal }) => {
  // state
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  // api
  const revertAutoSquare = async () => {
    try {
      let response = await APICall(
        "delete",
        `${EndPoints.restoreAutoSquareOff}${data?._id}`
      );
      if (response.status === 200) {
        setShowModal({ flag: false });
        console.log("response", response?.data);
        notification.success({ message: response?.data?.message });
        queryClient.resetQueries(["autoSqure"]);
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async (value) => {
    setLoading(true);
    let obj = { password: value };
    try {
      let response = await APICall("post", EndPoints.passwordVerification, obj);
      if (response?.status === 200) {
        revertAutoSquare();
      } else {
        notification.error({ message: response?.data?.message });
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  // function
  const handleCancel = () => {
    setShowModal({ flag: false });
  };
  const handleFinishFailed = (value) => {
    // console.log(value, "failed");
  };
  const handleFinish = (value) => {
    // console.log(value, "finish");
    handleSubmit(value?.passward);
  };
  return (
    <>
      <Modal
        open={true}
        footer={false}
        onCancel={handleCancel}
        title={<div>Restore Auto SquareOff</div>}
        width={400}
      >
        <Row>
          <ModalText>• Update M2M limit of customer</ModalText>
          <ModalText>• Disable only position squerUp</ModalText>
          <Typography.Text style={{ marginTop: "0.5rem" }}>
            To update account {`${data?.data?.userName}(${data?.clientId})`}
            <Link
              style={{ marginLeft: "0.5rem" }}
              target="_blank"
              to={`/users/edit-account/${data?.clientId}`}
            >
              Click here
            </Link>
          </Typography.Text>
        </Row>
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <Row align={"left"} style={{ marginTop: "1rem" }}>
            <Col span={24}>
              <ThemeInput
                name="passward"
                title="Passward"
                inputProps={{
                  placeholder: "Enter Login Password",
                }}
                marginBottom="0rem"
                formProps={{
                  name: "passward",
                  rules: [{ required: true, message: "Passward is required" }],
                }}
              />
            </Col>
          </Row>
          <Row align={"center"} gutter={[30]} style={{ marginTop: "1rem" }}>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                // onClick={handleSubmit}
              >
                Submit
              </Button>
            </Col>
            <Col>
              <Button onClick={handleCancel}>Cancel</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
