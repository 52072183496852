import { useDispatch, useSelector } from "react-redux";
import { App, Button, Form, Modal, Row, Space, Spin } from "antd";
import HeaderView from "./HeaderView";
import BasicInfoView from "./BasicInfoView";
import AccessDetailsView from "./AccessDetailsView";
import MarketAccessView from "./MarketAccessView";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import {
  resetUserAccountSlice,
  setNewAccountInfo,
  setParentValidation,
} from "../../../store/slice/userAccountSlice";
import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { setMarketAccess } from "../../../store/slice/authSlice";
import store from "../../../store/store";
import {
  resetMarketValidationErrors,
  saveDetails,
} from "../../../store/slice/globleSlice";
import { initializeObject } from "../../../components/SetUserDetails/SetUserDetails";
import {
  resetScript,
  updateScriptSlice,
} from "../../../store/slice/scriptSettingSlice";
import { Container } from "../../MarketSetting/MarketSetting";
import modifyInitialValuesObject from "./modifyInitialValuesObject";
import { payloadObject } from "./payloadObject";
import { removeBlockedAndEdited } from "../../../store/slice/scriptSettingsRequestSlice";
import { InitialAdditionalInfo } from "../../../Helper/constant";
import dayjs from "dayjs";
import { handleMarketValidation } from "../../../Helper/functions";
import CreateUserModal from "./components/CreateUserModal";
import {
  convertTimeToISO,
  removeFieldsWithXXYY,
} from "./components/UtilityFuction/removeFieldsWithXXYY";
import { markets as marketArray } from "../../../Helper/constant";
import utc from "dayjs/plugin/utc";
import { ButtonWrapper } from "./style";
dayjs.extend(utc);

const NewAccount = ({ is_edits }) => {
  //redux
  const userDetail = useSelector((state) => state.authSlice?.userDetail);
  const userIP = useSelector((state) => state.authSlice?.user?.userIP);
  const marketAccessDetails = useSelector(
    (state) => state.userAccountSlice?.market_access
  );

  const parentDetails = useSelector(
    (state) => state.userAccountSlice.parentValidation
  );

  const accountType = useSelector(
    (state) => state.userAccountSlice?.accountType
  );
  const childMarketAccess = useSelector(
    (state) => state?.userAccountSlice?.childMarketAccess
  );
  const isBroker = accountType === "broker";
  const isOwner = userDetail?.accountType === "owner";
  //state
  const userId = useParams().id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createButton, setCreateButton] = useState(false);
  const [parentLoadng, setParentLoading] = useState(
    (!isOwner || (userId && is_edits)) && !isBroker ? true : false
  );
  const [newUserDetails, setNewUserDetails] = useState({});
  const [parentId, setParentId] = useState(null);
  const [initialValues, setInitialValues] = useState({
    ...InitialAdditionalInfo,
    accountPassword: 1234,
  });
  const [loading, setLoading] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const updateRef = useRef({});
  const { notification } = App.useApp();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const loginId = userDetail?.userId;

  // life cycle
  useEffect(() => {
    dispatch(resetMarketValidationErrors());

    dispatch(resetScript());
    if (isModalOpen) {
      setIsModalOpen(false);
    }
    return () => {
      setParentLoading(false);
      dispatch(resetScript());
      form.resetFields();
      dispatch(removeBlockedAndEdited());
      setInitialValues();
    };
  }, []);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // This will run when the page is about to be unloaded (refreshed or closed)

      // Some browsers require returnValue to be set
      event.preventDefault();
      event.returnValue = "";
    };

    // Add the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // This will run when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (isBroker) {
      setParentLoading(false);
    }
    dispatch(removeBlockedAndEdited());

    if (!is_edits && !isOwner) {
      dispatch(resetScript());
      form.resetFields();
      form.setFieldsValue({
        ...parentDetails?.additional,
        ...parentDetails?.brokerageTypeofMarket,
        partnershipWith:
          parentDetails?.accountDetails?.partnershipWith === "rent"
            ? "percentage"
            : parentDetails?.accountDetails?.partnershipWith,
        minimumBrokerageRequire:
          parentDetails?.accountDetails?.minimumBrokerageRequire,
        accountPassword: 1234,
      });
    }
  }, [accountType]);

  useEffect(() => {
    if (userId && is_edits) {
      getuserDetails();
    } else {
      if (userDetail?.accountType !== "owner") {
        getParentDetails(loginId);
      }
    }
  }, [is_edits]);

  useEffect(() => {
    if (parentId) {
      getParentDetails(parentId);
    }
  }, [parentId]);

  // check manually validation by validationName
  const validateforScript = (script) => {
    return new Promise((resolve, reject) => {
      form
        .validateFields(script)
        .then(() => {
          // Resolve the Promise if validation is successful
          resolve(true);
        })
        .catch((errorInfo) => {
          console.log("Validation failed:", errorInfo);
          // Reject the Promise if validation fails
          reject({ isValid: false, errorInfo });
        });
    });
  };
  // check all validation
  const validateFields = () => {
    // Call validateFields on the form instance
    form
      .validateFields()
      .then((values) => {
        // dispatch(setInvalidMarket([]));
      })
      .catch((error) => {
        const errorMarkets = error?.errorFields?.reduce((acc, curr) => {
          let ismarket = curr?.name[0]?.split("$")[1];
          if (ismarket) {
            const isPresent = acc.includes(ismarket);
            if (!isPresent) {
              acc.push(ismarket);
            }
          }
          return acc;
        }, []);
        // dispatch(setInvalidMarket(errorMarkets));
        console.log("Validation failed:", error);
      });
  };

  const saveAfterFinish = async (create, data, ignore, obj) => {
    const state = data ? data : store.getState().userAccountSlice;

    let authorityLevel = state?.authorityLevel;
    setLoading(true);
    if (userId) {
      try {
        let response = await APICall(
          "post",
          `${EndPoints.editUsers}${userId}`,
          obj
        );

        if (response.status === 200) {
          let user = state?.basicInfo;
          notification.success({
            message: `User ${user?.accountId} (${user?.accountName}) updated successfully`,
          });
          queryClient.resetQueries(["usersDetails"]);
          setNewUserDetails(store.getState().userAccountSlice.basicInfo);
          setIsModalOpen(true);
          navigate("/users/accounts");
        } else {
          notification.error({
            message: response?.data?.message,
            duration: 10,
          });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
        dispatch(removeBlockedAndEdited());
        setCreateButton(false);
      }
    } else {
      try {
        let response = await APICall("post", EndPoints.register, obj);

        if (response.status === 200 && response.data) {
          notification?.success({
            message: response?.data?.message,
          });
          setNewUserDetails({
            ...response?.data?.data,
            passward:
              store.getState().userAccountSlice.basicInfo?.accountPassword,
          });
          setIsModalOpen(true);
          dispatch(resetUserAccountSlice());
          let obj = {
            accountType: accountType,
            authorityLevel: authorityLevel,
          };
          dispatch(setNewAccountInfo(obj));
          queryClient.refetchQueries(["usersDetails"]);
          queryClient.resetQueries(["getUserByFilter"]);

          accountType !== "masterAdmin" && getParentDetails(loginId);

          setCreateButton(false);
        } else {
          setCreateButton(false);
          notification?.error({
            message: response?.data?.message,
            duration: 10,
          });
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
  };
  const getuserDetails = async () => {
    if (!parentLoadng) {
      setParentLoading(true);
    }
    try {
      const response = await APICall("get", `${EndPoints.getUser}${userId}`);

      if (response?.status === 200 && response?.data) {
        let userInfo = response?.data?.data;
        if (userInfo?.user?.accountType === "broker") {
          let brokerData = userInfo?.user;
          let borkerObj = {
            accountType: brokerData?.accountType,
            accountName: brokerData?.accountName,
            accountId: brokerData?.userId,
          };
          let sliceValue = {
            accountType: brokerData?.accountType,
            basicInfo: {
              accountName: brokerData?.accountName,
              accountId: brokerData?.userId,
            },
          };
          setInitialValues(borkerObj);
          dispatch(setNewAccountInfo(sliceValue));
          setParentLoading(false);
        } else {
          setParentId(userInfo?.parent);

          let additionalSharingObj = {
            "NSE FUT": [],
            "NSE OPT": [],
            "NSE EQU": [],
            MCX: [],
            "MCX OPT": [],
            FOREX: [],
            COMEX: [],
            GLOBEX: [],
          };
          let constantValue = {
            "NSE FUT": {},
            "NSE OPT": {},
            "NSE EQU": {},
            MCX: {},
            "MCX OPT": {},
            FOREX: {},
            COMEX: {},
            GLOBEX: {},
          };

          if (userInfo.additionalSharing) {
            for (let i = 0; i < userInfo?.additionalSharing.length; i++) {
              additionalSharingObj[
                userInfo.additionalSharing[i]?.marketAccessId?.scriptName
              ].push({
                ...userInfo.additionalSharing[i],
                brokerId: Number(
                  userInfo.additionalSharing[i].brokerId?.userId
                ),
              });
            }

            for (let key in additionalSharingObj) {
              additionalSharingObj[key].sort((a, b) => a.brokerId - b.brokerId);
            }
          }

          let brokerageObj = {};
          userInfo.sharingBrokerageSchema?.forEach((el) => {
            if (el?.marketAccessId?.scriptName) {
              brokerageObj[el.marketAccessId.scriptName] = el;
            }
          });

          // const brokerageObj1 = userInfo.sharingBrokerageSchema?.reduce(
          //   (acc, curr) => {
          //     if (curr?.marketAccessId) {
          //       acc[curr.marketAccessId.scriptName] = curr;
          //       return acc;
          //     }
          //   },
          //   {}
          // );

          let onlyPositionObj = {};
          userInfo.marketAccess?.forEach((el) => {
            if (!onlyPositionObj[el?.scriptName]) {
              onlyPositionObj[el?.scriptName] = {};
            }
            onlyPositionObj[el?.scriptName] = {
              isOnlyPositionSquareup: el?.isOnlyPositionSquareup,
            };
          });

          let scriptWiseObj = {};
          userInfo.scriptWiseSettingInfo?.forEach((el) => {
            if (el?.marketAccessId?.scriptName) {
              scriptWiseObj[el.marketAccessId.scriptName] = el;
            }
          });

          // const scriptWiseObj = userInfo.scriptWiseSettingInfo?.reduce(
          //   (acc, curr) => {
          //     acc[curr.marketAccessId.scriptName] = curr;
          //     return acc;
          //   },
          //   {}
          // );

          let marginObj = {};
          userInfo.margin?.forEach((el) => {
            if (el?.marketAccessId?.scriptName) {
              marginObj[el.marketAccessId.scriptName] = el;
            }
          });

          // const marginObj = userInfo.margin?.reduce((acc, curr) => {
          //   acc[curr.marketAccessId.scriptName] = curr;
          //   return acc;
          // }, {});

          let marketAccess = userInfo?.marketAccess?.map(
            (item) => item?.scriptName
          );

          if (is_edits) {
            for (let key in constantValue) {
              constantValue[key].delivery = marginObj?.[key]?.delivery || 0;
              constantValue[key].intraday = marginObj?.[key]?.intraday || 0;
              constantValue[key].historyDelivery =
                marginObj?.[key]?.history?.[0]?.delivery || 0;
              constantValue[key].historyIntraday =
                marginObj?.[key]?.history?.[0]?.intraday || 0;
              constantValue[key].historyTotal =
                marginObj?.[key]?.history?.[0]?.total || 0;
            }
            constantValue.balance = userInfo?.user?.balance;
            constantValue.allowNoOfAccountSuperAdmin =
              userInfo.accountDetails?.allowNoOfAccountSuperAdmin || 0;
            constantValue.allowNoOfAccountAdmin =
              userInfo.accountDetails?.allowNoOfAccountAdmin || 0;
            constantValue.allowNoOfAccountMaster =
              userInfo.accountDetails?.allowNoOfAccountMaster || 0;
            constantValue.allowNoOfAccountBroker =
              userInfo.accountDetails?.allowNoOfAccountBroker || 0;
            constantValue.allowNoOfAccountDealer =
              userInfo.accountDetails?.allowNoOfAccountDealer || 0;
            constantValue.allowNoOfAccountCustomer =
              userInfo.accountDetails?.allowNoOfAccountCustomer || 0;
            constantValue.balance = userInfo?.user?.balance || 0;
            constantValue.marketAccess = marketAccess || [];
          }
          let newPositionTime = dayjs(
            userInfo?.accountDetails?.newPositionSqaureupTime
          );
          let stringTime = userInfo?.accountDetails?.stringTime;
          if (stringTime) {
            let splitTime = Number(stringTime?.split(":")?.[0]);
            if (splitTime < 60) {
              let resultTime = convertTimeToISO(stringTime);
              newPositionTime = resultTime.dayjs;
            }
          }

          let obj = {
            accountType: userInfo?.user?.accountType,
            // authorityLevel: userInfo?.user?.authorityLevel,
            // parentId: 1234,
            is_sample_account: false,
            is_edit: true,
            edit_userData: [],
            basicInfo: {
              accountName: userInfo?.user?.accountName,
              accountId: userInfo?.user?.userId,
              balance: userInfo?.user?.balance,
              dopositeHistory: userInfo?.user?.history?.[0]?.balance,
              linkLedger: userInfo?.user?.linkLedger,
              // importUserSettings: "",
            },
            childMarketAccess: userInfo?.childMarketAccess,
            accountDetails: {
              allowNoOfAccountSuperAdmin:
                userInfo.accountDetails?.allowNoOfAccountSuperAdmin || 0,
              allowNoOfAccountAdmin:
                userInfo.accountDetails?.allowNoOfAccountAdmin || 0,
              allowNoOfAccountMaster:
                userInfo.accountDetails?.allowNoOfAccountMaster || 0,
              allowNoOfAccountBroker:
                userInfo.accountDetails?.allowNoOfAccountBroker || 0,
              allowNoOfAccountDealer:
                userInfo.accountDetails?.allowNoOfAccountDealer || 0,
              allowNoOfAccountCustomer:
                userInfo.accountDetails?.allowNoOfAccountCustomer || 0,
              partnershipWith: userInfo.accountDetails?.partnershipWith,
              minimumBrokerageRequire:
                userInfo.accountDetails?.minimumBrokerageRequire,
              additionalInfo: userInfo?.additionalInfoSchema?.[0] || {},
              orderBetweenHighLow: userInfo.accountDetails?.orderBetweenHighLow,
              showDetailBill: userInfo.accountDetails?.showDetailBill,
              allowBannedScript: userInfo.accountDetails?.allowBannedScript,
              isOnlyPositionSquareup:
                userInfo.accountDetails?.isOnlyPositionSquareup,
              isAutoStopNewPosition:
                userInfo.accountDetails?.isAutoStopNewPosition,
              autoStopNewPosition: {
                profit: userInfo.accountDetails?.autoStopNewPositionProfit,
                loss: userInfo.accountDetails?.autoStopNewPositionLoss,
              },
              isApplyAutoSquareup: userInfo.accountDetails?.applyAutoSquareup,
              applyAutoSquareup: {
                profit: userInfo.accountDetails?.applyAutoSquareupProfit,
                loss: userInfo.accountDetails?.applyAutoSquareupLoss,
              },
              isM2MAlert: userInfo.accountDetails?.isM2MAlert,
              M2MAlert: {
                profit: userInfo.accountDetails?.m2mAlertProfit,
                loss: userInfo.accountDetails?.m2mAlertLoss,
              },
              isNewPositionSquareupTime:
                userInfo.accountDetails?.isNewPositionSqaureupTime,
              newPositionSquareupTime: userInfo.accountDetails
                ?.newPositionSqaureupTime
                ? newPositionTime || null
                : null,
              // userInfo.accountDetails?.newPositionSquareupTime
              isFreshLimitAllow: userInfo.accountDetails?.isFreshLimitAllow,
              freshLimitAllow: userInfo.accountDetails?.freshLimitAllow,
              remark: userInfo.accountDetails?.remark,
              historicalData: userInfo?.accountDetails?.totalAccounts?.[0],
            },
            market_access: marketAccess,
          };

          let scriptObj = {};
          const marketArray = store
            .getState()
            ?.globleSlice?.marketListData?.map((el) => el?.scriptName);

          if (userInfo?.user?.accountType !== "broker") {
            for (let i = 0; i < marketArray.length; i++) {
              let name =
                marketArray[i] === "NSE EQU"
                  ? "totalNoOfQuantity"
                  : "totalNoOfLot";
              let isOnlyPositionSquareup =
                onlyPositionObj?.[marketArray[i]]?.isOnlyPositionSquareup ||
                false;
              let brokerageDetails = {
                deliveryTotal:
                  brokerageObj[marketArray[i]]?.deliveryTotal ?? null,
                deliveryBuy: brokerageObj[marketArray[i]]?.deliveryBuy ?? null,
                intradayBuy: brokerageObj[marketArray[i]]?.intradayBuy ?? null,
                intradayTotal:
                  brokerageObj[marketArray[i]]?.intradayTotal ?? null,
                deliverySell:
                  brokerageObj[marketArray[i]]?.deliverySell ?? null,
                intradaySell:
                  brokerageObj[marketArray[i]]?.intradaySell ?? null,
                percentage: brokerageObj[marketArray[i]]?.percentage ?? null,
                brokerageType: brokerageObj[marketArray[i]]?.brokerageType,
              };
              let additionalSharing =
                additionalSharingObj?.[marketArray[i]] || [];
              let margin = {
                type: marginObj[marketArray[i]]?.type || "value",
                delivery: marginObj[marketArray[i]]?.delivery ?? null,
                intraday: marginObj[marketArray[i]]?.intraday ?? null,
                total: marginObj[marketArray[i]]?.total ?? null,
                interestRate: marginObj[marketArray[i]]?.interestRate ?? null,
              };
              let scriptWiseSettingInfo = {
                minimumScriptRate:
                  scriptWiseObj[marketArray[i]]?.minimumScriptRate ?? null,
                totalNoOfScriptAllowed:
                  scriptWiseObj[marketArray[i]]?.totalNoOfScripts ?? null,
                orderLimit: scriptWiseObj[marketArray[i]]?.orderLimits ?? null,
                strikeRateMaxLot:
                  scriptWiseObj[marketArray[i]]?.strikeRateMaxLot ?? null,
                allowShortSell:
                  scriptWiseObj[marketArray[i]]?.allowShortSell ?? false,
                allowLimitOrder:
                  scriptWiseObj[marketArray[i]]?.allowLimitOrder ?? false,
                allowQuantityTrading:
                  scriptWiseObj[marketArray[i]]?.allowQuantityTrading ?? false,
                [name]: scriptWiseObj[marketArray[i]]?.[name] ?? null,
              };
              let allScript =
                userInfo.scriptWiseSetting?.allScript[marketArray[i]] || [];

              let blockScript =
                userInfo.scriptWiseSetting?.blockScript[marketArray[i]] || [];

              const formatedScript = allScript?.reduce((acc, curr) => {
                if (curr?.scriptName) {
                  acc[curr?.scriptName] = curr;
                }
                return acc;
              }, {});
              const blockDetails = blockScript?.reduce((acc, curr) => {
                acc[curr] = { isBlocked: true };
                return acc;
              }, {});
              let finalObj = { ...formatedScript, ...blockDetails };
              scriptObj[marketArray[i]] = finalObj;
              obj[marketArray[i]] = {
                brokerageDetails,
                additionalSharing,
                margin,
                scriptWiseSettingInfo,
                allScript,
                blockScript,
                isOnlyPositionSquareup,
              };
            }
            let modedVals = modifyInitialValuesObject(obj);
            setInitialValues({
              ...modedVals,
            });

            obj.accountDetails.newPositionSquareupTime = null;
            dispatch(setNewAccountInfo(obj));

            dispatch(updateScriptSlice(scriptObj));
            if (userId) {
              dispatch(saveDetails(constantValue));
            }
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // function
  const onFinish = (values) => {
    // showModal();
    // saprateObjForPayload();
  };
  const getParentDetails = async (id) => {
    setParentLoading(true);

    try {
      let response = await APICall("get", `${EndPoints.getUser}${id}`);
      setParentLoading(false);
      let userInfo = response?.data?.data;
      const childMarketAccess = userInfo?.childMarketAccess;
      let scriptWiseTable = initializeObject();
      let accountDetails = userInfo?.accountDetails;
      const parentMarketList = userInfo?.marketAccess?.map((el) => {
        return el?.scriptName;
      });
      const basicInfo = userInfo?.user;

      let brokarageDetails = {};
      userInfo.sharingBrokerageSchema?.forEach((el) => {
        if (el?.marketAccessId?.scriptName) {
          brokarageDetails[el.marketAccessId.scriptName] = el;
        }
      });

      // const brokarageDetails = userInfo.sharingBrokerageSchema?.reduce(
      //   (acc, curr) => {
      //     acc[curr.marketAccessId.scriptName] = curr;
      //     return acc;
      //   },
      //   {}
      // );

      // const scriptWiseDetails = userInfo.scriptWiseSettingInfo?.reduce(
      //   (acc, curr) => {
      //     acc[curr.marketAccessId.scriptName] = curr;
      //     return acc;
      //   },
      //   {}
      // );
      let scriptWiseDetails = {};
      userInfo.scriptWiseSettingInfo?.forEach((el) => {
        if (el?.marketAccessId?.scriptName) {
          scriptWiseDetails[el.marketAccessId.scriptName] = el;
        }
      });
      // const marginDetails = userInfo.margin?.reduce((acc, curr) => {
      //   acc[curr.marketAccessId.scriptName] = curr;
      //   return acc;
      // }, {});

      let marginDetails = {};
      userInfo.margin?.forEach((el) => {
        if (el?.marketAccessId?.scriptName) {
          marginDetails[el.marketAccessId.scriptName] = el;
        }
      });

      for (let l = 0; l < marketArray.length; l++) {
        if (userInfo?.scriptWiseSetting?.allScript?.[marketArray[l]]) {
          let obj = {};
          let scriptArray =
            userInfo?.scriptWiseSetting?.allScript?.[marketArray[l]];
          for (let j = 0; j < scriptArray.length; j++) {
            if (scriptArray[j]?.scriptName) {
              obj[scriptArray[j].scriptName] = scriptArray[j];
            }
          }
          scriptWiseTable[marketArray[l]] = obj;
        }
      }

      let additionalInfo = userInfo?.additionalInfoSchema?.[0];
      let scriptInfo = userInfo?.scriptWiseSetting?.allScript;
      let blockScript = userInfo?.scriptWiseSetting?.blockScript;
      const markets = store
        .getState()
        ?.globleSlice?.marketListData?.map((el) => el?.scriptName);
      let scriptObj = {};
      let obj = {
        accountDetails: {
          allowNoOfAccountMasterAdmin: 0,
          allowNoOfAccountSuperAdmin: 0,
          allowNoOfAccountAdmin: 0,
          allowNoOfAccountMaster: 0,
          allowNoOfAccountBroker: 0,
          allowNoOfAccountDealer: "",
          allowNoOfAccountCustomer: "",
          isOnlyPositionSquareup: false,
          partnershipWith:
            accountDetails?.partnershipWith === "rent"
              ? "percentage"
              : accountDetails?.partnershipWith,
          minimumBrokerageRequire: accountDetails?.minimumBrokerageRequire,
          additionalInfo: { ...additionalInfo },
          orderBetweenHighLow: false,
          showDetailBill: false,
          allowBannedScript: false,
          showDepositeToCustomer: false,
          isAutoStopNewPosition: false,
          autoStopNewPosition: {
            profit: null,
            loss: null,
          },
          isApplyAutoSquareup: false,
          applyAutoSquareup: {
            profit: null,
            loss: null,
          },
          isM2MAlert: false,
          M2MAlert: {
            profit: null,
            loss: null,
          },
          isNewPositionSquareupTime: false,
          newPositionSquareupTime: { time: null },
          isFreshLimitAllow: true,
          freshLimitAllow: "",
          remark: "",
        },
      };

      for (let i = 0; i < markets.length; i++) {
        let name =
          markets[i] === "NSE EQU" ? "totalNoOfQuantity" : "totalNoOfLot";
        let isOnlyPositionSquareup = false;
        let brokerageDetails = {
          deliveryTotal: 0,
          deliveryBuy: 0,
          intradayBuy: 0,
          intradayTotal: 0,
          deliverySell: 0,
          intradaySell: 0,
          percentage: 0,
          brokerageType: brokarageDetails?.[markets[i]]?.brokerageType,
        };
        let additionalSharing = [];
        let margin = {
          type: "value",
          delivery: null,
          intraday: 0,
          total: 0,
          interestRate: 0,
        };

        let scriptWiseSettingInfo = {
          minimumScriptRate:
            scriptWiseDetails?.[markets[i]]?.minimumScriptRate || null,
          totalNoOfScriptAllowed:
            scriptWiseDetails?.[markets[i]]?.totalNoOfScripts || null,
          orderLimit: scriptWiseDetails?.[markets[i]]?.orderLimits || null,
          strikeRateMaxLot:
            scriptWiseDetails?.[markets[i]]?.strikeRateMaxLot || null,
          allowShortSell:
            scriptWiseDetails?.[markets[i]]?.allowShortSell || false,
          allowLimitOrder:
            scriptWiseDetails?.[markets[i]]?.allowLimitOrder || false,
          allowQuantityTrading:
            scriptWiseDetails?.[markets[i]]?.allowQuantityTrading || false,
          [name]: scriptWiseDetails?.[markets[i]]?.[name] || null,
        };
        let allScript =
          userInfo.scriptWiseSetting?.allScript[marketArray[i]] || [];

        let blockScript =
          userInfo.scriptWiseSetting?.blockScript[marketArray[i]] || [];

        const formatedScript = allScript?.reduce((acc, curr) => {
          if (curr?.scriptName) {
            acc[curr?.scriptName] = { ...curr, isParent: true, isSaved: true };
          }
          return acc;
        }, {});
        const blockDetails = blockScript?.reduce((acc, curr) => {
          acc[curr] = { isBlocked: true, isParent: true };
          return acc;
        }, {});
        let finalObj = { ...formatedScript, ...blockDetails };
        scriptObj[marketArray[i]] = finalObj;
        obj[marketArray[i]] = {
          isOnlyPositionSquareup,
          brokerageDetails,
          additionalSharing,
          margin,
          scriptWiseSettingInfo,
          allScript,
          blockScript,
        };
      }
      let brokerageTypeofMarket = {};
      markets?.forEach((el) => {
        brokerageTypeofMarket[el] = {
          brokerageType: obj?.[el]?.brokerageDetails.brokerageType,
        };
      });

      dispatch(
        setMarketAccess({
          brokarageDetails,
          scriptWiseDetails,
          scriptWiseTable,
          marginDetails,
          accountDetails,
          basicInfo,
          parentMarketList,
          additional: additionalInfo,
          blockScript,
          tabelScriptInfo: scriptInfo,
          childMarketAccess,
          brokerageTypeofMarket,
        })
      );
      dispatch(
        setParentValidation({
          brokarageDetails,
          scriptWiseDetails,
          scriptWiseTable,
          marginDetails,
          accountDetails,
          basicInfo,
          parentMarketList,
          additional: additionalInfo,
          blockScript,
          tabelScriptInfo: scriptInfo,
          childMarketAccess,
          brokerageTypeofMarket,
        })
      );
      if (!is_edits) {
        dispatch(setNewAccountInfo(obj));
        dispatch(updateScriptSlice(scriptObj));

        form.setFieldsValue({
          ...form.getFieldsValue(),
          ...obj.accountDetails,
          ...obj.accountDetails.additionalInfo,
          ...brokerageTypeofMarket,
          newPositionSquareupTime: obj.newPositionSquareupTime?.time || null,
          partnershipWith: obj.accountDetails?.partnershipWith,
        });
        setInitialValues({
          ...form.getFieldsValue(),
          ...obj.accountDetails,
          ...obj.accountDetails.additionalInfo,
          ...brokerageTypeofMarket,
          newPositionSquareupTime: obj.newPositionSquareupTime?.time || null,
          accountPassword: 1234,
        });
      }

      // setParentLoading(false);
    } catch (error) {
      setParentLoading(false);
      console.log("error while getting data of loginUser", error);
    }
  };

  const onFinishFailed = (error) => {
    console.log("Failed:", error);
    setCreateButton(false);

    const errorMarkets = error?.errorFields?.reduce((acc, curr) => {
      let ismarket = curr?.name[0]?.split("$")[1];
      if (ismarket) {
        const isPresent = acc.includes(ismarket);
        if (!isPresent) {
          acc.push(ismarket);
        }
      }
      return acc;
    }, []);
    // dispatch(setInvalidMarket(errorMarkets));

    notification.error({
      message: error?.errorFields[0]?.errors[0]
        ? error?.errorFields[0]?.errors[0]
        : error?.errorFields[0]?.name[0],
      duration: 10,
    });
  };

  const handleConfirm = () => {
    saprateObjForPayload(form.getFieldsValue());
  };

  const handleOk = () => {
    handleConfirm();
  };

  const handleCancel = () => {
    setCreateButton(false);
  };

  const showModal = async () => {
    setValidationLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 50));
    await form
      .validateFields()
      .then((values) => {
        setCreateButton(true);
      })
      .catch(async (error) => {
        setCreateButton(false);
        //////////////
        const container = document.getElementsByTagName("body")[0];
        if (container) {
          const errorElements = Array.from(
            container.querySelectorAll(".ant-form-item-has-error")
          );
          if (errorElements.length > 0) {
            const firstErrorElement = errorElements[0];
            firstErrorElement.scrollIntoView();
            setTimeout(() => {
              window.scrollBy(0, -160);
            }, 300);
          }
        }
        //////////////
        handleMarketValidation(form, dispatch);
        notification.error({ message: error?.errorFields?.[0]?.errors?.[0] });
      })
      .finally(() => {
        setValidationLoading(false);
      });
  };
  ////////////////////////

  const saprateObjForPayload = (value) => {
    let marketList = [];
    const touchedFields = form.isFieldsTouched();
    const touchedValues = {};
    if (touchedFields) {
      // Get all field values
      const allValues = form.getFieldsValue();
      // Filter to get only touched fields
      Object.keys(allValues).forEach((field) => {
        if (form.isFieldTouched(field)) {
          touchedValues[field] = allValues[field];
        }
      });
    }
    let valueToModify = is_edits ? touchedValues : value;
    let modifiyvalue = Object.keys(valueToModify).reduce((acc, curr) => {
      let isStructure = curr?.split("$");
      let objValue = isStructure[0];
      let objKey = isStructure[1];
      let market =
        marketArray?.includes?.(objKey) && !marketList?.includes(objKey);
      if (market) {
        marketList.push(objKey);
      }
      if (isStructure && objKey && marketArray?.includes?.(objKey)) {
        acc[objKey] = { ...acc[objKey], [objValue]: value[curr] };
      } else if (isStructure && objKey) {
        acc[objValue] = { ...acc[objValue], [objKey]: value[curr] };
      } else {
        acc[curr] = value[curr];
      }
      return acc;
    }, {});

    const blockedScriptList = store.getState()?.scriptSettingsRequestSlice;
    let blockedArray = [];

    for (let key in blockedScriptList) {
      let initalBlockMarkets =
        store.getState()?.userAccountSlice?.[key]?.blockScript;

      if (
        blockedScriptList[key]?.blocked.length > 0 ||
        blockedScriptList[key]?.edited.length > 0 ||
        initalBlockMarkets?.length > 0
      ) {
        let ispush = marketList?.includes(key);
        if (!ispush) {
          blockedArray.push(key);
        }
      }
    }
    let payload = payloadObject(
      modifiyvalue,
      [...marketList, ...blockedArray],
      touchedValues,
      is_edits ? true : false
    );
    // console.log({ valueToModify, payload });
    if (marketAccessDetails?.length === 0 && accountType !== "broker") {
      return notification?.error({
        message: "Please select atleast one market",
      });
    }
    if (is_edits) {
      let filteredPayLoad = removeFieldsWithXXYY(payload);
      const seletedMarkets = filteredPayLoad?.marketAccess?.filter((el) =>
        marketAccessDetails?.includes(el?.scriptName)
      );

      let newPlayload = { ...filteredPayLoad, marketAccess: seletedMarkets };
      if (accountType === "broker") {
        delete newPlayload.marketAccess;
      }
      saveAfterFinish(true, null, false, newPlayload);
    } else {
      const seletedMarkets = payload?.marketAccess?.filter((el) =>
        marketAccessDetails?.includes(el?.scriptName)
      );
      let newPlayload = { ...payload, marketAccess: seletedMarkets };
      if (accountType === "broker") {
        let payloadMarket = parentDetails?.parentMarketList?.map((el) => {
          return { scriptName: el };
        });
        newPlayload.marketAccess = payloadMarket;
      }

      saveAfterFinish(true, null, false, newPlayload);
    }
  };

  return (
    <>
      <Container>
        <Spin spinning={parentLoadng}>
          <Space
            direction="vertical"
            size={"large"}
            style={{ marginTop: "2rem", width: "100%" }}
          >
            <HeaderView is_edits={is_edits} />

            {!parentLoadng && initialValues && accountType ? (
              <Form
                name="dynamic_form"
                form={form}
                initialValues={initialValues}
                onChange={(e) => handleMarketValidation(form, dispatch)}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{
                  height: "fit-content",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <BasicInfoView
                  is_edits={is_edits}
                  validateFields={validateFields}
                  parentForm={form}
                />

                {accountType === "broker" ? null : (
                  <>
                    <AccessDetailsView
                      is_edits={is_edits}
                      updateRef={updateRef}
                      parentForm={form}
                    />
                    <MarketAccessView is_edits={is_edits} parentForm={form} />
                  </>
                )}
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    width: "100%",
                  }}
                >
                  <ButtonWrapper>
                    <Form.Item>
                      <Button
                        type="primary"
                        loading={validationLoading}
                        onClick={() => {
                          showModal();
                        }}
                      >
                        {validationLoading
                          ? "Validating..."
                          : is_edits
                          ? "Update Account"
                          : "Create Account"}
                      </Button>
                    </Form.Item>
                  </ButtonWrapper>
                  <Modal
                    title={is_edits ? "Update Account" : "Create Account"}
                    open={createButton}
                    // onOk={handleOk}
                    confirmLoading={loading}
                    // onCancel={handleCancel}
                    footer={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "1rem",
                        }}
                      >
                        <Button
                          key="cancel"
                          onClick={handleCancel}
                          style={{ minWidth: "100px" }}
                        >
                          Cancel
                        </Button>

                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOk}
                          style={{
                            minWidth: "100px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "1rem",
                          }}
                        >
                          Ok
                        </Button>
                      </div>
                    }
                  >
                    {isBroker
                      ? `Are you sure to ${
                          is_edits ? "upadate" : "create"
                        }  account`
                      : `Are you sure to ${
                          is_edits ? "update" : "create"
                        } a account with ${
                          marketAccessDetails?.length
                        } markets, List of that market name are`}

                    {!isBroker && (
                      <span style={{ color: "green", marginLeft: "0.5rem" }}>
                        {marketAccessDetails?.join(", ")}
                      </span>
                    )}
                  </Modal>
                </Row>
              </Form>
            ) : null}

            {!is_edits && isModalOpen && (
              <CreateUserModal
                newUserDetails={newUserDetails}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                type="default"
              />
            )}
          </Space>
        </Spin>
      </Container>
    </>
  );
};

export default NewAccount;
