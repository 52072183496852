import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import DashboardCommonTable from "../../components/DashboardCommonTable";
import { Col } from "antd";
import { StyledOverFlowDiv, onlyTime } from "./style";
import { formatToINR } from "../../components/FormateToINR/formatToINR";

const M2MAlertTable = React.memo(() => {
  const queryClient = useQueryClient();

  // life cycle
  useEffect(() => {
    queryClient.resetQueries(["m2mAlerts"]);
  }, []);

  // api call
  const getM2mAlert = async ({ pageParam = 1 }) => {
    if (!pageParam) {
      return;
    }

    let response = await APICall(
      "post",
      `${EndPoints.getM2MAlert}&page=${pageParam}&limit=10&isLatest=true`
    );
    // console.log("M2m response", response?.data);
    return response?.data?.data;
  };

  const alertQuery = useInfiniteQuery({
    queryKey: ["m2mAlerts"],
    queryFn: getM2mAlert,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    // enabled: canCall,
  });

  const allData = alertQuery?.data?.pages?.flatMap((el) => el?.alerts) || [];

  // column
  const m2mAlertColumns = [
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: "5rem",
      align: "left",
      render: (text, record) => {
        const time = onlyTime(record?.alertTime);
        return <span>{time}</span>;
      },
    },
    {
      title: "CLIENT",
      dataIndex: "d",
      key: "d",
      align: "left",
      width: "5rem",
      ellipsis: true,
      render: (text, record) => {
        return (
          <StyledOverFlowDiv
            title={`${record?.data?.userName}(${record?.clientId})`}
          >
            {`${record?.data?.userName}(${record?.clientId})`}
          </StyledOverFlowDiv>
        );
      },
    },
    {
      title: "MESSAGE",
      dataIndex: "message",
      key: "message",
      align: "left",
      width: "25rem",
      render: (text, record) => {
        return <div>{record?.data?.message}</div>;
      },
    },
    {
      title: "M2M",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "5rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.amount)}</span>;
      },
    },
    {
      title: "LEDGER + M2M",
      dataIndex: "lot",
      key: "lot",
      align: "center",
      width: "5rem",
      render: (text, record) => {
        return <span>{formatToINR(record?.data?.balance)}</span>;
      },
    },
  ];
  // console.log("alldata", allData);
  return (
    <Col xl={12} lg={24}>
      <DashboardCommonTable
        columns={m2mAlertColumns}
        dataSource={allData?.[0] ? allData : []}
        tableTitle="M2M ALERTS"
        queryKey="m2mAlerts"
        isLoading={alertQuery?.isLoading}
        tradeQuery={alertQuery}
        viewMore={true}
        allowBtn={false}
      />
    </Col>
  );
});
export default M2MAlertTable;
