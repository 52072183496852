import { Document, Page, Text, View } from "@react-pdf/renderer";
import {
  formatForValanBill,
  formatToINR,
} from "../../components/FormateToINR/formatToINR";
import { columnEQU, stylesValanPDF as styles } from "./style";
import {
  formatDate,
  valanBillDate,
} from "../../components/CommanFuction/CommanFunction";

const PDFDocument = ({
  valanBill,
  grossRef,
  withoutBrokerage,
  column,
  isCustomer,
}) => (
  <Document>
    <Page size="A2" style={styles.page}>
      <View>
        <View>
          <View style={styles.row}>
            <View style={styles.column}>
              <Text>
                Live Bill:{" "}
                {grossRef?.user?.accountName
                  ? `${grossRef?.user?.accountName}(${grossRef?.user?.userId})`
                  : ""}
              </Text>
            </View>
            <View style={styles.column}>
              <Text>
                Valan:{" "}
                {grossRef?.valan?.valanName ? grossRef?.valan?.valanName : ""}
              </Text>
            </View>
            <View style={styles.column}>
              <Text>
                From:{" "}
                {grossRef?.valan?.startDate
                  ? formatDate(grossRef?.valan?.startDate)
                  : ""}{" "}
                To{" "}
                {grossRef?.valan?.endDate
                  ? formatDate(grossRef?.valan?.endDate)
                  : ""}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.themeBuyColumn}>
              <Text>
                Buy Turnover: {formatToINR(grossRef?.totalBuyTurnover) || 0}
              </Text>
            </View>
            <View style={styles.themeSellColumn}>
              <Text
                style={{
                  textAlign: "right",
                  width: "100%",
                }}
              >
                Sell Turnover: {formatToINR(grossRef?.totalSellTurnover) || 0}
              </Text>
            </View>
          </View>

          <View
            style={{
              ...styles.row,
              justifyContent: isCustomer ? "center" : "space-between",
            }}
          >
            {!isCustomer && (
              <View style={styles.column}>
                <Text style={{ fontSize: "15px" }}>
                  Without Brokerage Bill:
                  {formatToINR(grossRef?.totalGroce) || 0}
                </Text>
              </View>
            )}
            {!withoutBrokerage && !isCustomer && (
              <View style={styles.column}>
                <Text style={{ fontSize: "15px" }}>
                  Total Brokerage: {formatToINR(grossRef?.totalBrokerage) || 0}
                </Text>
              </View>
            )}
            {!withoutBrokerage &&
              grossRef?.user?.accountType !== "customer" && (
                <View style={styles.column}>
                  <Text style={{ fontSize: "15px" }}>
                    Self Brokerage: {formatToINR(grossRef?.selfBrokerage) || 0}
                  </Text>
                </View>
              )}
            {grossRef?.user?.accountType !== "customer" && (
              <View style={styles.themeColumn}>
                <Text style={{ fontSize: "15px" }}>Self Amount: </Text>
                <Text
                  style={{
                    color: grossRef?.selfAmount > 0 ? "green" : "red",
                    fontSize: "15px",
                  }}
                >
                  {formatToINR(grossRef?.selfAmount) || 0}
                </Text>
              </View>
            )}
            <View style={styles.themeColumn}>
              <Text style={{ fontSize: "15px" }}>
                Final Bill With Brokerage:{" "}
              </Text>
              <Text
                style={{
                  color: grossRef?.totalNetAmount > 0 ? "green" : "red",
                  fontSize: "15px",
                }}
              >
                {formatToINR(grossRef?.totalNetAmount) || 0}
              </Text>
            </View>
          </View>

          {Object.keys(valanBill).length === 0 && (
            <View style={{ alignItems: "center", marginTop: 50 }}>
              <Text style={{ fontSize: 32 }}>No data available</Text>
            </View>
          )}
          <View style={{ marginTop: "20px" }}></View>
          {Object.keys(valanBill).length > 0 &&
            Object.keys(valanBill).map((script, index) => (
              <View style={styles.themeCard} key={index}>
                <View style={styles.titleRow}>
                  <Text style={styles.scriptTitle}>{script}</Text>
                  <View style={styles.segmentBill}>
                    <Text>
                      Segment Bill:
                      <Text
                        style={{
                          color:
                            valanBill[script].totalMarketNetAmount >= 0
                              ? "green"
                              : "red",
                        }}
                      >
                        {formatToINR(valanBill[script].totalMarketNetAmount) ||
                          0}
                      </Text>
                    </Text>
                  </View>
                </View>
                {valanBill[script].trades.map((item, index) => (
                  <View key={index}>
                    <Text style={styles.scriptHeader}>
                      {`${item._id.symbolName} ${
                        valanBill[script].trades[0].trades[0].expiryDate
                          ? valanBillDate(
                              valanBill[script].trades[0].trades[0].expiryDate,
                              false
                            )
                          : null
                      }`}
                    </Text>
                    <View style={styles.table}>
                      {/* Render table headers */}
                      <View style={styles.tableRow}>
                        {script === "NSE EQU" &&
                          columnEQU?.map((col, index) => (
                            <Text style={styles.tableCell} key={index}>
                              {col.title}
                            </Text>
                          ))}
                        {script !== "NSE EQU" &&
                          column?.map((col, index) => (
                            <Text style={styles.tableCell} key={index}>
                              {col.title}
                            </Text>
                          ))}
                      </View>
                      {/* Render table rows */}
                      {item?.trades?.map((trade, index) => (
                        <View style={styles.tableRow} key={index}>
                          {script === "NSE EQU" &&
                            columnEQU.map((col, indexs) => (
                              <Text style={styles.tableCell} key={indexs}>
                                {col.render
                                  ? col.render(
                                      trade[col.dataIndex],
                                      trade,
                                      index
                                    )
                                  : trade[col.dataIndex]}
                              </Text>
                            ))}
                          {script !== "NSE EQU" &&
                            column.map((col, indexs) => (
                              <Text style={styles.tableCell} key={indexs}>
                                {col.render
                                  ? col.render(
                                      trade[col.dataIndex],
                                      trade,
                                      index
                                    )
                                  : trade[col.dataIndex]}
                              </Text>
                            ))}
                        </View>
                      ))}
                    </View>
                    {/* Render table footer */}
                    <View style={styles.footerContainer}>
                      <View style={styles.tableRow}>
                        <Text style={[styles.tableCell]}>
                          ScriptWise Total :
                        </Text>
                        <Text style={styles.tableCell}></Text>
                        <Text style={styles.tableCell}></Text>
                        <Text style={styles.tableCell}></Text>
                        {script !== "NSE EQU" && (
                          <Text style={styles.tableCell}></Text>
                        )}
                        <Text style={styles.tableCell}>
                          {formatToINR(item?.totalBuyQuantity)}
                        </Text>
                        <Text style={styles.tableCell}>
                          {formatToINR(item?.totalSellQuantity)}
                        </Text>
                        <Text style={styles.tableCell}>
                          {formatForValanBill(item.rate)}
                        </Text>
                        {!withoutBrokerage && !isCustomer ? (
                          <Text style={styles.tableCell}>
                            {`Br. : ${formatForValanBill(item?.netRate)}`}
                          </Text>
                        ) : (
                          <Text style={styles.tableCell}></Text>
                        )}
                        <Text style={styles.tableCell}>
                          {item?.totalNetAmount
                            ? formatForValanBill(item?.totalNetAmount)
                            : ""}
                        </Text>
                      </View>
                    </View>
                  </View>
                ))}
                <View style={styles.scriptBottom}>
                  {!isCustomer && (
                    <Text style={styles.themeColumn}>
                      Total Brokerage :{" "}
                      {formatToINR(valanBill?.[script]?.totalBrokerage) || 0}
                    </Text>
                  )}
                  {grossRef?.user?.accountType !== "customer" && (
                    <Text style={styles.themeColumn}>
                      Self Brokerage::
                      {formatToINR(valanBill?.[script]?.selfBrokerage) || 0}
                    </Text>
                  )}
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default PDFDocument;
