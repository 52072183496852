import { Col, Radio, Row, Spin, Switch, notification, theme } from "antd";
import React, { useState } from "react";
import useColorChange from "../../hooks/useColorChange";
import { useSelector } from "react-redux";
import ThemeCard from "../../components/ThemeCard";
import ThemeInput from "../../components/ThemeInput";
import ThemeDropDown from "../../components/ThemeDropDown";
import ActionButton from "../../components/ActionButton";
import { useEffect } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQueryClient } from "@tanstack/react-query";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import { options, offlineOptions, StyledTheme } from "./style";

const istrue = ["COMEX", "FOREX", "GLOBEX"];

const ModalView = ({ modalType, record, onClose, activeScript }) => {
  // console.log("🚀 ~ ModalView ~ record:", record);
  // console.log("activeScript:", activeScript[record?.symbol], activeScript);
  const token = theme.useToken().token;

  //state
  const [tradeType, setTradeType] = useState("market");
  const [type, setType] = useState(undefined);
  const [tradeLoading, setTradeLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [offlineTrade, setOfflineTrade] = useState(false);
  const [price, setPrice] = useState(0);
  const [userData, setUserData] = useState([]);
  const [customerId, setCustomerId] = useState("");
  const queryClient = useQueryClient();
  const [value, setValue] = useState({
    lot: 1,
    qty: record?.lotSize,
  });
  const [preValue, setPreValue] = useState({ lot: 1, qty: record?.lotSize });
  const showLot = record?.marketName === "NSE EQU" ? false : true;

  // redux
  const userDetails = useSelector((state) => state.authSlice);
  const data = useSelector(
    (state) => state?.webSocketSlice?.webSocket?.data?.[record?.symbol] || 0
  );

  // variable

  const lotSize = record?.lotSize || 1;
  const userId = userDetails?.userDetail?.userId;
  const userIP = userDetails?.user?.userIP || "";
  const accountType = userDetails?.userDetail?.accountType;
  const allowofflineTrade = userDetails?.additionalSharing?.offlineTrade;
  const market =
    record?.marketName === "FOREX" || record?.marketName === "GLOBEX"
      ? "forex"
      : "other";

  // api call
  const getuser = async () => {
    setCustomerLoading(true);
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getUserByFilter}customer`
      );
      if (response.status === 200) {
        // console.log("filter", response?.data?.data?.finalResult);
        const finalData = response?.data?.data?.finalResult;
        const userData = finalData?.map((el) => {
          return {
            label: `${el?.userId} (${el?.accountName})`,
            value: el?.userId,
          };
        });
        // console.log("userData", userData);
        // setUserSetting(userData);
        setUserData(userData);
      }
    } catch (error) {
      console.log("error");
    } finally {
      setCustomerLoading(false);
    }
  };

  const createTrade = async (tradeObj) => {
    setTradeLoading(true);
    try {
      let response = await APICall("post", EndPoints.createTrade, tradeObj);
      if (response?.status === 200) {
        notification.success({ message: response?.data?.message });
        setTradeLoading(false);
        queryClient.resetQueries(["allTrade"]);
        onClose();
        setPrice(0);
      } else {
        notification.error({ message: response?.data?.message, duration: 10 });
        console.log("error", response?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTradeLoading(false);
    }
  };

  // life cycle
  useEffect(() => {
    if (accountType === "customer") {
      setCustomerId(userId);
    } else {
      getuser();
    }
    // getClientIP();
  }, []);

  // useEffect(() => {
  //   if (lotSize) {
  //     setValue((prev) => ({
  //       lot: prev.lot,
  //       qty: (prev?.lot || 1) * lotSize,
  //     }));
  //   }
  // }, [lotSize]);

  useEffect(() => {
    if (modalType) {
      setType(modalType === "BuyPrice" ? 1 : 2);
    }
  }, [modalType]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "+") {
        setType(1);
      } else if (e.key === "-") {
        setType(2);
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [type]);

  // function
  const handleKeyDown = (event) => {
    console.log("event", event);
  };

  const handleSubmit = async () => {
    if (customerId === "") {
      return notification.error({ message: "Please fill CustomerId" });
    }
    if (!activeScript?.[record?.symbol] && !offlineTrade) {
      return notification.error({ message: "This script is not active" });
    }

    let tradeObj = {
      tradeId: userId,
      customerId: customerId,
      tradeIpAddress: userIP || 0,
      marketName: record?.marketName || null,
      symbolName: record?.symbolName || null,
      symbol: record?.symbol || null,
      expiryDate:
        record?.marketName === "FOREX" ||
        record?.marketName === "GLOBEX" ||
        record?.marketName === "NSE EQU"
          ? null
          : record?.expiryDate || null,
      lot: record?.marketName === "NSE EQU" ? 0 : Number(value.lot),
      lotSize: record?.marketName === "NSE EQU" ? 0 : record?.lotSize,
      ltp: data?.LTP,
      quantity: Number(value.qty),
      title: tradeType,
      ordertype:
        tradeType === "market" || tradeType == "limit" ? "market" : "intraday",
      stoploss:
        record?.marketName === "GLOBEX" || record?.marketName === "FOREX"
          ? type == 1
            ? data?.Bid
            : data?.Ask
          : data?.LTP || 0,
      takeprofit: null,
      tradePattern: type === 1 ? "buy" : "sell",
      tradePrice:
        (tradeType === "market" || tradeType === "intraday") && !offlineTrade
          ? type == 1
            ? data?.Bid
            : data?.Ask
          : Number(price),
      high: data?.High,
      low: data?.Low,
      strikePrice: record?.strikePrice || null,
      series: record?.series || null,
      marketStatus: offlineTrade === true ? "offline" : "online",
      tradeTime: new Date(),
      deviceType: "web",
    };

    // eq=lot no;
    // fut=qut yes

    // console.log("trade....", tradeObj);
    if (
      (tradeObj.lot <= 0 && record?.marketName !== "NSE EQU") ||
      tradeObj?.tradePrice <= 0 ||
      tradeObj?.tradePrice == undefined ||
      tradeObj?.quantity <= 0
    ) {
      notification.error({
        message: "You can not trade with 0 lot/quantity/price",
      });
    } else {
      createTrade(tradeObj);
    }
  };

  const onChangeTradeType = ({ target: { value } }) => {
    setTradeType(value);
    if (value === "limit" || value === "intradaylimit") {
      setPrice(0);
    } else {
      setPrice(type == 1 ? data?.Bid : data?.Ask);
    }
  };

  const onChange = ({ target: { value } }) => {
    setType(value);
  };

  const onChangeValue = ({ target: { name, value } }) => {
    // const wholeNumberRegex = /^\d+$/;
    // if (!wholeNumberRegex.test(value) && value !== "" && name !== "lot") {
    //   return;
    // }
    // if (
    //   !wholeNumberRegex.test(lotSize * (value || 1)) &&
    //   value !== "" &&
    //   name === "lot"
    // ) {
    //   return;
    // }

    if (name === "lot") {
      setValue({ lot: value, qty: lotSize * (value || 1) });
      setPreValue({ lot: value, qty: lotSize * (value || 1) });
    } else {
      setValue({ qty: value, lot: (value || 1) / lotSize });
      setPreValue({ qty: value, lot: (value || 1) / lotSize });
    }
  };

  const onChangePrice = ({ target: { value } }) => {
    const regex = /^\d*\.?\d{0,2}$/;
    const valid = istrue.includes(record?.marketName);

    if ((regex.test(value) || value === "") && !valid) {
      const decimal = value.split(".")[1]?.toString();
      if (decimal?.length == 2) {
        const secondDecimalDigit = decimal[1];
        if (
          secondDecimalDigit === "0" ||
          secondDecimalDigit === "5" ||
          record?.marketName === "NSE EQU"
        ) {
          setPrice(value);
        }
      } else if (!decimal || decimal?.length === 1) {
        setPrice(value);
      }
    }
    if (valid) {
      // let numberValue = Number(value);
      // if (numberValue || !value) {
      setPrice(value);
      // }
    }
  };

  const offlineTradeHandler = (item) => {
    setOfflineTrade(item);
    if (item) {
      if (tradeType !== "limit" || tradeType !== "intradaylimit") {
        setPrice(type == 1 ? data?.Bid : data?.Ask);
      } else {
        setPrice(0);
      }
    }
  };

  const onBlurPrice = () => {
    let checkPrice = Number(price);
    if (price === "" || !checkPrice) {
      setPrice(0);
    }
  };

  const onFocusPrice = () => {
    setPrice("");
  };

  const onChangeDropDown = (value) => {
    let stringValue = value[0];
    setCustomerId(stringValue);
  };

  const onBlur = ({ target: { name, value } }) => {
    if (!value) {
      setValue({
        lot: preValue.lot,
        qty: preValue.qty,
      });
    }
  };

  const onFocus = ({ target: { name, value } }) => {
    const numberValue = parseFloat(value.replace(/,/g, ""));
    setPreValue((pre) => ({ ...pre, [name]: numberValue }));
    setValue((pre) => ({ ...pre, [name]: null }));
  };

  if (!type) {
    return null;
  }

  return (
    <StyledTheme.Root>
      <StyledTheme.Modal
        open={true}
        onOk={onClose}
        onCancel={onClose}
        $type={type}
      >
        {accountType !== "customer" && allowofflineTrade && (
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Switch
              checkedChildren="Offline Trade"
              unCheckedChildren="Online Trade"
              onChange={offlineTradeHandler}
            />
          </Row>
        )}
        <Row
          justify={"space-evenly"}
          gutter={[5, 10]}
          style={{ marginTop: "1rem" }}
        >
          <Col xs={12} sm={8} md={6} lg={4} xl={3}>
            <ModalCellTrade
              title={`${record?.symbolName} ${record?.strikePrice || ""} ${
                record?.option_type || ""
              }  ${record?.expiryDate || ""}`}
              hideValue
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"SELL RATE"}
              currentValue={parseFloat(data?.Ask) || 0}
              checkUpdates
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"BUY RATE"}
              currentValue={parseFloat(data?.Bid) || 0}
              checkUpdates
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={market === "forex" ? "SPREAD" : "LTP"}
              currentValue={
                market === "forex" && data?.Spread
                  ? data?.Spread
                  : data?.LTP
                  ? data?.LTP
                  : 0
              }
              checkUpdates
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"VOLUME"}
              currentValue={
                data?.Volume == "0" ? "0" : data?.Volume ? data?.Volume : 0
              }
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"CHANGE %"}
              currentValue={`${data?.Price_Change || 0}%`}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"NET CHG"}
              currentValue={data?.Net_Change || 0}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade title={"HIGH"} currentValue={data?.High || 0} />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"LOW"}
              currentValue={parseFloat(data?.Low) || 0}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"OPEN"}
              currentValue={parseFloat(data?.Open) || 0}
            />
          </Col>
          <Col xs={8} sm={6} md={4} lg={3} xl={2}>
            <ModalCellTrade
              title={"CLOSE"}
              currentValue={parseFloat(data?.Close) || 0}
            />
          </Col>
        </Row>
        <div
          style={{
            height: "2px",
            backgroundColor: token.colorBgBase,
            marginTop: "1.5rem",
          }}
        />
        <ThemeCard bordered={false} style={{ margin: "1rem 0rem" }}>
          <StyledTheme.BuySellWrapper gutter={[5, 10]} justify={"space-evenly"}>
            <StyledTheme.RadioDiv>
              <Col xs={24} sm={12} md={12} lg={12} xl={7}>
                <Radio.Group
                  value={type}
                  onChange={onChange}
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Radio
                    value={1}
                    style={{
                      fontWeight: 600,
                      color: token.colorTextLabel,
                      fontSize: "1rem",
                    }}
                  >
                    Buy
                  </Radio>
                  <Radio
                    value={2}
                    style={{
                      fontWeight: 600,
                      color: token.colorTextLabel,
                      fontSize: "1rem",
                    }}
                  >
                    Sell
                  </Radio>
                </Radio.Group>
              </Col>
              <Col span={24}>
                <StyledTheme.MarketTypeRadio
                  options={offlineTrade ? offlineOptions : options}
                  onChange={onChangeTradeType}
                  value={tradeType}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Col>
            </StyledTheme.RadioDiv>

            {showLot && (
              <Col
                xs={24}
                sm={12}
                md={8}
                lg={6}
                xl={4}
                style={{ marginTop: "1rem" }}
              >
                <ThemeInputNumber
                  marginBottom={"0rem"}
                  width={"100%"}
                  title="LOT"
                  extraProps={{
                    input_type: "number",
                  }}
                  inputProps={{
                    placeholder: "LOT",
                    name: "lot",
                    value: value?.lot,
                    onChange: onChangeValue,
                    onBlur: onBlur,
                    onFocus: onFocus,
                  }}
                />
              </Col>
            )}
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              style={{ marginTop: "1rem" }}
            >
              <ThemeInputNumber
                marginBottom={"0rem"}
                width={"100%"}
                title="QTY"
                className="ThemeInput"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  placeholder: "QTY",
                  name: "qty",
                  value: value?.qty,
                  onChange: onChangeValue,
                  onBlur: onBlur,
                  onFocus: onFocus,
                  // disabled: record?.market !== "NSE FUT",
                  locked: true,
                }}
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={8}
              lg={6}
              xl={4}
              style={{ marginTop: "1rem" }}
            >
              <ThemeInput
                style={{ fontWeight: "bold" }}
                marginBottom={"0rem"}
                width={"100%"}
                title="PRICE"
                className="ThemeInput"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  onChange: onChangePrice,
                  onBlur: onBlurPrice,
                  onFocus: onFocusPrice,
                  // locked: !isPriceChangeAllow,
                  readOnly:
                    (tradeType === "market" || tradeType === "intraday") &&
                    !offlineTrade
                      ? "readOnly"
                      : null,
                  placeholder: "Price",
                  value:
                    (tradeType === "market" || tradeType === "intraday") &&
                    !offlineTrade
                      ? type == 1
                        ? data?.Bid
                        : data?.Ask
                      : price,
                  // tradeType === "intradaylimit" ||
                  // tradeType === "limit" ||
                  // offlineTrade
                  //   ? price
                  //   : type === 2
                  //   ? record?.marketName === "FOREX"
                  //     ? (data?.Bid).toFixed(5)
                  //     : data?.Bid
                  //   : record?.marketName === "FOREX"
                  //   ? (data?.Ask).toFixed(5)
                  //   : data?.Ask,
                }}
              />
            </Col>
            {accountType !== "customer" && (
              <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                <ThemeDropDown
                  // marginBottom={"0rem"}
                  width={"100%"}
                  title={"Client"}
                  showSearch={true}
                  placeholder="Select Client"
                  options={userData}
                  value={customerId}
                  onChange={onChangeDropDown}
                  suffixIcon={<Spin size="small" spinning={customerLoading} />}
                />
              </Col>
            )}
          </StyledTheme.BuySellWrapper>

          <Row justify={"center"}>
            <ActionButton
              title={"Submit"}
              onClick={handleSubmit}
              loading={tradeLoading}
            />

            <ActionButton
              title={"Cancel"}
              buttonType="cancel"
              onClick={onClose}
            />
          </Row>
        </ThemeCard>
      </StyledTheme.Modal>
    </StyledTheme.Root>
  );
};

export default ModalView;

const ModalCellTrade = React.memo(
  ({
    name = "",
    title,
    currentValue,
    checkUpdates = false,
    hideValue = false,
  }) => {
    const token = theme.useToken().token;
    const { color, backgroundColor } = useColorChange({
      currentValue: checkUpdates ? currentValue : null,
      defaultBgColor: token.colorBgBase,
      defaultColor: token.colorTextLabel,
    });

    return (
      <StyledTheme.Cell
        style={{
          backgroundColor,
          border: "1px solid white",
        }}
      >
        <div className="cell-title" style={{ color }}>
          {title}
        </div>
        {hideValue ? null : (
          <div className="cell-number" style={{ color }}>
            {currentValue || "~"}
          </div>
        )}
      </StyledTheme.Cell>
    );
  }
);
