import React, { useEffect, useRef, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Row } from "antd";
import UserFilterComponant from "../../components/UserFilterComponant/UserFilterComponant";
import ThemeCardTable from "../../components/ThemeCardTable";
import { useDispatch, useSelector } from "react-redux";
import { setUserClearFilter } from "../../store/slice/globleSlice";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { useQuery } from "@tanstack/react-query";
import useGetQueryParams from "../../hooks/useGetQueryParams";
import { StyledButton } from "../../styles/commonStyle";

function TradingBlocked() {
  const { params, setSearchParams } = useGetQueryParams();
  const valueRef = useRef({});
  const dispatch = useDispatch();

  // redux
  const loginUserId = useSelector(
    (state) => state.authSlice?.userDetail?.userId
  );
  const loginAccountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );

  //life cycle
  useEffect(() => {
    if (Object.keys(params)?.length === 0) {
      setSearchParams({ userId: loginUserId });
    }
  }, [params]);

  useEffect(() => {
    setSearchParams({
      ...params,
      userId: params?.userId ? params.userId : loginUserId,
    });
  }, []);

  // api call
  const getBanScript = async () => {
    let response = await APICall(
      "get",
      `${EndPoints.banScriptList}?userId=${params?.userId}`
    );

    return response?.data?.data;
  };

  const {
    isLoading,
    data: blockedScript,
    error,
  } = useQuery({
    queryKey: ["blockScript", params],
    queryFn: getBanScript,
    enabled: Object.keys(params)?.length > 0,
  });

  // column
  const columns = [
    {
      title: "SR. No.",
      dataIndex: "sr",
      width: "5rem",
      key: "sr",
      render: (text, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "SCRIPT",
      dataIndex: "Script",
      key: "Script",
      // width: "25rem",
    },
    // Table.SELECTION_COLUMN,
    // {
    //   title: "ALLOW",
    //   dataIndex: "allow",
    //   key: "allow",
    // },
    {
      title: "BLOCKED BY",
      dataIndex: "blockedBy",
      key: "blockedBy",
    },
  ];

  // function
  const handleClear = () => {
    dispatch(setUserClearFilter(true));
    valueRef.current = {};
    setSearchParams({ userId: loginUserId });
  };
  const handleFind = () => {
    let payloadValue = valueRef.current;
    if (payloadValue?.userId) {
      setSearchParams({ userId: payloadValue.userId });
    }
  };

  return (
    <>
      {loginAccountType !== "customer" && (
        <ThemeCard style={{ marginTop: "2rem" }}>
          <Row gutter={[20, 20]}>
            <UserFilterComponant valueRef={valueRef} />
          </Row>
          <Row style={{ marginTop: "2rem" }} gutter={[10, 10]}>
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <StyledButton onClick={handleFind} type="primary">
                Search
              </StyledButton>
            </Col>
            <Col xs={24} sm={12} md={4} lg={3} xl={2}>
              <StyledButton onClick={handleClear} type="primary">
                Clear Search
              </StyledButton>
            </Col>
          </Row>
        </ThemeCard>
      )}

      <ThemeCardTable
        data={blockedScript}
        column={columns}
        headingStyle={false}
        isFilterTrue={true}
        isLoading={isLoading}
        filterType={"blockScript"}
      />
    </>
  );
}

export default TradingBlocked;
