import { useRef, useState } from "react";
import { MarketWatchButton } from "../style";

import { ClockCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Col } from "antd";
import AllScriptLogic from "./AllScriptLogic";

const AddAllScript = ({ value, scriptList, name, flage }) => {
  // state
  const [loading, setLoading] = useState(false);
  const allScriptRef = useRef();

  // function
  const addAllScriptTable = () => {
    if (allScriptRef.current) {
      allScriptRef.current.getAllScript();
    }
  };

  return (
    <Col>
      <MarketWatchButton
        type="primary"
        icon={flage ? <ClockCircleFilled /> : <PlusCircleFilled />}
        onClick={addAllScriptTable}
        loading={loading}
      >
        {/* Add All Scripts */}
        {name}
      </MarketWatchButton>
      <AllScriptLogic
        value={value}
        ref={allScriptRef}
        scriptList={scriptList}
        setLoading={setLoading}
        flage={flage}
      />
    </Col>
  );
};

export default AddAllScript;
