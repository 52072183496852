import { Modal, Radio, Table, Tabs } from "antd";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  width: 80% !important;
  /* height: 500px !important; */
  .ant-modal-close-x {
    /* display: none; */
  }
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    @media (max-width: 650px) {
      padding: 15px 5px !important;
    }
  }

  @media (max-width: 650px) {
    width: 95% !important;
    /* padding: 5px; */
  }
`;
export const StyTable = styled(Table)`
  transform: none !important;
  & .ant-table-thead tr .ant-table-cell {
    background-color: ${(props) => props.theme.token.colorPrimary};
    color: ${(props) => props.theme.token.colorTextLightSolid};
    font-size: 0.9rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1079px) {
    .ant-table-thead tr .ant-table-cell {
      font-size: 0.75rem;
    }
  }
  .ant-table-thead tr .ant-table-cell::before {
    display: none;
  }

  .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
    border-top: ${(props) =>
      `1px solid ${props.theme.token.colorBorder} !important`};
  }
  .ant-table-body .ant-table-cell {
    border-bottom: 0px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 0.5rem !important;
  }
  .ant-table-thead tr th {
    height: 2rem !important;
    padding: 0rem 0.5rem !important;
  }
  .collapseTable .ant-collapse-expand-icon span svg {
    fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
  }
  .collapseTable .ant-collapse-header-text {
    color: ${(props) => props.theme.token.colorTextLightSolid};
  }

  .ant-switch-checked .ant-switch-inner {
    background-color: ${(props) =>
      props.theme.token.colorBgActionButton} !important;
  }

  .ant-table-thead {
    height: 3.18rem;
  }

  .ant-table-thead .ant-table-cell {
    &:hover {
      background: ${(props) =>
        props.theme.token.colorPrimaryBgHover} !important;
    }
  }
  .ant-table-body .ant-table-cell {
    padding: 0.5rem !important;
  }

  .ant-table-tbody .ant-table-row:hover {
    background: #f5f5f5;
  }
`;
export const StyledTabs = styled(Tabs)`
  /* background-color: red; */
  font-size: 16px;
  padding: 16px;
  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1677ff !important;
    font-weight: 600 !important;
    font-size: 1.3rem !important;
  }
  .ant-tabs-nav-list {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-tabs-tab-btn {
    color: #727880;
    font-weight: 500;
    font-size: 1.3rem !important;
  }
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  @media (max-width: 1060px) {
    .ant-tabs-nav-wrap {
      justify-content: left;
    }
  }
`;
export const option = [
  { value: "10", label: "10" },
  { value: "20", label: "20" },
  { value: "30", label: "30" },
  { value: "40", label: "40" },
];
export const UserStatusOptions = [
  { label: "Active", value: "Active" },
  { label: "Block", value: "Block" },
];
export const LoginStatusOptions = [
  { label: "Login", value: "Login" },
  { label: "Logout", value: "Logout" },
];
export const AccountTypeRadioButton = styled(Radio.Group)`
  width: 100%;
  text-align: center;
  & .ant-radio-button-wrapper {
    width: 80px;
    font-size: 1.1rem;
  }
`;
export const accountOption = [
  {
    label: "Normal",
    value: "default",
  },
  { label: "Rent", value: "rent" },
];
