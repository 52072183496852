import { ReloadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Skeleton, Spin, Table } from "antd";
import React, { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import "../../pages/Dashboard/style.css";

const StyledTheme = {
  Root: styled.div`
    margin-top: 2rem;
  `,
  MainHeader: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    align-items: center;
    margin-bottom: 1rem;
  `,
  Title: styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    @media (max-width: 1079px) {
      font-size: 1rem;
    }
  `,
  RightHeaderWrapper: styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 1440px) {
      align-items: center;
      display: flex;
    }
  `,
  ViewMore: styled.div`
    font-size: 1.2rem;
    font-weight: 600;
    color: #465f7b;
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 1079px) {
      font-size: 1rem;
    }
  `,
  AllowButton: styled(Button)`
    border-radius: ${({ theme }) => theme.token.borderRadiusLG};
    font-size: 1rem;
    height: 3rem;
    width: unset !important;
    background-color: ${({ theme }) => theme.token.colorBgActionButton};
    margin-right: 20px;
    @media (max-width: 1079px) {
      height: 2rem;
      font-size: 0.8rem;
    }
  `,
  Table: styled(Table)`
    transform: none !important;
    & .ant-table-thead tr .ant-table-cell {
      background-color: ${(props) => props.theme.token.colorPrimary};
      color: ${(props) => props.theme.token.colorTextLightSolid};
      font-size: 0.9rem;
      font-weight: 500;
    }
    .ant-table-cell-ellipsis {
      max-width: 100px;
    }
    @media screen and (max-width: 1079px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.75rem;
      }
    }
    .ant-table-thead tr .ant-table-cell::before {
      display: none;
    }

    .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
      border-top: ${(props) =>
        `1px solid ${props.theme.token.colorBorder} !important`};
    }
    .ant-table-body .ant-table-cell {
      border-bottom: 0px;
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 0.5rem !important;
    }
    .ant-table-thead tr th {
      height: 2rem !important;
      padding: 0rem 0.5rem !important;
    }
    .collapseTable .ant-collapse-expand-icon span svg {
      fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
    }
    .collapseTable .ant-collapse-header-text {
      color: ${(props) => props.theme.token.colorTextLightSolid};
    }

    .ant-switch-checked .ant-switch-inner {
      background-color: ${(props) =>
        props.theme.token.colorBgActionButton} !important;
    }

    .ant-table-thead {
      height: 3.18rem;
    }

    .ant-table-thead .ant-table-cell {
      &:hover {
        background: ${(props) =>
          props.theme.token.colorPrimaryBgHover} !important;
      }
    }
    .ant-table-body .ant-table-cell {
      padding: 0.5rem !important;
    }

    .ant-table-tbody .ant-table-row:hover {
      background: #f5f5f5;
    }
    .ant-table-body {
      scroll-behavior: smooth; /* Enables smooth scrolling */
    }
  `,
};

const columnSpan = (name) => {
  switch (name) {
    case name === "allTrade":
      return "8";

    case name === "pendingTrade":
      return "9";

    case name === "rejectionLogs":
      return "10";

    case name === "m2mAlerts":
      return "5";

    default:
      return "10";
  }
};

function DashboardCommonTable(props) {
  const { tradeQuery, dataSource, isLoading, queryKey, columns, onChange } =
    props;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const lastRowRef = useRef(null);
  const scrollRef = useRef(null);

  const handleObserver = useCallback(
    (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          tradeQuery?.hasNextPage &&
          !tradeQuery.isFetchingNextPage
        ) {
          tradeQuery?.fetchNextPage();
        }
      });
    },
    [
      tradeQuery?.fetchNextPage,
      tradeQuery?.hasNextPage,
      tradeQuery?.isFetchingNextPage,
    ]
  );

  // life cycle
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: tableRef.current,
      rootMargin: "50px",
      threshold: 0.3,
    });

    // Determine the last row of the current data and observe it
    const rows = tableRef.current?.querySelectorAll(".ant-table-row");

    const lastRow = rows ? rows[rows.length - 1] : null;

    if (lastRow) {
      observer.observe(lastRow);
      lastRowRef.current = lastRow; // Update the last row reference
    }

    return () => {
      if (lastRowRef.current) observer.unobserve(lastRowRef.current);
    };
  }, [dataSource, tradeQuery]);

  // function
  const loadingComponant = () => (
    <tr>
      <td colSpan={columnSpan(queryKey)}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      </td>
    </tr>
  );
  const handleReload = () => {
    queryClient.resetQueries([props?.queryKey]);
    const dataSource = props?.dataSource;
    if (dataSource) {
      dataSource.length = 0; // Empties the array
    }
  };
  const navigator = () => {
    if (props?.queryKey === "allTrade" || props?.queryKey === "pendingTrade") {
      navigate("/trading/trade");
    } else if (props?.queryKey === "rejectionLogs") {
      navigate("/utilities/rejection-log");
    } else if (props?.queryKey === "m2mAlerts") {
      navigate("/utilities/M2mAlert-Log");
    }
  };

  useEffect(() => {
    const handleTableWheelScroll = (e) => {
      const tableBody = scrollRef.current?.querySelector(".ant-table-body");

      if (tableBody && tableBody.contains(e.target)) {
        const scrollTop = tableBody.scrollTop;
        const maxScrollTop = tableBody.scrollHeight - tableBody.clientHeight;

        // Prevent default scrolling only when table can scroll further
        if (
          (e.deltaY < 0 && scrollTop > 0) || // Scroll up when not at the top
          (e.deltaY > 0 && scrollTop < maxScrollTop) // Scroll down when not at the bottom
        ) {
          e.preventDefault();
          tableBody.scrollTop += e.deltaY * 2; // Manually scroll the table
        }
      }
    };

    // Attach event listener to table body for mouse scroll
    const tableBody = scrollRef.current?.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.addEventListener("wheel", handleTableWheelScroll, {
        passive: false, // Allow preventDefault to work
      });
    }

    // Cleanup the event listener on unmount
    return () => {
      if (tableBody) {
        tableBody.removeEventListener("wheel", handleTableWheelScroll);
      }
    };
  }, []);

  return (
    <StyledTheme.Root ref={scrollRef}>
      <StyledTheme.MainHeader>
        <StyledTheme.Title>{props && props?.tableTitle}</StyledTheme.Title>
        <StyledTheme.RightHeaderWrapper>
          {props?.allowBtn ? (
            <StyledTheme.AllowButton block type="primary" htmlType="submit">
              Allow
            </StyledTheme.AllowButton>
          ) : null}
          <StyledTheme.ViewMore onClick={navigator}>
            {props?.viewMore ? "View More>>" : ""}
          </StyledTheme.ViewMore>
          <span
            style={{
              marginLeft: "2rem",
              marginRight: "1rem",
              color: "#465f7b",
            }}
            onClick={handleReload}
          >
            <ReloadOutlined />
          </span>
        </StyledTheme.RightHeaderWrapper>
      </StyledTheme.MainHeader>

      <div
        ref={tableRef}
        style={{
          height: "270px",
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <StyledTheme.Table
          // virtual

          className="dashboard-tables"
          columns={props && props?.columns}
          dataSource={props && props?.dataSource}
          pagination={false}
          onChange={onChange}
          sticky
          locale={{
            emptyText: props && props?.isLoading ? <Skeleton active /> : null,
          }}
          rowKey="_id"
          summary={() => tradeQuery?.isFetchingNextPage && loadingComponant()}
          scroll={{ x: true, y: 225, scrollToFirstRowOnChange: false }}
        />
        {/* <div style={{ textAlign: "center", margin: "10px 0" }}>
          {tradeQuery?.isFetchingNextPage && <Spin />}
        </div> */}
      </div>
    </StyledTheme.Root>
    // </div>
  );
}

export default DashboardCommonTable;
