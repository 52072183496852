import React, { useEffect, useState } from "react";
import ThemeCard from "../../components/ThemeCard";
import { Col, Form, Row, notification } from "antd";
import ThemeInput from "../../components/ThemeInput";
import ThemeDatePicker from "../../components/ThemeDatePicker";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import ThemeDropDown from "../../components/ThemeDropDown";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { SubmitButton, ThemeForm } from "../AccountPointEntry/style";
import ThemeInputNumber from "../../components/ThemeInputNumber/ThemeInputNumber";
import { useWatch } from "antd/es/form/Form";

const initalState = {
  debitFrom: "",
  creditTo: "",
  type: "JV",
  date: "",
  point: "",
  remark: "",
};

function AccountPointJVEntry() {
  // state
  const [value, setValue] = useState(initalState);
  const [userOption, setUserOption] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = Form.useForm();
  // redux
  const userDetail = useSelector((state) => state.authSlice.userDetail);
  const debit = useWatch("debitFrom", form)?.[0];
  const credit = useWatch("creditTo", form)?.[0];

  // api call
  const onSubmit = async (value) => {
    const payload = {
      ...value,
      creditTo: value?.creditTo?.[0],
      debitFrom: value?.debitFrom?.[0],
      type: "JV",
      remark: value?.remark || null,
    };

    try {
      setSubmitLoading(true);
      let response = await APICall("post", EndPoints.createLedger, payload);

      if (response.status === 200) {
        notification.success({ message: response.data?.message });
        form.resetFields();
        // setValue(initalState);
      } else {
        notification.error({ message: response.data?.message });
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      console.log("error", error);
    }
  };

  const getChildUser = async () => {
    try {
      let response = await APICall(
        "get",
        `${EndPoints.getChildUsers}${userDetail?.userId}&type=onlychild`
      );
      return response?.data?.data?.childUsers;
    } catch (error) {
      console.log("error", error);
    }
  };

  const {
    isLoading,
    error,
    data: childData,
  } = useQuery({ queryKey: ["getChildUser"], queryFn: getChildUser });

  // life cycle
  useEffect(() => {
    if (childData) {
      setUserOption(() => {
        const list = childData.map((el) => {
          return {
            label: `${el?.accountName} (${el?.userId})`,
            value: el?.userId,
          };
        });
        return list;
      });
    }
  }, [childData]);

  // function
  const onChangeDate = (value) => {
    setValue((pre) => ({ ...pre, date: value }));
  };
  const onChangeInput = ({ target: { name, value } }) => {
    setValue((pre) => ({ ...pre, [name]: value }));
  };
  const onChangeDropDown = (name, value, option) => {
    setValue((pre) => ({ ...pre, [name]: value[0] }));
  };
  const handleCancel = () => {
    // setValue(initalState);
    form.resetFields();
  };

  const handleFinish = (value) => {
    // console.log("finish", value);
    onSubmit(value);
  };

  const handleFailed = (error) => {
    console.log("failed", error);
    notification.error({ message: error?.errorFields?.[0]?.errors?.[0] });
  };

  return (
    <ThemeCard title={"Account Point JV Entry"}>
      <ThemeForm
        form={form}
        onFinish={handleFinish}
        onFinishFailed={handleFailed}
        labelCol={{ xs: 24, sm: 8, md: 10, lg: 10, xl: 10 }}
        wrapperCol={{ xs: 24, sm: 12, md: 10, lg: 8, xl: 6 }}
        style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
      >
        <ThemeDropDown
          name="debitFrom"
          placeholder="Select"
          // $value={value.debitFrom}
          // $onChange={onChangeDropDown}
          options={userOption?.filter((el) => el?.value !== credit)}
          showSearch={true}
          style={{ width: "100%" }}
          // suffixIcon={<Spin size="small" spinning={isLoading} />}
          disabled={isLoading}
          form_props={{
            name: "debitFrom",
            label: "Debit Account",
            rules: [{ required: true }],
          }}
        />

        <ThemeDropDown
          name={"creditTo"}
          placeholder="Select"
          // $value={value.creditTo}
          // $onChange={onChangeDropDown}
          options={userOption?.filter((el) => el?.value !== debit)}
          showSearch={true}
          style={{ width: "100%" }}
          // suffixIcon={<Spin size="small" spinning={isLoading} />}
          disabled={isLoading}
          form_props={{
            name: "creditTo",
            label: "Credit Account",
            rules: [{ required: true }],
          }}
        />

        <ThemeDatePicker
          // onChange={onChangeDate}
          // value={value.date}
          width={"100%"}
          formProps={{
            name: "date",
            label: "Date",
            rules: [{ required: true }],
          }}
        />

        <ThemeInputNumber
          marginBottom={"0rem"}
          style={{ width: "100%" }}
          inputProps={{
            name: "point",
            // value: value.point,
            // onChange: onChangeInput,
          }}
          errorheight="0rem"
          formProps={{
            name: "point",
            label: "Point",
            rules: [{ required: true }],
          }}
        />

        <ThemeInput
          marginBottom={"0rem"}
          style={{ width: "100%" }}
          inputProps={{
            name: "remark",
            // value: value.remark,
            // onChange: onChangeInput,
          }}
          formProps={{
            name: "remark",
            label: "Remark",
          }}
        />

        <Row justify={"center"} style={{ marginTop: "2rem" }} gutter={[20, 20]}>
          <Col xs={24} sm={8} md={4} lg={4} xl={3}>
            <SubmitButton
              // onClick={onSubmit}
              loading={submitLoading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </SubmitButton>
          </Col>
          <Col xs={24} sm={8} md={4} lg={4} xl={3}>
            <SubmitButton remove={true} onClick={handleCancel}>
              Cancel
            </SubmitButton>
          </Col>
        </Row>
      </ThemeForm>
    </ThemeCard>
  );
}

export default AccountPointJVEntry;
