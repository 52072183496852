import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import APICall from "../../APICall";
import EndPoints from "../../APICall/EndPoints";
import { Col, Modal, Row, notification } from "antd";
import ThemeCard from "../ThemeCard";
import ThemeInput from "../ThemeInput";
import ThemeInputNumber from "../ThemeInputNumber/ThemeInputNumber";
import ActionButton from "../ActionButton";
import dayjs from "dayjs";
import ModalCellView from "../ModalCellView/ModalCellView";

const istrue = ["COMEX", "FOREX", "GLOBEX"];
const EditTrade = ({ handleCancel, record }) => {
  const queryCliant = useQueryClient();

  // state
  const [data, setData] = useState({
    lot: record?.lot,
    qty: record?.quantity,
    price: record?.tradePrice,
  });
  const [loading, setLoading] = useState(false);

  // api call
  const handleSubmit = async () => {
    let id = record?._id;

    // let customerId = record?.customerId?._id;

    let obj = {
      // id: record?.customerId?._id,
      lot: data.lot,
      quantity: data.qty,
      tradePrice: Number(data.price),
    };
    // console.log("editedTrade", obj);
    setLoading(true);
    try {
      let response = await APICall("patch", `${EndPoints.editTrade}${id}`, obj);
      // console.log("response", response);
      if (response.status === 200) {
        notification.success({ message: response?.data?.message });
        queryCliant.resetQueries({ queryKey: [record?.key, "getWatchList"] });
        queryCliant.refetchQueries({
          queryKey: "tradingTrade",
          type: "active",
        });
      } else {
        notification.error({ message: response?.data?.message });
      }
      setLoading(false);
      handleCancel();
    } catch (error) {
      setLoading(false);
      notification.error({ message: error?.message });
      console.log("error", error);
    }
  };

  // function
  const onChangeValue = ({ target: { name, value } }) => {
    if (name === "price") {
      const regex = /^\d*\.?\d{0,2}$/;
      const valid = istrue.includes(record?.marketName);
      // console.log("market", record?.marketName);
      if ((regex.test(value) || value === "") && !valid) {
        const decimal = value.split(".")[1]?.toString();
        if (decimal?.length == 2) {
          const secondDecimalDigit = decimal[1];
          if (
            secondDecimalDigit === "0" ||
            secondDecimalDigit === "5" ||
            record?.marketName === "NSE EQU"
          ) {
            setData({ ...data, [name]: value });
          }
        } else if (!decimal || decimal?.length === 1) {
          setData({ ...data, [name]: value });
        }
      }
      if (valid) {
        setData({ ...data, [name]: value });
      }
    } else if (name === "qty") {
      let lotValue = (value || 1) / record?.lotSize;
      setData({ ...data, [name]: value, lot: lotValue });
    } else if (name === "lot") {
      let qtyValue = record?.lotSize * (value || 1);
      setData({ ...data, [name]: value, qty: qtyValue });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  return (
    <Modal
      open={true}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={false}
    >
      <Row justify={"left"} style={{ marginTop: "1rem" }}>
        <Col span={12}>
          <ModalCellView
            title={`${record?.symbolName} ${record?.strikePrice || ""} ${
              record?.option_type || ""
            }  ${
              record?.expiryDate
                ? dayjs(record?.expiryDate)?.format("DD MMM YYYY") || ""
                : ""
            }`}
            hideValue
          />
        </Col>

        <Col span={10}>
          <ModalCellView
            title={`${record?.customer[0]?.accountName} (${record?.customer[0]?.userId})`}
            hideValue
          />
        </Col>
      </Row>
      <ThemeCard bordered={false} style={{ margin: "1rem 0rem" }}>
        <Row justify={"space-around"} gutter={[30, 0]}>
          <Col span={8}>
            <ThemeInput
              marginBottom={"0rem"}
              width={"100%"}
              title="Price"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "Price",
                name: "price",
                value: data?.price,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
          {record?.marketName !== "NSE EQU" && (
            <Col span={8}>
              <ThemeInputNumber
                marginBottom={"0rem"}
                width={"100%"}
                title="LOT"
                extraProps={{
                  input_type: "number",
                }}
                inputProps={{
                  placeholder: "LOT",
                  name: "lot",
                  value: data?.lot,
                  onChange: onChangeValue,
                  // onBlur: onChangeValue,
                  disabled: record?.marketName === "NSE EQU" ? true : false,
                }}
              />
            </Col>
          )}
          <Col span={8}>
            <ThemeInputNumber
              marginBottom={"0rem"}
              width={"100%"}
              title="QTY"
              className="ThemeInput"
              extraProps={{
                input_type: "number",
              }}
              inputProps={{
                placeholder: "QTY",
                name: "qty",
                value: data?.qty,
                onChange: onChangeValue,
                // onBlur: onChangeValue,
                // disabled: record?.marketName !== "NSE FUT" ? true : false,
              }}
            />
          </Col>
        </Row>
        <Row justify={"center"} style={{ marginTop: "2rem" }}>
          <ActionButton
            title={"Submit"}
            onClick={handleSubmit}
            loading={loading}
          />

          <ActionButton
            title={"Cancel"}
            buttonType="cancel"
            onClick={handleCancel}
          />
        </Row>
      </ThemeCard>
    </Modal>
  );
};

export default EditTrade;
