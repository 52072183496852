import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSocket } from "../components/Socket";
import store from "../store/store";
import APICall from "../APICall";
import EndPoints from "../APICall/EndPoints";
import { setMarketWatchData } from "../store/slice/marketWatchSlice";
import { setScriptCall } from "../store/slice/globleSlice";

const useGetWatchList = () => {
  // const [callAPI, setCallAPI] = useState(false);
  //redux
  const selectedWatchList =
    useSelector((state) => state.marketWatchSlice?.seletctedMaketIndex) || 0;
  const dispatch = useDispatch();
  const socketContext = useSocket();
  const { doSend, socketData } = socketContext;
  const storedScripts = store.getState()?.webSocketSlice?.webSocket?.data;
  const scriptRef = useRef(null);

  // life cycle
  const getWatchListByHook = (watcListData) => {
    if (watcListData) {
      // let obj = {
      //   "NSE FUT": [],
      //   "NSE OPT": [],
      //   "NSE EQU": [],
      //   MCX: [],
      //   "MCX OPT": [],
      //   FOREX: [],
      //   COMEX: [],
      //   GLOBEX: [],
      // };

      // watcListData?.map((item) => {
      //   if (!obj[item?.marketName]) {
      //     obj[item.marketName] = [];
      //     obj[item.marketName].push(item);
      //   } else {
      //     obj[item.marketName].push(item);
      //   }
      // });

      // for (let key in obj) {
      //   dispatch(
      //     setMarketWatchData({
      //       type: "multiple_add",
      //       nested_key: "data",
      //       key: key,
      //       payload: obj[key],
      //     })
      //   );
      // }

      // const allScript = watcListData?.map((item) => {
      //   return item.symbol;
      // });
      // const allScript = watcListData?.reduce((acc, curr) => {
      //   const symbolCall = curr?.scriptList?.map((el) => el?.symbol);
      //   acc = [...acc, ...symbolCall];
      //   return acc;
      // }, []);
      const allScript = watcListData?.[selectedWatchList]?.scriptList?.map(
        (el) => el?.symbol
      );

      dispatch(setScriptCall(allScript));
      setTimeout(() => {
        let filteredScript = allScript?.filter(
          (script) => !storedScripts[script]
        );
        // console.log("filteredScript", filteredScript);
        if (filteredScript?.length >= 1) {
          doSend({
            event: "subscribeToServerMarket",
            payload: filteredScript,
          });
          scriptRef.current = filteredScript;
        }
      }, 1);
    }
  };

  return { getWatchListByHook };
};

export default useGetWatchList;
