import React, { useMemo, useRef, useState } from "react";
import AddAllScript from "./AddAllScript";
import { Col, Spin, notification, Typography, theme, Skeleton } from "antd";
import { useSelector } from "react-redux";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import { SEGMENTS, options } from "../../../Helper/constant";
import { MarketWatchButton, StyledRow, TextTitle, style } from "../style";
import ThemeDropDown from "../../../components/ThemeDropDown";
import { PlusCircleFilled } from "@ant-design/icons";
import ScriptLogic from "./ScriptLogic";

const HeaderView = () => {
  const token = theme.useToken().token;

  //state
  const [value, setValue] = useState({
    marketName: null,
    script: null,
    expiryDate: null,
    Exchange: null,
    instrument_type: null,
    option_type: null,
    strikePrice: null,
  });
  const [strike_price_options, setStrikePriceOptions] = useState([]);
  const [isScriptListFetching, setIsScriptListFetching] = useState(false);
  const [isExpiryDateListFetching, setIsExpiryDateListFetching] =
    useState(false);
  const [scriptList, setScriptList] = useState([]);
  const [expiryDatesList, setExpiryDatesList] = useState([]);
  const { Title, Paragraph, Text, Link } = Typography;
  let show_expiry_date_input = false;
  let show_option_input = false;

  //redux
  const data = useSelector((state) => state.marketWatchSlice?.data);
  const marketList = useSelector((state) => state.globleSlice?.marketListData);

  // useEffect(() => {
  // console.log("marketName", value.marketName);
  if (
    value?.marketName !== "NSE EQU" &&
    value?.marketName !== "FOREX" &&
    value?.marketName !== "GLOBEX"
  ) {
    show_expiry_date_input = true;
  }
  if (value?.marketName === "MCX OPT" || value?.marketName === "NSE OPT") {
    show_option_input = true;
  }

  /* api */
  const getScriptList = async (obj) => {
    setScriptList([]);
    setIsScriptListFetching(true);
    await APICall("get", `${EndPoints.getScriptList}${obj.marketName}`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const scriptNameOptions = response.data?.data?.map((item, index) => {
            if (obj?.marketName === "globex") {
              return {
                key: index,
                value: item.symbol,
                label: item?.symbolName ? item?.symbolName : item?.symbol,
                lotSize: item?.lotSize,
              };
            } else {
              return {
                value: item.symbol,
                label: item?.symbolName ? item?.symbolName : item?.symbol,
                symbolId: item?.symbolId,
                key: index,
              };
            }
          });

          setScriptList(scriptNameOptions);
          setExpiryDatesList([]);
          if (
            value?.marketName === "NSE OPT" ||
            value?.marketName === "MCX OPT"
          ) {
            setStrikePriceOptions([]);
          }
        }
      })
      .finally(() => {
        setIsScriptListFetching(false);
      });
  };

  const getExpiryDateList = async (obj) => {
    // console.log("marketName", value?.marketName);
    setIsExpiryDateListFetching(true);
    await APICall("post", EndPoints.getExpiryDateList, obj)
      .then((response) => {
        if (response.status === 200 && response.data) {
          // console.log("Expairy", response.data);

          if (value.marketName !== "NSE EQU" && value.marketName !== "FOREX") {
            const expiryDateOptions = response.data?.data?.finalData?.map(
              (item, index) => {
                return {
                  ...item,
                  value: `${item.expiryDate} -${index}`,
                  label: item.expiryDate,
                  key: item.symbolId,
                };
              }
            );
            if (
              value?.marketName === "NSE OPT" ||
              value?.marketName === "MCX OPT"
            ) {
              //   let strikePrice = response.data?.data?.strikePrice?.map(
              //     (item, index) => {
              //       return { label: item, value: item, key: index };
              //     }
              //   );
              //   setStrikePriceOptions(
              //     strikePrice?.sort((a, b) => a?.value - b?.value)
              //   );

              setStrikePriceOptions([]);
            }

            setValue((pre) => ({
              ...pre,
              ...response.data?.data?.finalData[0],
              symbolName: pre?.script,
            }));

            setExpiryDatesList(expiryDateOptions);
          } else if (value.marketName === "FOREX") {
            setValue((pre) => ({
              ...pre,
              lotSize: response.data?.data?.finalData?.[0]?.lotSize,
            }));

            const expiryDateOptions = response.data?.data?.finalData?.map(
              (item, index) => {
                return {
                  ...item,
                  value: `${item.expiryDate} -${index}`,
                  label: item.expiryDate,
                  key: item.symbolId,
                };
              }
            );
            setExpiryDatesList(expiryDateOptions);
          }
        }
      })
      .finally(() => {
        setIsExpiryDateListFetching(false);
      });
  };

  const getStrikePrice = async (obj) => {
    try {
      let response = await APICall("post", EndPoints.getStrikePrice, obj);
      if (response.status === 200) {
        let data = response.data?.data;
        // console.log("strikePrice response", response, data);
        let strikePrice = data?.finalData?.map((item, index) => {
          return {
            label: item?.strikePrice,
            value: item?.strikePrice,
            symbol: item?.symbolId,
            key: index,
          };
        });

        const strikePriceSymbol = strikePrice?.filter(
          (el) => el?.value === data?.nearestStrikePrice
        );
        if (strikePriceSymbol?.length === 0) {
          notification.error({
            message: "Current Strike Price not found in Option list",
          });
        } else {
          setValue((pre) => {
            return {
              ...pre,
              strikePrice: data?.nearestStrikePrice,
              symbol: strikePriceSymbol?.[0]?.symbol,
              symbolId: strikePriceSymbol?.[0]?.symbol,
            };
          });
        }

        setStrikePriceOptions(strikePrice?.sort((a, b) => a?.value - b?.value));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //functions
  const onChangeDropdown = (name, selectedValue, selectedOptions) => {
    const value_option = selectedOptions?.[0];
    // console.log("valueOption", value_option);
    const _value = value_option?.value;

    let obj = {};

    if (name === "marketName") {
      obj = {
        ...value,
        [name]: _value,
        instrument_type: null,
        script: null,
        expiryDate: null,
        option_type: null,
        strikePrice: null,
      };
    } else if (name === "script") {
      if (value?.marketName === "NSE EQU") {
        obj = {
          ...value,
          [name]: _value,
          symbol: `${_value}`,
          symbolId: value_option?.symbolId,
          symbolName: _value,
          instrument_type: null,
          expiryDate: null,
          option_type: null,
          strikePrice: null,
        };
      } else if (
        value?.marketName === "FOREX" ||
        value?.marketName === "COMEX"
      ) {
        obj = {
          ...value,
          [name]: _value,
          symbol: _value,
          symbolId: _value,
          symbolName: _value,
          instrument_type: null,
          expiryDate: null,
          option_type: null,
          strikePrice: null,
        };
      } else if (value?.marketName === "GLOBEX") {
        // console.log("globex", value_option);
        obj = {
          ...value,
          [name]: _value,
          symbol: _value,
          symbolId: _value,
          symbolName: value_option?.label,
          instrument_type: null,
          expiryDate: null,
          option_type: null,
          strikePrice: null,
          lotSize: value_option?.lotSize,
        };
      } else {
        obj = {
          ...value,
          [name]: _value,
          instrument_type: null,
          expiryDate: null,
          option_type: null,
          strikePrice: null,
        };
      }
    } else if (name === "expiryDate") {
      let expairyValue = _value?.split(" ")[0];
      obj = {
        ...value,
        [name]: expairyValue,
        // script: value_option?.symbol,
        symbol: value_option?.symbol,
        symbolId: value_option?.symbolId,
        lotSize: value_option?.lotSize,
        symbolName: value.script,
        option_type: null,
        strikePrice: null,
      };
    } else if (name === "option_type") {
      obj = {
        ...value,
        [name]: _value,
        series: _value,
        // strikePrice: null,
      };
    } else {
      obj = {
        ...value,
        [name]: _value,
        strikePrice: _value,
        symbol: value_option?.symbol,
        symbolId: value_option?.symbol,
      };
    }
    console.log("obj...", obj);

    setValue(obj);
    if (name === "marketName") {
      setExpiryDatesList([]);
      setStrikePriceOptions([]);
      if (_value && SEGMENTS?.[_value]) {
        getScriptList({ marketName: SEGMENTS?.[_value] });
      }
    } else if (name === "script") {
      setExpiryDatesList([]);
      if (
        (show_expiry_date_input && _value && SEGMENTS?.[value?.marketName]) ||
        value?.marketName === "FOREX"
      ) {
        getExpiryDateList({
          marketName: SEGMENTS[value?.marketName],
          scriptName: _value,
        });
        // getSymbolExpiryList({
        //   search: _value,
        //   segment: SEGMENTS[value?.marketName],
        // });
      }
    } else if (name === "option_type") {
      obj = {
        marketName: SEGMENTS[value?.marketName],
        symbolName: value?.script,
        expiryDate: value?.expiryDate,
        series: obj?.series,
      };
      getStrikePrice(obj);
    }
  };

  const calculateOffset = () => {
    switch (value?.marketName) {
      case "NSE OPT":
      case "MCX OPT":
        return 4;
      case "NSE EQU":
        return 16;
      case "FOREX":
        return 5;
      case "NSE FUT":
        return 10;
      case "GLOBEX":
        return 16;
      case "COMEX":
        return 10;
      case "MCX":
        return 7;
      default:
        return 10;
    }
  };
  const offsetForLarge = () => {
    switch (value?.marketName) {
      case "NSE OPT":
      case "MCX OPT":
        return 2;
      case "NSE EQU":
        return 15;
      case "FOREX":
        return 4;
      case "NSE FUT":
        return 7;
      case "GLOBEX":
        return 15;
      case "MCX":
        return 4;
      default:
        return 8;
    }
  };

  const memoizedView = useMemo(() => {
    const dropdownRender = () => {
      const onClick = (_item) => {
        setValue((prev) => ({ ...prev, strikePrice: _item?.value }));
      };

      return (
        <>
          {strike_price_options?.map((item) => {
            return (
              <div
                onClick={() => onClick(item)}
                style={{
                  width: "13rem",
                  maxHeight: "1rem !important",
                  padding: "0.2rem",
                  fontSize: "1rem",
                  cursor: "pointer",
                  paddingInline: "1rem",
                  marginInline: "0.5rem",
                  borderRadius: token.borderRadiusSM,
                  backgroundColor:
                    value.strikePrice === item?.value
                      ? token?.colorPrimaryBgHover
                      : item?.isCloset
                      ? token.blue1
                      : "transparent",
                  // backgroundColor: to ken?.controlItemBgHover,
                }}
              >
                {item?.label}
              </div>
            );
          })}
        </>
      );
    };
    return (
      <StyledRow gutter={[10, 10]}>
        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={24}>
          <ThemeDropDown
            title={"Market"}
            placeholder="Select"
            name="marketName"
            options={marketList}
            width={"98%"}
            showSearch={true}
            $onChange={onChangeDropdown}
            $value={value?.marketName}
            // disabled={isMarketListFetching}
            // suffixIcon={<Spin size="small" spinning={isMarketListFetching} />}
          />
        </Col>
        <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={24}>
          <ThemeDropDown
            title={"Script Name"}
            placeholder="Script Name"
            name="script"
            width={"98%"}
            showSearch={true}
            $onChange={onChangeDropdown}
            $value={value?.script}
            options={scriptList}
            disabled={isScriptListFetching}
            suffixIcon={<Spin size="small" spinning={isScriptListFetching} />}
          />
        </Col>
        {/* {value?.marketName === "COMEX" && (
            <Col xxl={3} xl={3} lg={3} md={5} sm={5}>
              <ThemeDropDown
                title={"Last Trade Date"}
                placeholder="Last Trade Date"
                name="trade"
                width={"98%"}
                showSearch={true}
                // $onChange={onChangeDropdown}
                // $value={value?.script}
                // options={scriptList}
                // disabled={isScriptListFetching}
                // suffixIcon={<Spin size="small" spinning={isScriptListFetching} />}
              />
            </Col>
          )} */}
        {value?.marketName === "FOREX" && (
          <Col xxl={11} xl={11} lg={11}>
            <Paragraph style={{ marginBottom: "0" }}>
              <TextTitle>Description</TextTitle>
              <pre style={{ margin: "0" }}>
                {isExpiryDateListFetching ? (
                  <Skeleton.Input
                    active
                    size="small"
                    prefix={<i className="fas fa-search" />}
                    style={{ width: 200 }}
                    // placeholder="Search"
                  />
                ) : expiryDatesList?.[0]?.description ? (
                  expiryDatesList?.[0]?.description
                ) : (
                  `Select ScriptName for Description`
                )}
                {/* {expiryDatesList?.[0]?.description
                    ? expiryDatesList?.[0]?.description
                    : `Select ScriptName for Description`} */}
              </pre>
            </Paragraph>
          </Col>
        )}
        {show_expiry_date_input ? (
          <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={24}>
            <ThemeDropDown
              title={
                value?.marketName === "COMEX"
                  ? "Last Trade Date"
                  : "Expiry Date"
              }
              name="expiryDate"
              placeholder="Expiry Date"
              width={"98%"}
              showSearch={true}
              options={expiryDatesList}
              $value={value?.expiryDate}
              $onChange={onChangeDropdown}
              disabled={isExpiryDateListFetching}
              suffixIcon={
                <Spin size="small" spinning={isExpiryDateListFetching} />
              }
            />
          </Col>
        ) : null}
        {show_option_input ? (
          <>
            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={24}>
              <ThemeDropDown
                title={"CE / PE"}
                name="option_type"
                placeholder="CE / PE"
                showSearch={true}
                width={"98%"}
                options={options.marketWatch.option_types}
                $value={value?.option_type}
                $onChange={onChangeDropdown}
              />
            </Col>
            <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={24}>
              <ThemeDropDown
                title={"Strike"}
                name="strikePrice"
                placeholder="Select Price"
                showSearch={true}
                width={"98%"}
                $value={value?.strikePrice}
                $onChange={onChangeDropdown}
                options={strike_price_options}
                // dropdownRender={dropdownRender}
              />
            </Col>
          </>
        ) : null}
        <Col
          xxl={{ span: 2, offset: calculateOffset() }}
          xl={{ span: 2, offset: calculateOffset() }}
          lg={{ span: 3, offset: offsetForLarge() }}
          md={{
            span: 6,
            offset:
              value?.marketName === "NSE EQU" || value?.marketName === "GLOBEX"
                ? 6
                : 0,
          }}
          sm={{ span: 12, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
          style={style.headerScriptBtnStyle}
        >
          <AddScriptTable
            value={value}
            setValue={setValue}
            setExpiryDatesList={setExpiryDatesList}
            setStrikePriceOptions={setStrikePriceOptions}
          />
        </Col>
        {show_expiry_date_input || value?.marketName === "COMEX" ? (
          <Col
            xxl={3}
            xl={3}
            lg={4}
            md={6}
            sm={12}
            xs={24}
            style={style.headerScriptBtnStyle}
          >
            <AddAllScript
              value={value}
              scriptList={scriptList}
              flage={true}
              name={"New Expiry"}
            />
          </Col>
        ) : null}
        {value.marketName === "MCX" ? (
          <Col
            xxl={3}
            xl={3}
            lg={4}
            md={6}
            sm={12}
            xs={24}
            style={style.headerScriptBtnStyle}
          >
            <AddAllScript
              value={value}
              scriptList={scriptList}
              flage={false}
              name={"Add All Scripts"}
            />
          </Col>
        ) : null}
      </StyledRow>
    );
  }, [
    strike_price_options,
    value,
    marketList,
    scriptList,
    isScriptListFetching,
    isExpiryDateListFetching,
    data,
  ]);

  return <>{memoizedView}</>;
};

export default React.memo(HeaderView); // HeaderView;

const AddScriptTable = ({
  value,
  setValue,
  setExpiryDatesList,
  setStrikePriceOptions,
}) => {
  const scriptRef = useRef(null);
  // state
  const [addLoading, setAddLoading] = useState(false);

  //function
  const handleClick = () => {
    if (scriptRef.current) {
      scriptRef.current.callCheckScript();
    }
  };

  return (
    <>
      {/* <Col> */}
      <MarketWatchButton
        type="primary"
        icon={<PlusCircleFilled />}
        onClick={handleClick}
        loading={addLoading}
      >
        Add
      </MarketWatchButton>

      <ScriptLogic
        ref={scriptRef}
        value={value}
        setValue={setValue}
        setExpiryDatesList={setExpiryDatesList}
        setStrikePriceOptions={setStrikePriceOptions}
        setAddLoading={setAddLoading}
      />
      {/* </Col> */}
    </>
  );
};
