import {
  DashboardOutlined,
  LineChartOutlined,
  FileTextOutlined,
  StockOutlined,
  UserOutlined,
  ToolOutlined,
  BankOutlined,
  PieChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import store from "../../store/store";

const allowCreateAccount =
  store.getState()?.authSlice?.additionalSharing?.newClientAdd;
const allowAccountPoint =
  store?.getState()?.authSlice?.additionalSharing?.accountPointEntry;

const Items = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    label: "Market Watch",
    to: "/market",
    icon: <LineChartOutlined />,
  },
  {
    label: "Summary Report",
    to: "/report",
    icon: <FileTextOutlined />,
  },
  {
    label: "Trading",
    to: "/trading",
    icon: <StockOutlined />,
    subMenu: [
      {
        to: "/trading/trade",
        key: "/trading/trade",
        label: "Trade",
      },

      { label: "Position", to: "/trading/position", key: "/trading/position" },
      // {
      //   label: "Global Summary",
      //   to: "/trading/global-summary",
      //   key: "/trading/global-summary",
      // },
      {
        label: "Blocked Scripts",
        to: "/trading/blocked-scripts",
        key: "/trading/blocked-scripts",
      },
    ],
  },
  {
    label: "Users",
    to: "/users",
    icon: <UserOutlined />,
    subMenu: [
      {
        label: "Accounts",
        to: "/users/accounts/:type?",
        key: "/users/accounts",
      },
      {
        label: "New Account",
        to: "/users/new-account",
        key: "/users/new-account",
      },
      {
        label: "New Rent Account",
        to: "/users/create-rent-account",
        key: "/users/create-rent-account",
      },
    ],
  },
  {
    label: "Utilities",
    to: "/utilities",
    icon: <ToolOutlined />,
    subMenu: [
      {
        label: "Trade Log",
        to: "/utilities/trade-log",
        key: "/utilities/trade-log",
      },
      {
        label: "User Edit Log",
        to: "/utilities/user-edit-log",
        key: "/utilities/user-edit-log",
      },
      {
        label: "Point Ledger Log",
        to: "/utilities/point-ledger-log",
        key: "/utilities/point-ledger-log",
      },
      // {
      //   label: "Cash Ledger Log",
      //   to: "/utilities/cash-ledger-log",
      //   key: "/utilities/cash-ledger-log",
      // },
      {
        label: "Rejection Log",
        to: "/utilities/rejection-log",
        key: "/utilities/rejection-log",
      },
      {
        label: "M2mAlert Log",
        to: "/utilities/M2mAlert-Log",
        key: "/utilities/M2mAlert-Log",
      },
      {
        label: "Bulk Trading",
        to: "/utilities/bulk-trading",
        key: "/utilities/bulk-trading",
      },
    ],
  },
  {
    label: "Accounts",
    to: "/accounts",
    icon: <BankOutlined />,
    subMenu: [
      {
        label: "Point Ledger",
        to: "/account-point-ledger",
        key: "/account-point-ledger",
      },
      {
        label: "Point Entry",
        to: "/account-point-entry",
        key: "/account-point-entry",
      },
      {
        label: "Point JV Entry",
        to: "/account-point-jv-entry",
        key: "/account-point-jv-entry",
      },
    ],
  },
  {
    label: "Reports",
    to: "/reports",
    icon: <PieChartOutlined />,
    subMenu: [
      {
        label: "Brokerage Report",
        to: "/reports/brokerage-report",
        key: "/reports/brokerage-report",
      },
      // {
      //   label: "Trade Report",
      //   to: "/reports/trade-report",
      //   key: "/reports/trade-report",
      // },
      {
        label: "Profit&Loss percentage",
        to: "/reports/profit-and-loss-percentage",
        key: "/reports/profit-and-loss-percentage",
      },
    ],
  },
  {
    label: "Settings",
    to: "/settings",
    icon: <SettingOutlined />,
    subMenu: [
      // {
      //   label: "Script Block-Allow",
      //   to: "/settings/script-block-allow",
      //   key: "/settings/script-block-allow",
      // },
      // {
      //   label: "Notification",
      //   to: "/settings/notification",
      //   key: "/settings/notification",
      // },
      // {
      //   label: "Valan Setting",
      //   to: "/settings/valan-setting",
      //   key: "/settings/valan-setting",
      // },
      {
        label: "Expiry Setting",
        to: "/settings/expiry-setting",
        key: "/settings/expiry-setting",
      },
      {
        label: "Market Setting",
        to: "/settings/market-setting",
        key: "/settings/market-setting",
      },
    ],
  },
];

export default Items;

export const UserItems = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    label: "Market Watch",
    to: "/market",
    icon: <LineChartOutlined />,
  },
  {
    label: "Summary Report",
    to: "/report",
    icon: <FileTextOutlined />,
  },
  {
    label: "Trading",
    to: "/trading",
    icon: <StockOutlined />,
    subMenu: [
      {
        to: "/trading/trade",
        key: "/trading/trade",
        label: "Trade",
      },

      { label: "Position", to: "/trading/position", key: "/trading/position" },
      // {
      //   label: "Global Summary",
      //   to: "/trading/global-summary",
      //   key: "/trading/global-summary",
      // },
      {
        label: "Blocked Scripts",
        to: "/trading/blocked-scripts",
        key: "/trading/blocked-scripts",
      },
    ],
  },
  {
    label: "Users",
    to: "/users",
    icon: <UserOutlined />,
    subMenu: [
      {
        label: "Accounts",
        to: "/users/accounts/:type?",
        key: "/users/accounts",
      },

      {
        label: "New Account",
        to: "/users/new-account",
        key: "/users/new-account",
      },

      {
        label: "New Rent Account",
        to: "/users/create-rent-account",
        key: "/users/create-rent-account",
      },
    ],
  },
  {
    label: "Utilities",
    to: "/utilities",
    icon: <ToolOutlined />,
    subMenu: [
      {
        label: "Trade Log",
        to: "/utilities/trade-log",
        key: "/utilities/trade-log",
      },
      {
        label: "User Edit Log",
        to: "/utilities/user-edit-log",
        key: "/utilities/user-edit-log",
      },
      {
        label: "Point Ledger Log",
        to: "/utilities/point-ledger-log",
        key: "/utilities/point-ledger-log",
      },
      // {
      //   label: "Cash Ledger Log",
      //   to: "/utilities/cash-ledger-log",
      //   key: "/utilities/cash-ledger-log",
      // },
      {
        label: "Rejection Log",
        to: "/utilities/rejection-log",
        key: "/utilities/rejection-log",
      },
      {
        label: "M2mAlert Log",
        to: "/utilities/M2mAlert-Log",
        key: "/utilities/M2mAlert-Log",
      },
      {
        label: "Bulk Trading",
        to: "/utilities/bulk-trading",
        key: "/utilities/bulk-trading",
      },
    ],
  },
  {
    label: "Accounts",
    to: "/accounts",
    icon: <BankOutlined />,
    subMenu: [
      {
        label: "Point Ledger",
        to: "/account-point-ledger",
        key: "/account-point-ledger",
      },
      {
        label: "Point Entry",
        to: "/account-point-entry",
        key: "/account-point-entry",
      },
      {
        label: "Point JV Entry",
        to: "/account-point-jv-entry",
        key: "/account-point-jv-entry",
      },
    ],
  },
  {
    label: "Reports",
    to: "/reports",
    icon: <PieChartOutlined />,
    subMenu: [
      {
        label: "Brokerage Report",
        to: "/reports/brokerage-report",
        key: "/reports/brokerage-report",
      },
      {
        label: "Broker Sharing Report",
        to: "/reports/broker-sharing-report",
        key: "/reports/broker-sharing-report",
      },
      // {
      //   label: "Trade Report",
      //   to: "/reports/trade-report",
      //   key: "/reports/trade-report",
      // },
      {
        label: "Profit&Loss percentage",
        to: "/reports/profit-and-loss-percentage",
        key: "/reports/profit-and-loss-percentage",
      },
    ],
  },
];

export const DealerItems = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    label: "Market Watch",
    to: "/market",
    icon: <LineChartOutlined />,
  },
  {
    label: "Summary Report",
    to: "/report",
    icon: <FileTextOutlined />,
  },
  {
    label: "Trading",
    to: "/trading",
    icon: <StockOutlined />,
    subMenu: [
      {
        to: "/trading/trade",
        key: "/trading/trade",
        label: "Trade",
      },

      { label: "Position", to: "/trading/position", key: "/trading/position" },
      // {
      //   label: "Global Summary",
      //   to: "/trading/global-summary",
      //   key: "/trading/global-summary",
      // },
      {
        label: "Blocked Scripts",
        to: "/trading/blocked-scripts",
        key: "/trading/blocked-scripts",
      },
    ],
  },
  {
    label: "Users",
    to: "/users",
    icon: <UserOutlined />,
    subMenu: [
      {
        label: "Accounts",
        to: "/users/accounts/:type?",
        key: "/users/accounts",
      },

      {
        label: "New Account",
        to: "/users/new-account",
        key: "/users/new-account",
      },
    ],
  },
  {
    label: "Utilities",
    to: "/utilities",
    icon: <ToolOutlined />,
    subMenu: [
      {
        label: "Trade Log",
        to: "/utilities/trade-log",
        key: "/utilities/trade-log",
      },
      {
        label: "User Edit Log",
        to: "/utilities/user-edit-log",
        key: "/utilities/user-edit-log",
      },
      {
        label: "Point Ledger Log",
        to: "/utilities/point-ledger-log",
        key: "/utilities/point-ledger-log",
      },
      // {
      //   label: "Cash Ledger Log",
      //   to: "/utilities/cash-ledger-log",
      //   key: "/utilities/cash-ledger-log",
      // },
      {
        label: "Rejection Log",
        to: "/utilities/rejection-log",
        key: "/utilities/rejection-log",
      },
      {
        label: "M2mAlert Log",
        to: "/utilities/M2mAlert-Log",
        key: "/utilities/M2mAlert-Log",
      },
      {
        label: "Bulk Trading",
        to: "/utilities/bulk-trading",
        key: "/utilities/bulk-trading",
      },
    ],
  },
  {
    label: "Accounts",
    to: "/accounts",
    icon: <BankOutlined />,
    subMenu: [
      {
        label: "Point Ledger",
        to: "/account-point-ledger",
        key: "/account-point-ledger",
      },
      {
        label: "Point Entry",
        to: "/account-point-entry",
        key: "/account-point-entry",
      },
      {
        label: "Point JV Entry",
        to: "/account-point-jv-entry",
        key: "/account-point-jv-entry",
      },
    ],
  },
  {
    label: "Reports",
    to: "/reports",
    icon: <PieChartOutlined />,
    subMenu: [
      {
        label: "Brokerage Report",
        to: "/reports/brokerage-report",
        key: "/reports/brokerage-report",
      },
      {
        label: "Broker Sharing Report",
        to: "/reports/broker-sharing-report",
        key: "/reports/broker-sharing-report",
      },
      {
        label: "Profit&Loss percentage",
        to: "/reports/profit-and-loss-percentage",
        key: "/reports/profit-and-loss-percentage",
      },
    ],
  },
];

export const BrokerItems = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    label: "Market Watch",
    to: "/market",
    icon: <LineChartOutlined />,
  },
  {
    label: "Summary Report",
    to: "/report",
    icon: <FileTextOutlined />,
  },
  {
    label: "Trading",
    to: "/trading",
    icon: <StockOutlined />,
    subMenu: [
      {
        to: "/trading/trade",
        key: "/trading/trade",
        label: "Trade",
      },

      { label: "Position", to: "/trading/position", key: "/trading/position" },

      {
        label: "Blocked Scripts",
        to: "/trading/blocked-scripts",
        key: "/trading/blocked-scripts",
      },
    ],
  },
  {
    label: "Users",
    to: "/users",
    icon: <UserOutlined />,
    subMenu: [
      {
        label: "Accounts",
        to: "/users/accounts/:type?",
        key: "/users/accounts",
      },
    ],
  },
  {
    label: "Accounts",
    to: "/accounts",
    icon: <BankOutlined />,
    subMenu: [
      {
        label: "Point Ledger",
        to: "/account-point-ledger",
        key: "/account-point-ledger",
      },
    ],
  },
  {
    label: "Reports",
    to: "/reports",
    icon: <PieChartOutlined />,
    subMenu: [
      {
        label: "Brokerage Report",
        to: "/reports/brokerage-report",
        key: "/reports/brokerage-report",
      },
      {
        label: "Broker Sharing Report",
        to: "/reports/broker-sharing-report",
        key: "/reports/broker-sharing-report",
      },
    ],
  },
];

export const CustomerItems = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: <DashboardOutlined />,
  },
  {
    label: "Market Watch",
    to: "/market",
    icon: <LineChartOutlined />,
  },
  {
    label: "Summary Report",
    to: "/report",
    icon: <FileTextOutlined />,
  },
  {
    label: "Trading",
    to: "/trading",
    icon: <StockOutlined />,
    subMenu: [
      {
        to: "/trading/trade",
        key: "/trading/trade",
        label: "Trade",
      },

      { label: "Position", to: "/trading/position", key: "/trading/position" },

      {
        label: "Blocked Scripts",
        to: "/trading/blocked-scripts",
        key: "/trading/blocked-scripts",
      },
    ],
  },
  {
    label: "Utilities",
    to: "/utilities",
    icon: <ToolOutlined />,
    subMenu: [
      {
        label: "Rejection Log",
        to: "/utilities/rejection-log",
        key: "/utilities/rejection-log",
      },
    ],
  },
  {
    label: "Accounts",
    to: "/accounts",
    icon: <BankOutlined />,
    subMenu: [
      {
        label: "Point Ledger",
        to: "/account-point-ledger",
        key: "/account-point-ledger",
      },
    ],
  },
];

// const Items = [
//   {
//     label: "Dashboard",
//     to: "/dashboard",
//     icon: <DashboardOutlined />,
//   },
//   {
//     label: "Market Watch",
//     to: "/market",
//     icon: <LineChartOutlined />,
//   },
//   {
//     label: "Summary Report",
//     to: "/report",
//     icon: <FileTextOutlined />,
//   },
//   {
//     label: "Trading",
//     to: "/trading",
//     icon: <StockOutlined />,
//     subMenu: [
//       {
//         to: "/trading/trade",
//         key: "/trading/trade",
//         label: "Trade",
//       },
//       {
//         label: "Position",
//         to: "/trading/position",
//         key: "/trading/position",
//       },
//       {
//         label: "Blocked Scripts",
//         to: "/trading/blocked-scripts",
//         key: "/trading/blocked-scripts",
//       },
//     ],
//   },
//   accountType !== "customer" && {
//     label: "Users",
//     to: "/users",
//     icon: <UserOutlined />,
//     subMenu: [
//       { label: "Accounts", to: "/users/accounts", key: "/users/accounts" },
//       accountType !== "broker" && {
//         label: "New Account",
//         to: "/users/new-account",
//         key: "/users/new-account",
//       },
//     ],
//   },
//   accountType !== "broker" && {
//     label: "Utilities",
//     to: "/utilities",
//     icon: <ToolOutlined />,
//     subMenu: [
//       accountType !== "customer" && {
//         label: "Trade Log",
//         to: "/utilities/trade-log",
//         key: "/utilities/trade-log",
//       },
//       accountType !== "customer" && {
//         label: "User Edit Log",
//         to: "/utilities/user-edit-log",
//         key: "/utilities/user-edit-log",
//       },
//       accountType !== "customer" && {
//         label: "Point Ledger Log",
//         to: "/utilities/point-ledger-log",
//         key: "/utilities/point-ledger-log",
//       },
//       {
//         label: "Rejection Log",
//         to: "/utilities/rejection-log",
//         key: "/utilities/rejection-log",
//       },
//       accountType !== "customer" && {
//         label: "M2mAlert Log",
//         to: "/utilities/M2mAlert-Log",
//         key: "/utilities/M2mAlert-Log",
//       },
//       accountType !== "customer" && {
//         label: "Bulk Trading",
//         to: "/utilities/bulk-trading",
//         key: "/utilities/bulk-trading",
//       },
//     ],
//   },
//   {
//     label: "Accounts",
//     to: "/accounts",
//     icon: <BankOutlined />,
//     subMenu: [
//       {
//         label: "Point Ledger",
//         to: "/account-point-ledger",
//         key: "/account-point-ledger",
//       },
//       accountType !== "broker" &&
//         accountType !== "customer" && {
//           label: "Point Entry",
//           to: "/account-point-entry",
//           key: "/account-point-entry",
//         },
//       accountType !== "broker" &&
//         accountType !== "customer" && {
//           label: "Point JV Entry",
//           to: "/account-point-jv-entry",
//           key: "/account-point-jv-entry",
//         },
//     ],
//   },
//   accountType !== "customer" && {
//     label: "Reports",
//     to: "/reports",
//     icon: <PieChartOutlined />,
//     subMenu: [
//       {
//         label: "Brokerage Report",
//         to: "/reports/brokerage-report",
//         key: "/reports/brokerage-report",
//       },
//       accountType !== "broker" && {
//         label: "Profit&Loss percentage",
//         to: "/reports/profit-and-loss-percentage",
//         key: "/reports/profit-and-loss-percentage",
//       },
//     ],
//   },
//   accountType === "owner" && {
//     label: "Settings",
//     to: "/settings",
//     icon: <SettingOutlined />,
//     subMenu: [
//       {
//         label: "Expiry Setting",
//         to: "/settings/expiry-setting",
//         key: "/settings/expiry-setting",
//       },
//       {
//         label: "Market Setting",
//         to: "/settings/market-setting",
//         key: "/settings/market-setting",
//       },
//     ],
//   },
// ];
