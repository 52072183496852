import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  notification,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  Tag,
  theme,
  Tooltip,
} from "antd";
import { styled } from "styled-components";
import dayjs from "dayjs";
import closeImage from "../../../assets/icons/closeIcon.svg";
import {
  setMarketWatchData,
  setWatchListArray,
} from "../../../store/slice/marketWatchSlice";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import ModalView from "../TradeModal";
import { QuestionCircleOutlined } from "@ant-design/icons";
import APICall from "../../../APICall";
import EndPoints from "../../../APICall/EndPoints";
import {
  M2MCalculation,
  MemoizedCardItemPrice,
  MemorizedHighLowPrice,
  MemorizedOpenClose,
  MemorizedPercentageAero,
  MemorizedPriceChange,
} from "../../../components/SocketLiveData";
import store from "../../../store/store";
import { marketTag } from "../../../Helper/constant";
import { useSocket } from "../../../components/Socket";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const StyledTheme = {
  Root: styled.div`
    width: 100%;
    height: fit-content;
    background-color: ${(props) => props.theme.token.colorPrimary};
    border-radius: ${(props) => props.theme.token.borderRadiusLG}px;
    margin-bottom: 1.5rem;
    /* overflow-x: auto; */
  `,
  Table: styled(Table)`
    transform: none !important;

    & .ant-table-thead tr .ant-table-cell {
      background-color: ${(props) => props.theme.token.colorPrimary};
      color: ${(props) => props.theme.token.colorTextLightSolid};
      font-size: 0.9rem;
      font-weight: 500;
    }
    @media screen and (max-width: 1079px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.6rem;
      }
      .ant-table-body .ant-table-cell {
        font-size: 0.75rem;
      }
    }
    @media screen and (max-width: 991px) {
      .ant-table-thead tr .ant-table-cell {
        font-size: 0.65rem;
      }
      .ant-table-body .ant-table-cell {
        font-size: 0.65rem;
      }
    }

    .ant-table-thead tr .ant-table-cell::before {
      display: none;
    }

    .ant-table-thead tr .ant-table-cell .ant-table-column-has-sorters {
      border-top: ${(props) =>
        `1px solid ${props.theme.token.colorBorder} !important`};
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 0.5rem !important;
    }
    .ant-table-thead tr th {
      height: 2rem !important;
      padding: 0rem 0.5rem !important;
    }
    .collapseTable .ant-collapse-expand-icon span svg {
      fill: ${(props) => props.theme.token.colorTextLightSolid} !important;
    }
    .collapseTable .ant-collapse-header-text {
      color: ${(props) => props.theme.token.colorTextLightSolid};
    }

    .ant-switch-checked .ant-switch-inner {
      background-color: ${(props) =>
        props.theme.token.colorBgActionButton} !important;
    }

    .ant-table-thead {
      height: 3.18rem;
    }
    .ant-table-thead .ant-table-cell {
      &:hover {
        background: ${(props) =>
          props.theme.token.colorPrimaryBgHover} !important;
      }
    }
    .ant-table-body .ant-table-cell {
      padding: 0.5rem !important;
    }
  `,
};
const CloseImage = styled.img`
  width: 25px;
  @media screen and (max-width: 1079px) {
    width: 20px;
  }
`;

const SimpleTable = React.memo((props) => {
  let record = useRef();
  let modalType = useRef();
  const activeScriptRef = useRef({});
  const dispatch = useDispatch();
  const token = theme.useToken().token;

  //redux
  // const isLoading = useSelector(
  //   (state) => state.marketWatchSlice?.marketWatchLoading
  // );
  // const data = useSelector(
  //   (state) => state.marketWatchSlice?.data?.[props?.title]
  // );
  const selectedMarket = useSelector(
    (state) => state.marketWatchSlice?.seletctedMaketIndex
  );

  const watchList = useSelector(
    (state) =>
      state?.marketWatchSlice?.watchListArray?.[selectedMarket]?.scriptList
  );

  const accountType = useSelector(
    (state) => state.authSlice?.userDetail?.accountType
  );
  const iscustomer = accountType === "customer";
  const isBroker = accountType === "broker";

  //state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableData, setTableData] = useState(props?.watchListData);
  const socketContext = useSocket();
  const { doSend } = socketContext;
  const [deleteLoading, setDeteleLoading] = useState({
    load: false,
    index: "",
  });

  useEffect(() => {
    setTableData(props?.watchListData);
  }, [props?.selectedMarket, props?.watchListData]);

  // api call
  const handleRemoveItem = async (item, index) => {
    setDeteleLoading({ load: true, index: index });
    try {
      let response = await APICall(
        "post",
        `${EndPoints.deleteScript}scriptId=${item._id}`
      );
      if (response.status === 200) {
        notification.success({ message: response.data.message });
        // dispatch(
        //   setMarketWatchData({
        //     type: "delete",
        //     nested_key: "data",
        //     key: props?.title,
        //     payload: item?._id,
        //   })
        // );
        dispatch(
          setWatchListArray({
            type: "deleteScript",
            id: item?._id,
            index: selectedMarket,
          })
        );
        setDeteleLoading({ load: false, index: "" });
        doSend({
          event: "unsubscribeToServerMarket",
          payload: [item?.symbol],
        });
      } else {
        notification.error({ message: "Something want wrong" });
        setDeteleLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setDeteleLoading({ load: false, index: "" });
    }
  };

  const handleRemoveAllScript = async () => {
    let watchlistData =
      store.getState()?.marketWatchSlice?.watchListArray?.[selectedMarket];
    let filteredScript = watchlistData?.scriptList?.map((el) => el?.symbol);
    try {
      let response = await APICall(
        "post",
        `${EndPoints.deleteScript}watchListId=${watchlistData?._id}`
      );
      if (response.status === 200) {
        // dispatch(
        //   setMarketWatchData({
        //     type: "delete_all",
        //     nested_key: "data",
        //     key: props?.title,
        //   })
        // );

        notification.success({ message: response.data.message });
        dispatch(
          setWatchListArray({ type: "deleteAllScript", index: selectedMarket })
        );
        doSend({
          event: "unsubscribeToServerMarket",
          payload: filteredScript,
        });
      } else {
        notification.error({ message: response.data.message });
      }
    } catch (error) {
      console.log("error", error);
      notification.error({ message: "Something want wrong" });
    }
  };

  //functions
  const showModal = (item) => {
    record.current = item;
    if (!isBroker) {
      setIsModalOpen(true);
    }
  };

  const onClose = (item) => {
    setIsModalOpen(false);
  };

  const ActiveScriptDot = ({ record, Exchange, _lastUpdatedDate }) => {
    const [isScriptActive, setIsScriptActive] = useState(true);

    const time = MemorizedTime(record);
    // console.log("🚀 ~ ActiveScriptDot ~ time:", time);
    const timePart = time?.split("T");
    const givenDate = new Date(time);
    const differnce = Date.now() - givenDate.getTime();
    const inputDateString = record?.expiryDate;

    let formattedDate = "";
    let _lastUpdatedText = `Last Updated ${timePart} `;

    let option = false;
    let showExpiry = true;
    if (
      record?.marketName === "FOREX" ||
      record?.marketName === "GLOBEX" ||
      record?.marketName === "NSE EQU"
    ) {
      showExpiry = false;
    }
    if (record?.marketName === "NSE OPT" || record?.marketName === "MCX OPT") {
      option = true;
    }

    // life cycle
    useEffect(() => {
      if (differnce > 1000 * 60 * 5) {
        setIsScriptActive(false);
        activeScriptRef.current[record?.symbol] = false;
        // activeScriptRef.current = {
        //   ...activeScriptRef.current,
        //   [record?.symbol]: false,
        // };
      } else {
        setIsScriptActive(true);
        activeScriptRef.current[record?.symbol] = true;
        // activeScriptRef.current = {
        //   ...activeScriptRef.current,
        //   [record?.symbol]: true,
        // };
      }
    }, [record, differnce]);

    if (inputDateString) {
      const [day, month, year] = inputDateString?.split("-").map(Number);
      const dateObject = new Date(year, month - 1, day);
      formattedDate = dateObject.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      formattedDate = formattedDate.replace(/\s/g, "").toUpperCase();
    }

    return (
      <div>
        <Tooltip placement="top" title={_lastUpdatedText} arrow>
          <div
            style={{
              // display: "flex",
              alignItems: "center",
              fontWeight: 600,
              lineHeight: "1.4",
            }}
          >
            <Row justify={"left"}>
              <Col style={{ fontSize: "1rem" }}>
                {`${record?.symbolName || ""} ${
                  (option && record?.strikePrice) || ""
                }  ${(option && record?.series) || ""} 
                `}
              </Col>
              <Col
                style={{
                  width: "6px",
                  height: "6px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "0.5rem",
                  marginTop: "0.5rem",
                  backgroundColor: isScriptActive ? token.green : token.red,
                }}
              ></Col>
            </Row>
            <Row
              style={{
                fontSize: "0.8rem",
                fontWeight: "550",
              }}
            >
              {`
                ${showExpiry && record?.expiryDate ? formattedDate : ""}`}
            </Row>
          </div>
        </Tooltip>
      </div>
    );
  };
  let call = true;
  const handleSort = (a, b, c, type) => {
    if (call) {
      call = false;
      const storeData = store.getState()?.webSocketSlice?.webSocket?.data;

      const WatchlistModifiy = watchList?.map((el) => {
        return { ...el, change: storeData?.[el?.symbol]?.[type] || 0 };
      });

      const sortedWatchList = WatchlistModifiy?.sort((a, b) => {
        if (type === "symbolName" || type === "marketName") {
          // Sort by scriptName if the type is 'scriptName'
          return c === "ascend"
            ? a[type].localeCompare(b[type])
            : b[type].localeCompare(a[type]);
        } else {
          // Sort by change for numeric sorting
          return c === "ascend" ? b.change - a.change : a.change - b.change;
        }
      });

      dispatch(
        setWatchListArray({
          type: "sortedWatchList",
          index: selectedMarket,
          sortedList: sortedWatchList,
        })
      );

      setTimeout(() => {
        call = true;
      }, 1000);
    }
  };

  // column
  const columns = useMemo(() => {
    let array = [
      {
        fixed: "left",
        title: (
          <Col
            style={{
              cursor: "pointer",
              color: token.green5,
              fontWeight: 700,
              fontSize: "1rem",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {props?.title}
          </Col>
        ),
        dataIndex: "InstrumentIdentifier",
        // sorter: {
        //   compare: (a, b) => a?.symbolName?.localeCompare(b?.symbolName),
        // },
        sorter: (a, b, c) => handleSort(a, b, c, "symbolName"),
        align: "left",
        width: "14rem",
        render: (text, record) => {
          let option = false;
          if (
            record?.marketName === "NSE OPT" ||
            record?.marketName === "MCX OPT"
          ) {
            option = true;
          }
          let _lastUpdatedDate = null;
          let formattedDate = "";

          const inputDateString = record?.expiryDate;
          if (inputDateString) {
            formattedDate = convertTime(inputDateString);
          }

          return (
            <ActiveScriptDot
              record={record}
              Exchange={record?.Exchange}
              _lastUpdatedDate={_lastUpdatedDate}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "Change",
        align: "center",
        width: "5rem",
        // sorter: {
        //   compare: (a, b) => a?.marketName?.localeCompare(b?.marketName),
        // },
        sorter: (a, b, c) => handleSort(a, b, c, "marketName"),
        render(text, record) {
          return (
            <div style={{ display: "flex" }}>
              <MemorizedPercentageAero
                item={record}
                flage={
                  record?.marketName === "FOREX" ||
                  record?.marketName === "GLOBEX"
                    ? true
                    : false
                }
              />
              <Space size={[0, "small"]} wrap style={{ marginLeft: "auto" }}>
                <Tooltip title={record?.marketName} placement={"leftTop"}>
                  <Tag
                    color="magenta"
                    bordered={false}
                    style={{
                      fontSize: "0.9rem",
                      marginLeft: "0.5rem",
                      fontWeight: 500,
                    }}
                  >
                    {marketTag[record?.marketName]}
                  </Tag>
                </Tooltip>
              </Space>
            </div>
          );
        },
      },
      {
        title: "SELL RATE",
        dataIndex: "SellPrice",
        align: "center",
        // sorter: {
        //   compare: (a, b) => a.SellPrice - b.SellPrice,
        //   multiple: 2,
        // },
        // width: "90px",
        render(text, record) {
          const onClickCell = () => {
            modalType.current = "SellPrice";
            showModal(record);
          };

          return (
            <MemoizedCardItemPrice
              item={record}
              priceType={"Ask"}
              onClickCell={onClickCell}
            />
          );
        },
      },
      {
        title: "BUY RATE",
        dataIndex: "BuyPrice",
        text: "BuyPrice",
        align: "center",
        // sorter: {
        //   compare: (a, b) => a.BuyPrice - b.BuyPrice,
        //   multiple: 2,
        // },
        // width: "90px",
        render(text, record) {
          const onClickCell = () => {
            modalType.current = "BuyPrice";
            showModal(record);
          };
          return (
            <MemoizedCardItemPrice
              item={record}
              priceType={"Bid"}
              onClickCell={onClickCell}
            />
          );
        },
      },
      {
        title: <div style={{ padding: "0px 18px" }}>{"LTP"}</div>,
        dataIndex: "LastTradePrice",
        align: "center",
        // width: "90px",
        render(text, record) {
          return <MemoizedCardItemPrice item={record} priceType={"LTP"} />;
        },
      },
      {
        title: "CHANGE(%)",
        dataIndex: "PriceChangePercentage",
        align: "center",
        sorter: (a, b, c) => handleSort(a, b, c, "Price_Change"),
        // width: "90px",
        render(text, record) {
          return (
            <MemorizedPercentageAero
              item={record}
              number={true}
              flage={
                record?.marketName === "FOREX" ||
                record?.marketName === "GLOBEX"
                  ? true
                  : false
              }
            />
          );
        },
      },
      {
        title: "(₹)",
        dataIndex: "PriceChange",
        align: "right",
        sorter: (a, b, c) => handleSort(a, b, c, "Net_Change"),
        // width: "90px",
        render(text, record) {
          return (
            <div>
              <MemorizedPriceChange item={record} />
            </div>
          );
        },
      },
      {
        title: "HIGH",
        dataIndex: "High",
        align: "right",
        // width: "90px",
        render(text, record, index) {
          return (
            <MemorizedHighLowPrice
              item={record}
              priceType={"High"}
              index={index}
            />
          );
        },
      },
      {
        title: "LOW",
        dataIndex: "Low",
        align: "right",
        // width: "90px",
        render(text, record, index) {
          return (
            <MemorizedHighLowPrice
              item={record}
              priceType={"Low"}
              index={index}
            />
          );
        },
      },
      // props.title !== "FOREX" &&
      {
        title: "OPEN",
        dataIndex: "Open",
        align: "right",
        className: `open-cell`,
        sorter: (a, b, c) => handleSort(a, b, c, "Open"),
        // width: "90px",
        render(text, record) {
          return <MemorizedOpenClose item={record} priceType={"Open"} />;
        },
      },
      {
        title: "CLOSE",
        dataIndex: "Close",
        align: "right",
        // width: "90px",
        render(text, record) {
          return <MemorizedOpenClose item={record} priceType={"Close"} />;
        },
      },
      {
        title: "POSITION",
        dataIndex: "position",
        align: "right",
        // width: "90px",
        render: (text, record) => {
          let position = record?.positions;

          let totalBuy = 0;
          let totalSell = 0;
          position?.forEach((item) => {
            if (item?.type === "buy") {
              totalBuy += item?.totalQuantity;
            }
          });
          position?.forEach((item) => {
            if (item?.type === "sell") {
              totalSell += -item?.totalQuantity;
            }
          });

          // let totalSell = position?.reduce((acc, item) => {
          //   return acc + (item?.totalSellQuantity || 0);
          // }, 0);

          // console.log("Buy sum:", totalBuy);
          // console.log("Sell", totalSell);

          const formatQuantity = (total) => {
            if (total >= 1000) {
              return `${Math.floor(total / 1000)?.toFixed(2)} K`;
            } else if (total >= 100000) {
              return `${Math.floor(total / 100000)?.toFixed(2)} Lacs`;
            } else if (total >= 10000000) {
              return `${Math.floor(total / 10000000)?.toFixed(2)} Cr`;
            } else {
              return total?.toFixed(2);
            }
          };
          return (
            <div>
              <div>B +{formatQuantity(totalBuy)} </div>
              <div>S -{formatQuantity(totalSell)}</div>
            </div>
          );
        },
      },
      !isBroker &&
        !iscustomer && {
          title: "M2M BUY",
          dataIndex: "buy",
          key: "buy",
          align: "right",
          // width: "90px",
          render: (text, record) => {
            const position = record?.positions;
            return (
              <div>
                {position ? (
                  <M2MCalculation item={record} flag="buy" />
                ) : (
                  "0.00"
                )}
              </div>
            );
          },
        },
      !isBroker &&
        !iscustomer && {
          title: "M2M SELL",
          dataIndex: "sell",
          key: "sell",
          align: "right",
          // width: "90px",
          render: (text, record) => {
            const position = record?.positions;
            return (
              <div>
                {position ? (
                  <M2MCalculation item={record} flag="sell" />
                ) : (
                  "0.00"
                )}
              </div>
            );
          },
        },
      {
        title: (
          <Popconfirm
            title="Delete the all script"
            placement="rightTop"
            description="Are you sure want to delete all script?"
            onConfirm={handleRemoveAllScript}
            icon={
              <QuestionCircleOutlined
                style={{
                  color: "red",
                }}
              />
            }
          >
            <div
              style={{
                cursor: "pointer",
                color: token.colorBgBase,
                fontWeight: 600,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                WebkitTextStroke: "0.5px red",
              }}
            >
              REMOVE ALL
            </div>
          </Popconfirm>
        ),
        dataIndex: "remove",
        align: "center",
        render: (imageUrl, item, index) => {
          return (
            <div
              onClick={() => handleRemoveItem(item, index)}
              style={{
                cursor: "pointer",
              }}
            >
              {deleteLoading.load === true && deleteLoading.index === index ? (
                <Spin size="small" />
              ) : (
                <CloseImage src={closeImage} alt="Product" />
              )}
            </div>
          );
        },
      },
    ];
    return array.filter((o1) => o1);
  }, [tableData, token, deleteLoading, props.selectedMarket]);

  // loading column
  const loadingColumn = useMemo(() => {
    let array = [
      {
        title: (
          <Col
            style={{
              cursor: "pointer",
              color: token.green5,
              fontWeight: 700,
              fontSize: "1rem",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {props?.title}
          </Col>
        ),
        dataIndex: "InstrumentIdentifier",
        align: "left",
        width: "14rem",
      },
      {
        title: "",
        dataIndex: "Change",
        align: "center",
        width: "3rem",
      },
      {
        title: "SELL RATE",
        dataIndex: "SellPrice",
        align: "center",
      },
      {
        title: "BUY RATE",
        dataIndex: "BuyPrice",
        text: "BuyPrice",
        align: "center",
      },

      {
        title: (
          <div style={{ padding: "0px 18px" }}>
            {props.title === "FOREX" || props.title === "GLOBEX"
              ? "SPREAD"
              : "LTP"}
          </div>
        ),
        dataIndex: "LastTradePrice",
        align: "center",
      },
      {
        title: "CHANGE(%)",
        dataIndex: "PriceChangePercentage",
        align: "center",
      },
      {
        title:
          props?.title === "FOREX" || props?.title === "GLOBEX" ? "($)" : "(₹)",
        dataIndex: "PriceChange",
        align: "right",
      },
      {
        title: "HIGH",
        dataIndex: "High",
        align: "right",
      },
      {
        title: "LOW",
        dataIndex: "Low",
        align: "right",
      },
      // props.title !== "FOREX" &&
      {
        title: "OPEN",
        dataIndex: "Open",
        align: "right",
        className: `open-cell`,
      },
      {
        title: "CLOSE",
        dataIndex: "Close",
        align: "right",
      },
      {
        title: "POSITION",
        dataIndex: "position",
        align: "right",
      },
      !isBroker &&
        !iscustomer && {
          title: "M2M BUY",
          dataIndex: "buy",
          key: "buy",
          align: "right",
        },
      !isBroker &&
        !iscustomer && {
          title: "M2M SELL",
          dataIndex: "sell",
          key: "sell",
          align: "right",
        },
      {
        title: (
          <Popconfirm
            title="Delete the all script"
            placement="leftTop"
            description="Are you sure want to delete all script?"
          >
            <div
              style={{
                cursor: "pointer",
                color: token.colorBgBase,
                fontWeight: 600,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                WebkitTextStroke: "0.5px red",
              }}
            >
              REMOVE ALL
            </div>
          </Popconfirm>
        ),
        dataIndex: "remove",
        align: "center",
      },
    ];
    return array.filter((o1) => o1);
  }, []);

  useDebounce(
    () => {
      if (props?.searchValue?.length > 0 && watchList) {
        const filteredData = watchList?.filter((existingScript) => {
          return existingScript?.symbolName
            ?.toLowerCase()
            ?.includes(props?.searchValue?.toLowerCase());
        });

        setTableData(filteredData);
      } else {
        setTableData(watchList);
      }
    },
    500,
    [props?.searchValue, watchList]
  );

  const skeletonData = new Array(4).fill({}).map((_, index) => ({
    // key: index,
    InstrumentIdentifier: (
      <Skeleton.Input
        key={index}
        style={{ width: `${(index + 1) * 25}rem`, height: "1.6rem" }}
        active={props.isLoading}
      />
    ),
  }));

  return (
    <div>
      <StyledTheme.Root>
        <StyledTheme.Table
          columns={props?.isLoading ? loadingColumn : columns}
          dataSource={props?.isLoading ? skeletonData : tableData}
          pagination={false}
          sortDirections={["ascend", "descend", "ascend"]}
          rowKey="_id"
          sticky
          scroll={{ x: 1300, scrollToFirstRowOnChange: false }}
        />
        {isModalOpen ? (
          <ModalView
            modalType={modalType.current}
            record={record.current}
            onClose={onClose}
            activeScript={activeScriptRef.current}
          />
        ) : null}
      </StyledTheme.Root>
    </div>
  );
});

const MemorizedTime = (record) => {
  const time = useSelector(
    (state) => state.webSocketSlice?.webSocket?.data?.[record?.symbol]?.LUT
  );

  const timeMemo = useMemo(() => {
    return time;
  }, [time, record]);

  // const timePart = time?.split("T");
  // const finalTime = timePart?.subString(0, 8);

  return `${timeMemo}`;
};

export const convertTime = (date) => {
  let convertedDate = "";
  const [day, month, year] = date?.split("-").map(Number);
  const dateObject = new Date(year, month - 1, day);

  convertedDate = dateObject.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  convertedDate = convertedDate.replace(/\s/g, "");

  return convertedDate;
};

export default SimpleTable;
